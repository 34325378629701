import React, { useEffect, useMemo, useState } from "react";
import { Button, Dropdown, Navbar, Nav, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { logout, capitalizeEveryFirstLetter } from "../../helper-methods";
import { switchAssignedResorts } from "../../redux/actions/user-credential";
import logoutIcon from "../../assets/img/Icon_Logout.svg";
import { hasPermission } from "../../helper-methods/has-permission";
import LanguageParsedText from "../../multi-lang/lang-parsed-text/LanguageParsedText";
import { getUnreadNotificationsCount, eButlerService } from "../../http/http-calls";
import './AdminNavbar.css'

function AdminNavbar(props) {
  const dispatch = useDispatch();

  const [collapseOpen, setCollapseOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [filterAssignedResorts, setFilterAssignedResorts] = useState([]);

  const userCredential = useSelector((state) => state["userCredential"]);
  //console.log("userCredential vibha",userCredential)
  const countVal = localStorage.getItem("countVal");
  const hasPermission_manageresort = useMemo(
    () => hasPermission("manageresort"),
    []
  );

  const _getUnreadNotificationsCount = (resortId) => {
    //console.log("vivek kaushik", resortId)
    getUnreadNotificationsCount(resortId)
      .then((res) => {

        localStorage.setItem("countVal", res.count);

       // console.log("read", res.count);
      })
      .catch((error) => {
        console.log("error>>", error);
      });
  };
  useEffect(() => {
    //console.log("vivek");
    _getUnreadNotificationsCount(userCredential?.selectedResort?._id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCredential?.selectedResort?._id]);


  useEffect(() => {
    if (userCredential?.assignedResorts?.length) {
      let newFilterAssignedResorts = userCredential.assignedResorts.filter(
        (each) =>
          each._id &&
          each.name &&
          each.isResortActivated &&
          each.isActive &&
          !each.isSelectedResort
      );
      setFilterAssignedResorts(newFilterAssignedResorts);
    }
  }, [userCredential?.assignedResorts]);

  const _logout = () => {
    console.log(props.history, "history")
    logout(props.history);
  };

  const _switchResord = (resortId) => {
    if (resortId) dispatch(switchAssignedResorts(resortId));
  };

  return (
    <Navbar expand="lg" className="header_top">
      <Container fluid>
        <div className="navbar-wrapper">
          <div className="navbar-minimize">
            <Button
              className="btn-icon d-none d-xl-block"
              variant="dark"
              onClick={() => document.body.classList.toggle("sidebar-mini")}
            >
              <img
                src={require("../../assets/img/fast-forward.png").default}
                alt="react-logo"
                className="menudir"
                loading="lazy"
              />
            </Button>
            <Button
              className="btn-icon d-block d-xl-none"
              variant="dark"
              onClick={() =>
                document.documentElement.classList.toggle("nav-open")
              }
            >
              <i className="fas fa-bars visible-on-sidebar-regular" />
              <i className="fas fa-bars visible-on-sidebar-mini" />
            </Button>
          </div>
        </div>
        <button
          className="navbar-toggler navbar-toggler-right border-0 d-none"
          type="button"
          onClick={() => setCollapseOpen(!collapseOpen)}
        >
          <span className="navbar-toggler-bar burger-lines" />
          <span className="navbar-toggler-bar burger-lines" />
          <span className="navbar-toggler-bar burger-lines" />
        </button>
        <Navbar.Collapse className="justify-content-end" in={collapseOpen}>
          <Nav navbar className="header_right align-items-center">
            <Dropdown as={Nav.Item} className="notification_dropdown">
              <Dropdown.Toggle
                as={Nav.Link}
                // id="dropdown-414718872"
                // variant="default"
                onClick={() => {
                  props.history.push("/notifications");
                  //  setCount(0)
                }}
              >

                <i className="fa fa-bell" >
                  {countVal == 0 ? null : (
                    <div className="d-inline-flex position-absolute bg-danger justify-content-center align-items-center rounded-circle notification-num" 
                    style={{
                      top: "0",
                      right: "3px",
                      width: "130%",
                      height: "50%",
                      fontSize: "12px"
                    }}>
                      <div className="text-light">{countVal}</div>
                    </div>
                  )}
                </i>
                {/* <span className="notification" /> */}
              </Dropdown.Toggle>

              {/* <Dropdown.Menu alignRight>
                <Dropdown.Item onClick={(e) => e.preventDefault()}>
                  Notification 1
                </Dropdown.Item>
                <Dropdown.Item onClick={(e) => e.preventDefault()}>
                  Notification 2
                </Dropdown.Item>
                <Dropdown.Item onClick={(e) => e.preventDefault()}>
                  Notification 3
                </Dropdown.Item>
                <Dropdown.Item onClick={(e) => e.preventDefault()}>
                  Notification 4
                </Dropdown.Item>
                <Dropdown.Item onClick={(e) => e.preventDefault()}>
                  Notification 5
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => props.history.push("/notifications")}
                >
                  <LanguageParsedText keyString="pages.General.seeMore" />
                </Dropdown.Item>
              </Dropdown.Menu> */}
            </Dropdown>

            <Dropdown as={Nav.Item} className="user_info">
              <Dropdown.Toggle
                as={Nav.Link}
                variant="default"
                className="user_wrapper"
              >
                <img
                  src={
                    userCredential?.selectedResort?.logo
                      ? userCredential.selectedResort.logo
                      : require("../../assets/img/hotel.svg").default
                  }
                  style={
                    userCredential?.selectedResort?.logo
                      ? { borderRadius: "50px" }
                      : {}
                  }
                  alt="icon"
                  width="50"
                  loading="lazy"
                />
                <div className="userInfo">
                  {userCredential?.selectedResort?.name ? (
                    <h3>
                      {capitalizeEveryFirstLetter(
                        userCredential?.selectedResort?.name
                      )}
                    </h3>
                  ) : (
                    "N/A"
                  )}
                </div>
              </Dropdown.Toggle>

              {filterAssignedResorts?.length || hasPermission_manageresort ? (
                <Dropdown.Menu alignRight>
                  {filterAssignedResorts?.length
                    ? filterAssignedResorts.map((each) => (
                      <Dropdown.Item
                        className="active"
                        key={each._id}
                        onClick={() => _switchResord(each._id)}
                      >
                        {capitalizeEveryFirstLetter(each.name)}
                      </Dropdown.Item>
                    ))
                    : null}
                  {hasPermission_manageresort ? (
                    <Dropdown.Item
                      onClick={() => props.history.push("/add-resort")}
                    >
                      <LanguageParsedText keyString="pages.General.addResort" />
                    </Dropdown.Item>
                  ) : null}
                </Dropdown.Menu>
              ) : null}
            </Dropdown>

            <Nav.Item className="logout" onClick={() => _logout()}>
              <img src={logoutIcon} alt="logout" width="18" loading="lazy" />{" "}
              <LanguageParsedText keyString="pages.General.logout" />
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default AdminNavbar;
