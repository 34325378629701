import {
  ADD_USER_CREDENTIAL,
  UPDATE_USER_DATA,
  UPDATE_ASSIGNED_RESORTS,
  CLEAR_USER_CREDENTIAL,
  SWITCH_ASSIGNED_RESORTS,
} from "../actions/";

const initialState = {
  token: null,
  user: null,
  assignedResorts: [],
  selectedResort: null,
};

export const userCredentialReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case ADD_USER_CREDENTIAL: {
      newState = action.payload.credential;
      break;
    }
    case UPDATE_USER_DATA: {
      newState["user"] = action.payload.user;
      break;
    }
    case UPDATE_ASSIGNED_RESORTS: {
      if (newState.assignedResorts?.length) {
        const findSelectedResort = newState.assignedResorts.find(
          (each) => each.isSelectedResort
        );

        let isNotResortSelected = true;

        if (findSelectedResort) {
          const findResort = action.payload.assignedResorts.find(
            (each) => each._id === findSelectedResort._id
          );

          if (findResort) {
            findResort["isSelectedResort"] = true;
            isNotResortSelected = false;
          }
        }

        if (isNotResortSelected)
          action.payload.assignedResorts[0]["isSelectedResort"] = true;

        newState["assignedResorts"] = action.payload.assignedResorts;
      } else {
        action.payload.assignedResorts[0]["isSelectedResort"] = true;

        newState["assignedResorts"] = action.payload.assignedResorts;
      }

      newState["selectedResort"] = newState.assignedResorts.find(
        (each) => each.isSelectedResort
      );
      break;
    }
    case SWITCH_ASSIGNED_RESORTS: {
      if (newState.assignedResorts?.length) {
        newState["assignedResorts"] = newState.assignedResorts.map((each) => {
          return {
            ...each,
            isSelectedResort:
              each._id === action.payload.resortId ? true : false,
          };
        });
      }

      newState["selectedResort"] = newState.assignedResorts.find(
        (each) => each.isSelectedResort
      );
      break;
    }
    case CLEAR_USER_CREDENTIAL: {
      newState = initialState;
      break;
    }
    default:
  }
  return newState;
};
