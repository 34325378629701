import React, { Component } from "react";
import { Button } from "reactstrap";
import GoogleLogin from "react-google-login";
import { SOCIAL_CREDENTIALS } from "../config";
import LanguageParsedText from "../multi-lang/lang-parsed-text/LanguageParsedText";

export default class GoogleLoginComponent extends Component {
  
  /**
   *
   * @param {Object} response - google res object
   * @param {Boolean} failure - failure -> true or false
   */
  _responseGoogle = (response, failure) => {
    try {
      if (failure) {
        console.log(`google failure:${failure} res: `, response);
      } else {
        console.log(`google failure:${failure} res: `, response);

        if (response && !failure) {
          console.log("Response form Google", response);
          this.props.response(response);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  _componentClicked = () => console.log("clicked");
  
  render() {
    const { btnText } = this.props;
    //console.log(SOCIAL_CREDENTIALS ,"SOCIAL_CREDENTIALS ");
    return (
      <>
        <GoogleLogin
          clientId={SOCIAL_CREDENTIALS.googleClientId}
          onClick={this._componentClicked}
          render={(renderProps) => (
            <Button
              className={this.props.className}
              onClick={renderProps.onClick}
            >
              <img
                src={require("../assets/img/google-logo.png").default}
                alt="Google"
                loading="lazy"
              />
              {btnText ? (
                btnText
              ) : (
                <>
                  <LanguageParsedText keyString="pages.Login.continueWith" />{" "}
                  Google
                </>
              )}
            </Button>
          )}
          onSuccess={(response) => this._responseGoogle(response, false)} // failure -> true or false
          onFailure={(response) => this._responseGoogle(response, true)} // failure -> true or false
          cookiePolicy={"single_host_origin"}
        />
      </>
    );
  }
}
