import React, { Component } from "react";
import { Container, FormGroup, InputGroup, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import {
  Label,
  InputGroupText,
  CustomInput,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import { DEFAULT_PROFILE_PICTURE } from "../../config";
import { countryCodes } from "../../config/country-codes";
import {
  assignToComplimentaryServicesConfig,
  permissionsConfig,
} from "../../config/helper-config";
import { RegexConfig } from "../../config/RegexConfig";
import {
  capitalize,
  deepClone,
  getPhoneCodeFromBrackets,
  getPhoneNumberFromBrackets,
  showToast,
  uploadFileOnServer,
} from "../../helper-methods";
import { getAndUpdateUserData } from "../../redux/actions/user-credential";
import {
  getAmenitiesList,
  updateLoggedInUserDetail,
} from "../../http/http-calls";
import LanguageSwitcher from "../../components/LanguageSwitcher";
import LanguageParsedText, {
  getValueFromCurrentLang,
} from "../../multi-lang/lang-parsed-text/LanguageParsedText";

class ProfilePage extends Component {
  state = {
    formFields: {
      name: {
        value: "",
        isDirty: false,
        error: null,
        isValidationRequired: true,
      },
      username: {
        value: "",
        isDirty: false,
        error: null,
        isValidationRequired: true,
      },
      email: {
        value: "",
        isDirty: false,
        error: null,
        isValidationRequired: false,
      },
      phoneCode: {
        value: "+1",
        error: null,
        isDirty: false,
        isValidationRequired: false,
      },
      phone: {
        value: "",
        isDirty: false,
        error: null,
        isValidationRequired: true,
      },
      roles: {
        value: [],
        isDirty: false,
        error: null,
        isValidationRequired: false,
      },
      avatar: {
        value: {
          uploadData: null,
          previewBlob: null,
          type: null,
          uploadUrl: null,
        },
        isDirty: false,
        error: null,
        isValidationRequired: false,
      },
    },
    loading: false,
    amenityArray: [],
  };

  _setForm = () => {
    try {
      const { userCredential } = this.props;
      const { formFields } = this.state;

      formFields.name.value = userCredential?.user?.name?.full || "";
      formFields.username.value = userCredential?.user?.username || "";
      formFields.email.value = userCredential?.user?.email || "";

      if (userCredential?.user?.phone) {
        formFields.phoneCode.value =
          getPhoneCodeFromBrackets(userCredential.user.phone) || "+1";
        formFields.phone.value =
          getPhoneNumberFromBrackets(userCredential.user.phone) || "";
      }

      formFields.avatar.value.uploadUrl = userCredential?.user?.avatar || null;
      formFields.roles.value = userCredential?.user?.roles?.length
        ? userCredential?.user?.roles
        : [];

      this.setState({ formFields, loading: false });
    } catch (error) {
      console.log("error>>", error);
      this.setState({ loading: false });
      showToast(
        error?.reason?.length
          ? error.reason
          : getValueFromCurrentLang("pages.General.somethingWentWrong"),
        "error"
      );
    }
  };

  _getAmenitiesList = () => {
    getAmenitiesList()
      .then((res) => {
        this.setState({ amenityArray: res.amenity?.length ? res.amenity : [] });
      })
      .catch((error) => {
        console.log("error>>", error);
        this.setState({ loading: false });
        showToast(
          error?.reason?.length
            ? error.reason
            : getValueFromCurrentLang("pages.General.serverError"),
          "error"
        );
      });
  };

  _getAndUpdateUserData = async () => {
    try {
      this.setState({ loading: true });

      await this.props.getAndUpdateUserData();

      this._setForm();
    } catch (error) {
      this.setState({ loading: false });
      showToast(
        error?.reason?.length
          ? error.reason
          : getValueFromCurrentLang("pages.General.serverError"),
        "error"
      );
    }
  };

  componentDidMount = () => {
    this._getAmenitiesList();

    this._setForm();

    this._getAndUpdateUserData();
  };

  _resetAvatar = () => {
    const { formFields } = deepClone(this.state);

    formFields["avatar"] = {
      value: {
        uploadData: null,
        previewBlob: null,
        type: null,
        uploadUrl: null,
      },
      error: null,
      isDirty: false,
      isValidationRequired: false,
    };

    this.setState({ formFields });
  };

  _onChangeAvatar = (e) => {
    try {
      if (e?.target?.files?.length) {
        const { formFields } = deepClone(this.state);

        const file = e.target.files[0];

        const fileType = file.type.split("/")[0];

        if (fileType === "image") {
          const previewBlob = URL.createObjectURL(file);

          formFields.avatar.value["uploadData"] = file;
          formFields.avatar.value["previewBlob"] = previewBlob;
          formFields.avatar.value["type"] = fileType;
          formFields.avatar.value["uploadUrl"] = null;
        } else {
          showToast("Only image file is allowed", "error");
          return;
        }

        this.setState({ formFields });
      }
    } catch (error) {
      console.log("error>>", error);
      showToast(
        getValueFromCurrentLang("pages.General.somethingWentWrong"),
        "error"
      );
    }
  };

  _validateFormFields = () => {
    return new Promise((resolve) => {
      const { formFields } = deepClone(this.state);

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (formFields[key].isDirty && formFields[key].isValidationRequired) {
          switch (key) {
            case "name": {
              if (formFields[key].value?.trim().length) {
                formFields[key].error = null;
                formFields[key].isDirty = false;
              } else {
                formFields[key].error = getValueFromCurrentLang(
                  "pages.General.required"
                );
                isFormValid = false;
              }
              break;
            }
            case "phone":
            case "username": {
              if (formFields[key].value?.trim().length) {
                if (
                  RegexConfig[key].test(
                    String(formFields[key].value).toLowerCase()
                  )
                ) {
                  formFields[key].error = null;
                  formFields[key].isDirty = false;
                } else {
                  formFields[key].error = `*${getValueFromCurrentLang(
                    "pages.General.invalid"
                  )} ${getValueFromCurrentLang(`pages.General.${key}`)}`;
                  isFormValid = false;
                }
              } else {
                formFields[key].error = getValueFromCurrentLang(
                  "pages.General.required"
                );
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      this.setState({ formFields }, () => resolve(isFormValid));
    });
  };

  _onChangeFormFields = (key, value) => {
    if (key === "email") return;

    const { formFields } = deepClone(this.state);
    formFields[key].value = value;
    this.setState({ formFields });
  };

  _onBlurFormFields = (key) => {
    const { formFields } = deepClone(this.state);
    formFields[key].isDirty = true;
    this.setState({ formFields }, () => this._validateFormFields());
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve) => {
      const { formFields } = deepClone(this.state);
      Object.keys(formFields).forEach((key) => {
        formFields[key].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };

  _updateLoggedInUserDetail = (payload) => {
    updateLoggedInUserDetail(payload)
      .then((res) => {
        showToast("Profile updated", "success");
        this._getAndUpdateUserData();
      })
      .catch((error) => {
        console.log("error>>", error);
        this.setState({ loading: false });
        showToast(
          error?.reason?.length
            ? error.reason
            : getValueFromCurrentLang("pages.General.serverError"),
          "error"
        );
      });
  };

  _onSaveProfile = async (event) => {
    try {
      if (event) event.preventDefault();

      await this._markAllFieldDirty();

      const isFormValid = await this._validateFormFields();

      if (isFormValid) {
        this.setState({ loading: true });

        const { formFields } = deepClone(this.state);

        let splitName = formFields.name.value.trim().split(" ");

        if (splitName?.length) {
          splitName = {
            first:
              splitName.length > 1
                ? splitName.slice(0, -1).join(" ")
                : splitName[0],
            last: splitName.length > 1 ? splitName[splitName.length - 1] : "",
          };
        }

        const payload = {
          name: {
            first: splitName.first?.trim() ? splitName.first.trim() : "",
            last: splitName.last?.trim() ? splitName.last.trim() : "",
          },
          username: formFields.username.value,
          phone: `(${
            formFields.phoneCode.value
          })${formFields.phone.value.trim()}`,
          phoneCountry: formFields.phoneCode.value,
          avatar: "",
        };

        if (formFields.avatar.value.uploadData) {
          const response = await uploadFileOnServer([
            { ...formFields.avatar.value },
          ]);
          payload["avatar"] = response[0].url;
          formFields.avatar.value["uploadUrl"] = response[0].url;
          formFields.avatar.value["uploadData"] = null;

          this.setState({ formFields });
        } else if (formFields.avatar.value.uploadUrl) {
          payload["avatar"] = formFields.avatar.value.uploadUrl;
        }

        this._updateLoggedInUserDetail(payload);
      }
    } catch (error) {
      console.log("error>>", error);
      this.setState({ loading: false });
      showToast(
        error?.reason?.length
          ? error.reason
          : getValueFromCurrentLang("pages.General.somethingWentWrong"),
        "error"
      );
    }
  };

  _formatPermission = (key, value) => {
    switch (key) {
      case "serviceRoles": {
        const permissionName = value.split("-")[1];

        const findPermission = assignToComplimentaryServicesConfig.find(
          (each) => each.value === permissionName
        );

        return findPermission?.label ? findPermission.label : "N/A";
      }
      case "roles": {
        const findAmenity = this.state.amenityArray.find(
          (each) => each._id === value
        );
        return findAmenity?.name ? capitalize(findAmenity.name) : "N/A";
      }
      case "permissions": {
        const permissionName = value.split("-")[1];

        const findPermission = permissionsConfig.find(
          (each) => each.value === permissionName
        );

        return findPermission?.label ? findPermission.label : "N/A";
      }
      default:
        return "N/A";
    }
  };

  render() {
    const { formFields, loading } = this.state;
    const { userCredential } = this.props;

    return (
      <div className="content profile">
        <Container className="mobilePadding-0">
          <div className="page_Inner_header">
            <Row className="align-items-center">
              <Col xl={5} md={4}>
                <div className="page_title">
                  <h1 className="space_remove">
                    <LanguageParsedText keyString="pages.Profile.userProfile" />{" "}
                    {loading ? <Spinner /> : null}
                  </h1>
                </div>
              </Col>
            </Row>
          </div>

          <div className="mt-4 pt-4 pl-0 main_inner gridBox bg-white">
            <Row className="m-0">
              <Col md="2">
                <div className="amenity-location profile_center mb-4">
                  <div className="  d-flex align-items-center justify-content-center">
                    <div className={`input-gallery-col`}>
                      <div className="group_image">
                        <img
                          src={
                            formFields.avatar.value.previewBlob ||
                            formFields.avatar.value.uploadUrl ||
                            DEFAULT_PROFILE_PICTURE
                          }
                          alt="cover"
                          loading="lazy"
                        />
                        {!loading &&
                        (formFields.avatar.value.previewBlob ||
                          formFields.avatar.value.uploadUrl) ? (
                          <i
                            title="Remove Profile Picture"
                            className="fa fa-close"
                            disabled={loading}
                            onClick={() => this._resetAvatar()}
                          />
                        ) : null}
                      </div>
                      <Label>
                        <span className="replace_map">
                          <Input
                            type="file"
                            value=""
                            className="d-none"
                            disabled={loading}
                            accept="image/*"
                            onChange={(e) => this._onChangeAvatar(e)}
                          />

                          <LanguageParsedText keyString="pages.General.replace" />
                        </span>
                      </Label>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="10">
                <div className="innerForm">
                  <Row className="no-margin">
                    {/* name */}
                    <Col md="12" className="mobilePadding-0">
                      <FormGroup>
                        <Label>
                          <LanguageParsedText keyString="pages.General.name" />
                        </Label>
                        <InputGroup>
                          <InputGroupText>
                            <img
                              src={require("../../assets/img/user.svg").default}
                              alt="user"
                              className="img-fluid"
                              loading="lazy"
                            />
                          </InputGroupText>
                          <Input
                            type="text"
                            placeholder={getValueFromCurrentLang(
                              "pages.General.enterYourName"
                            )}
                            autoComplete="off"
                            name="name"
                            value={formFields.name.value}
                            onChange={(e) =>
                              this._onChangeFormFields("name", e.target.value)
                            }
                            onBlur={() => this._onBlurFormFields("name")}
                          />
                        </InputGroup>
                        {formFields.name.error ? (
                          <div className="form-error">
                            {formFields.name.error}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>

                    <Col md="12" className="mobilePadding-0">
                      <FormGroup>
                        <Label>
                          <LanguageParsedText keyString="pages.General.username" />
                        </Label>
                        <InputGroup>
                          <InputGroupText>
                            <img
                              src={require("../../assets/img/at.svg").default}
                              alt="user"
                              className="img-fluid"
                              loading="lazy"
                            />
                          </InputGroupText>
                          <Input
                            type="text"
                            placeholder={getValueFromCurrentLang(
                              "pages.General.enterTheUsername"
                            )}
                            autoComplete="off"
                            name="username"
                            value={formFields.username.value}
                            onChange={(e) =>
                              this._onChangeFormFields(
                                "username",
                                e.target.value
                              )
                            }
                            onBlur={() => this._onBlurFormFields("username")}
                          />
                        </InputGroup>
                        {formFields.username.error ? (
                          <div className="form-error">
                            {formFields.username.error}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>

                    {/* email */}
                    <Col md="12" className="mobilePadding-0">
                      <FormGroup>
                        <Label>
                          <LanguageParsedText keyString="pages.General.email" />
                        </Label>
                        <InputGroup>
                          <InputGroupText>
                            <img
                              src={require("../../assets/img/at.svg").default}
                              alt="user"
                              className="img-fluid"
                              loading="lazy"
                            />
                          </InputGroupText>
                          <Input
                            type="text"
                            placeholder={getValueFromCurrentLang(
                              "pages.General.enterYourEmail"
                            )}
                            autoComplete="off"
                            disabled={true}
                            name="email"
                            value={formFields.email.value}
                            onChange={(e) => {}}
                            onBlur={() => {}}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>

                    {/* phone */}
                    <Col md="12" className="mobilePadding-0">
                      <FormGroup>
                        <Label>
                          <LanguageParsedText keyString="pages.General.phoneNumber" />
                        </Label>
                        <InputGroup className="countryCode">
                          <InputGroupText>
                            <img
                              src={
                                require("../../assets/img/phone.svg").default
                              }
                              alt="user"
                              className="img-fluid"
                              loading="lazy"
                            />
                            <Input
                              type="select"
                              className="pl-0"
                              value={formFields.phoneCode.value}
                              onChange={(e) =>
                                this._onChangeFormFields(
                                  "phoneCode",
                                  e.target.value
                                )
                              }
                            >
                              {countryCodes.map((each) => (
                                <option key={each.code} value={each.dial_code}>
                                  {each.code} ({each.dial_code})
                                </option>
                              ))}
                            </Input>
                          </InputGroupText>
                          <Input
                            type="text"
                            placeholder={getValueFromCurrentLang(
                              "pages.General.enterYourPhoneNumber"
                            )}
                            autoComplete="off"
                            name="phone"
                            value={formFields.phone.value}
                            onChange={(e) =>
                              this._onChangeFormFields("phone", e.target.value)
                            }
                            onBlur={() => this._onBlurFormFields("phone")}
                          />
                        </InputGroup>
                        {formFields.phone.error ? (
                          <div className="form-error">
                            {formFields.phone.error}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>

                    {/* language Switcher */}
                    <Col md="12" className="mobilePadding-0">
                      <FormGroup>
                        <Label>
                          <LanguageParsedText keyString="pages.General.language" />
                        </Label>
                        <InputGroup>
                          <InputGroupText>
                            <i
                              className="fa fa-language"
                              style={{ fontSize: "26px", width: "auto" }}
                            />
                          </InputGroupText>
                          {/* language Switcher input component */}
                          <LanguageSwitcher
                            className="languageSelect"
                            isShowSelectInput={true}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>

                  {userCredential &&
                  userCredential.user &&
                  userCredential.user.hasOwnProperty("isSuper") &&
                  !userCredential.user.isSuper ? (
                    <>
                      <h1 className="role_permission">
                        <LanguageParsedText keyString="pages.Profile.rolesAndPermissions" />
                      </h1>
                      <div className="check-box-container">
                        {userCredential.user?.roles?.length
                          ? userCredential.user.roles.map((each) => (
                              <CustomInput
                                key={`profile_page_custom_checkbox_role_permissions_${each}`}
                                id={`profile_page_custom_checkbox_role_permissions_${each}`}
                                type="checkbox"
                                checked
                                disabled
                                label={this._formatPermission("roles", each)}
                              />
                            ))
                          : null}
                        {userCredential.user?.permissions?.length
                          ? userCredential.user.permissions.map((each) => (
                              <CustomInput
                                key={`profile_page_custom_checkbox_role_permissions_${each}`}
                                id={`profile_page_custom_checkbox_role_permissions_${each}`}
                                type="checkbox"
                                checked
                                disabled
                                label={this._formatPermission(
                                  "permissions",
                                  each
                                )}
                              />
                            ))
                          : null}
                      </div>
                    </>
                  ) : null}

                  <div className="text-center mt-4 mb-3">
                    <Button
                      color="primary"
                      className="btn-save"
                      disabled={loading}
                      onClick={(e) => this._onSaveProfile(e)}
                    >
                      {loading ? (
                        <i className="fa fa-spinner fa-spin mr-1" />
                      ) : null}{" "}
                      <LanguageParsedText keyString="pages.General.save" />
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userCredential: state.userCredential,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAndUpdateUserData: () => dispatch(getAndUpdateUserData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
