import React, { useEffect, useState } from "react";
import { Button, Table } from "reactstrap";
import Chart from "react-apexcharts";
import LanguageParsedText, {
  getValueFromCurrentLang,
} from "../../multi-lang/lang-parsed-text/LanguageParsedText";
import { getFeedGraphData } from "../../http/http-calls";
import { errorHandler, formatDate } from "../../helper-methods/index1";
import MultiMediaModal from "../Modals/multi-media-modal";
import LoadingSkeleton from "react-loading-skeleton";

const FeedGraph = ({ payload }) => {
  // #5
  const [feedViewsGraph, setFeedViewsGraph] = useState({
    lineOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      stroke: {
        curve: "smooth",
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: [
        {
          labels: {
            formatter: (value) => value.toFixed(0),
            style: {
              colors: "#FF1654",
            },
          },
        },
        {
          opposite: true,
          labels: {
            formatter: (value) => value.toFixed(0),
            style: {
              colors: "#247BA0",
            },
          },
        },
      ],
      xaxis: {
        categories: [],
      },
      colors: ["#FF1654", "#247BA0"],
      tooltip: {
        // shared: false,
        x: {
          show: false,
        },
      },
    },
    lineSeries: [
      {
        name: `# ${getValueFromCurrentLang("pages.KPIs.views")}`,
        data: [],
      },
      {
        name: `# ${getValueFromCurrentLang("pages.KPIs.likes")}`,
        data: [],
      },
    ],
  });

  const [topFeedPosts, setTopFeedPosts] = useState([]);
  const [totalViews, setTotalViews] = useState(0);
  const [totalLikes, setTotalLikes] = useState(0);
  const [loading, setLoading] = useState(false);
  const [multiMediaModal, setMultiMediaModal] = useState({
    isOpen: false,
    content: [],
  });

  const _setFeedPosts = (feedPosts = []) => {
    const newFeedViewsGraph = { ...feedViewsGraph };

    newFeedViewsGraph.lineOptions.xaxis.categories = [];
    newFeedViewsGraph.lineSeries[0].data = []; // # views
    newFeedViewsGraph.lineSeries[1].data = []; // # likes

    feedPosts.forEach((each) => {
      newFeedViewsGraph.lineOptions.xaxis.categories.push(
        `Week ${each._id.week}`
      );
      newFeedViewsGraph.lineSeries[0].data.push(each.views);
      newFeedViewsGraph.lineSeries[1].data.push(each.likes);
    });

    setFeedViewsGraph(newFeedViewsGraph);

    setTotalViews(feedPosts.reduce((acc, each) => acc + each.views, 0));
    setTotalLikes(feedPosts.reduce((acc, each) => acc + each.likes, 0));
  };

  const _getFeedGraphData = (payload) => {
    setLoading(true);
    getFeedGraphData(payload)
      .then((res) => {
        if (res.result) {
          _setFeedPosts(res.result.feedPosts);
          setTopFeedPosts(res.result.topFeedPosts || []);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        errorHandler(error);
      });
  };

  useEffect(() => {
    if (payload?.startDate && payload?.endDate && payload?.groupByUnit)
      _getFeedGraphData(payload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload?.startDate, payload?.endDate, payload?.groupByUnit]);

  const _toggleMultiMediaModal = (isOpen = false, content = []) => {
    setMultiMediaModal({
      isOpen,
      content,
    });
  };

  return (
    <div className="graphWrapper">
      <div className="graphTitleWrap">
        <h1>
          <LanguageParsedText keyString="pages.KPIs.feedPerformance" />
        </h1>

        <div className="specialOffers">
          <div>
            {loading ? (
              <LoadingSkeleton />
            ) : (
              <h4>
                <span style={{ background: "#ff1654" }} /> {totalViews}
              </h4>
            )}
            <p>
              # <LanguageParsedText keyString="pages.KPIs.guestViews" />
            </p>
          </div>

          <div>
            {loading ? (
              <LoadingSkeleton />
            ) : (
              <h4>
                <span style={{ background: "#247ba0" }} /> {totalLikes}
              </h4>
            )}
            <p>
              # <LanguageParsedText keyString="pages.KPIs.guestLikes" />
            </p>
          </div>
        </div>
      </div>

      {/* feed Table */}
      <Table className="cardTable" style={{ marginTop: 45 }}>
        <thead>
          <tr>
            <th>
              <LanguageParsedText keyString="pages.KPIs.topFeedPosts" />
            </th>
            <th>
              <LanguageParsedText keyString="pages.KPIs.totalLikes" />
            </th>
            <th>
              <LanguageParsedText keyString="pages.KPIs.datePosted" />
            </th>
          </tr>
        </thead>
        <tbody>
          {topFeedPosts?.length ? (
            topFeedPosts.map((each) => (
              <tr key={each._id}>
                <td>
                  {each.content?.length ? (
                    <Button
                      color="link"
                      className="p-0"
                      onClick={() => _toggleMultiMediaModal(true, each.content)}
                    >
                      {each.text || "View Feed"}
                    </Button>
                  ) : (
                    each.text || "N/A"
                  )}
                </td>
                <td>{each.totalLikes || 0}</td>
                <td>{formatDate(each.timestamp)}</td>
              </tr>
            ))
          ) : loading ? (
            <>
              <tr>
                <td>
                  <LoadingSkeleton />
                </td>
                <td>
                  <LoadingSkeleton />
                </td>
                <td>
                  <LoadingSkeleton />
                </td>
              </tr>
              <tr>
                <td>
                  <LoadingSkeleton />
                </td>
                <td>
                  <LoadingSkeleton />
                </td>
                <td>
                  <LoadingSkeleton />
                </td>
              </tr>
            </>
          ) : (
            <tr>
              <td colSpan="3">There is no data to display.</td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* feed post graph */}
      <div className="graphTitleWrap mb-2" style={{ marginTop: 45 }}>
        <h1>
          <LanguageParsedText keyString="pages.KPIs.feedPosts" />
        </h1>
      </div>

      {loading ? (
        <LoadingSkeleton height={200} />
      ) : (
        <>
          {feedViewsGraph.lineSeries[0]?.data?.length ? (
            <Chart
              options={feedViewsGraph.lineOptions}
              series={feedViewsGraph.lineSeries}
              type="area"
              width="100%"
              height="275"
              className="chartWrap mb-1"
            />
          ) : (
            <p>There is no data to display.</p>
          )}
        </>
      )}

      <MultiMediaModal
        isOpen={multiMediaModal.isOpen}
        content={multiMediaModal.content}
        activeIndex={0}
        toggle={() => _toggleMultiMediaModal()}
      />
    </div>
  );
};

export default FeedGraph;
