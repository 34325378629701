import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { ListGroup, ListGroupItem } from "reactstrap";
import { errorHandler, formatDecimalNumber } from "../../helper-methods/index1";
import { getServiceGraphData } from "../../http/http-calls";
import LanguageParsedText, {
  getValueFromCurrentLang,
} from "../../multi-lang/lang-parsed-text/LanguageParsedText";
import LoadingSkeleton from "react-loading-skeleton";

const ServiceGraph = ({ payload }) => {
  // #2.1
  const [reservationGraph, setReservationGraph] = useState({
    lineOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      stroke: {
        curve: "smooth",
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: [
        {
          labels: {
            formatter: (value) => value.toFixed(0),
            style: {
              colors: "#FF1654",
            },
          },
        },
        {
          opposite: true,
          tickAmount: 4,
          min: 0,
          max: 100,
          labels: {
            formatter: (value) => value + "%",
            style: {
              colors: "#247BA0",
            },
          },
        },
      ],
      xaxis: {
        categories: [],
      },
      colors: ["#FF1654", "#247BA0"],
      tooltip: {
        // shared: false,
        x: {
          show: false,
        },
      },
    },
    lineSeries: [
      {
        name: `# ${getValueFromCurrentLang("pages.KPIs.reservations")}`,
        data: [],
      },
      {
        name: `# ${getValueFromCurrentLang(
          "pages.KPIs.guestMakingReservation"
        )}`,
        data: [],
      },
    ],
  });

  // #2.2
  const [reservationDonutGraph, setReservationDonutGraph] = useState({
    donutOptions: {
      plotOptions: {
        pie: {
          expandOnClick: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      labels: [],
      colors: ["#008ffb", "#FF1654", "#feb019", "#F1512E", "#255aee"],
    },
    donutSeries: [],
  });

  const [serviceBreakdown, setServiceBreakdown] = useState([]);
  const [loading, setLoading] = useState(false);

  const _setBreakdownGraph = (serviceBreakdown = []) => {
    const newReservationDonutGraph = { ...reservationDonutGraph };
    newReservationDonutGraph.donutOptions.labels = [];
    newReservationDonutGraph.donutSeries = [];
    serviceBreakdown.forEach((each) => {
      newReservationDonutGraph.donutOptions.labels.push(each.name);
      newReservationDonutGraph.donutSeries.push(each.count);
    });
    setReservationDonutGraph(newReservationDonutGraph);
    setServiceBreakdown(serviceBreakdown);
  };

  const _setReservationsGraph = (
    serviceReservations = [],
    guestMakingReservation = []
  ) => {
    const newReservationGraph = { ...reservationGraph };
    newReservationGraph.lineOptions.xaxis.categories = [];
    newReservationGraph.lineSeries[0].data = []; // # reservations
    newReservationGraph.lineSeries[1].data = []; // % guest Making Reservation
    serviceReservations.forEach((each) => {
      newReservationGraph.lineOptions.xaxis.categories.push(
        `Week ${each._id.week}`
      );
      newReservationGraph.lineSeries[0].data.push(each.count);
      newReservationGraph.lineSeries[1].data.push(0);
    });
    guestMakingReservation.forEach((each) => {
      const findIndex =
        newReservationGraph.lineOptions.xaxis.categories.findIndex(
          (val) => val === `Week ${each._id.split("-")[1]}`
        );
      if (findIndex === -1) {
        newReservationGraph.lineOptions.xaxis.categories.push(
          `Week ${each._id.split("-")[1]}`
        );
        newReservationGraph.lineSeries[0].data.push(0);
        const total = each.details.reduce((acc, obj) => acc + obj.count, 0);
        const percent = formatDecimalNumber((each.count / total) * 100);
        newReservationGraph.lineSeries[1].data.push(percent);
      } else {
        const total = each.details.reduce((acc, obj) => acc + obj.count, 0);
        const percent = formatDecimalNumber((each.count / total) * 100);
        newReservationGraph.lineSeries[1].data.splice(findIndex, 1, percent);
      }
    });
    setReservationGraph(newReservationGraph);
  };

  const _getServiceGraphData = (payload) => {
    setLoading(true);
    getServiceGraphData(payload)
      .then((res) => {
        if (res.result) {
          _setReservationsGraph(
            res.result.serviceReservations,
            res.result.guestMakingReservation
          );
          _setBreakdownGraph(res.result.serviceBreakdown);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        errorHandler(error);
      });
  };

  useEffect(() => {
    if (payload?.startDate && payload?.endDate && payload?.groupByUnit)
      _getServiceGraphData(payload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload?.startDate, payload?.endDate, payload?.groupByUnit]);

  return (
    <div className="graphWrapper">
      {/* Service Reservations */}
      <div className="graphTitleWrap">
        <h1>
          <LanguageParsedText keyString="pages.KPIs.serviceReservations" />
        </h1>
      </div>

      {loading ? (
        <LoadingSkeleton height={200} />
      ) : (
        <>
          {reservationGraph.lineSeries[0]?.data?.length ? (
            <Chart
              options={reservationGraph.lineOptions}
              series={reservationGraph.lineSeries}
              type="area"
              width="100%"
              height="275"
              className="chartWrap"
            />
          ) : (
            <p>There is no data to display.</p>
          )}
        </>
      )}

      {/* Service Breakdown */}
      <div className="graphTitleWrap" style={{ marginTop: 45 }}>
        <h1>
          <LanguageParsedText keyString="pages.KPIs.serviceBreakdown" />
        </h1>
      </div>

      {loading ? (
        <LoadingSkeleton height={200} />
      ) : (
        <>
          {reservationDonutGraph.donutSeries?.length ? (
            <Chart
              options={reservationDonutGraph.donutOptions}
              series={reservationDonutGraph.donutSeries}
              type="donut"
              height="200"
              className="chartWrap mb-2"
            />
          ) : (
            <p>There is no data to display.</p>
          )}
        </>
      )}

      <p className="legendTxt">Legend</p>
      {loading ? (
        <div>
          <LoadingSkeleton count={2} />
        </div>
      ) : (
        <ListGroup className="donutGraphDetails">
          {serviceBreakdown?.length ? (
            serviceBreakdown.map((each, index) => (
              <ListGroupItem key={index}>
                <span
                  style={{
                    background:
                      reservationDonutGraph.donutOptions.colors[index % 5],
                  }}
                />{" "}
                {each.name} <div>{each.count}</div>
              </ListGroupItem>
            ))
          ) : (
            <ListGroupItem>There is no data to display.</ListGroupItem>
          )}
        </ListGroup>
      )}
    </div>
  );
};

export default ServiceGraph;
