import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  InputGroupText,
  Row,
  Col,
} from "reactstrap";
import { InputGroup } from "react-bootstrap";
import ReactDatetime from "react-datetime";
import moment from "moment";
import {
  formatDate,
  getYesterdayDate,
  showToast,
  uploadFileOnServer,
} from "../../helper-methods";
import { createChatGroup, updateChatGroup } from "../../http/http-calls";
import LanguageParsedText, {
  getValueFromCurrentLang,
} from "../../multi-lang/lang-parsed-text/LanguageParsedText";

const AddChatGroupModal = ({ isOpen, toggle, data, getAllGroups }) => {
  const [formFields, setFormFields] = useState({
    name: {
      value: "",
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
    startDate: {
      value: "",
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
    endDate: {
      value: "",
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
    displayPicture: {
      value: {
        uploadData: null,
        previewBlob: null,
        type: null,
        uploadUrl: null,
      },
      error: null,
      isDirty: false,
      isValidationRequired: false,
    },
  });
  const [loading, setLoading] = useState(false);

  const _resetStateModal = () => {
    setFormFields({
      name: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      startDate: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      endDate: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      displayPicture: {
        value: {
          uploadData: null,
          previewBlob: null,
          type: null,
          uploadUrl: null,
        },
        error: null,
        isDirty: false,
        isValidationRequired: false,
      },
    });
    setLoading(false);
  };

  const _setModalForm = (data) => {
    const newFormFields = { ...formFields };
    newFormFields.name.value = data.name || "";
    if (data.startDate) {
      newFormFields.startDate.value = data.startDate;
      if (data.endDate) newFormFields.endDate.value = data.endDate;
    }
    if (data.displayPicture)
      newFormFields.displayPicture.value.uploadUrl = data.displayPicture;
    setFormFields(newFormFields);
  };

  useEffect(() => {
    if (isOpen && data?.id) {
      _setModalForm(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, data]);

  const _closeModal = () => {
    _resetStateModal();
    toggle();
  };

  const _validateFormFields = (newFormFields) => {
    return new Promise((resolve) => {
      let isFormValid = true;

      Object.keys(newFormFields).forEach((key) => {
        if (newFormFields[key].isDirty) {
          switch (key) {
            case "name": {
              if (newFormFields[key]?.value?.trim().length) {
                newFormFields[key].error = null;
                newFormFields[key].isDirty = false;
              } else {
                newFormFields[key].error = getValueFromCurrentLang(
                  "pages.General.required"
                );
                isFormValid = false;
              }
              break;
            }
            case "startDate": {
              if (newFormFields[key]?.value) {
                if (newFormFields.endDate.value) {
                  if (
                    new Date(newFormFields[key].value) <
                    new Date(newFormFields.endDate.value)
                  ) {
                    newFormFields[key].error = null;
                    newFormFields[key].isDirty = false;
                  } else {
                    newFormFields[key].error = `*${getValueFromCurrentLang(
                      "pages.General.invalid"
                    )} Date`;
                    isFormValid = false;
                  }
                } else {
                  newFormFields[key].error = null;
                  newFormFields[key].isDirty = false;
                }
              } else {
                if (newFormFields.endDate.value) {
                  newFormFields[key].error = getValueFromCurrentLang(
                    "pages.General.required"
                  );
                  isFormValid = false;
                } else {
                  newFormFields[key].error = null;
                  newFormFields[key].isDirty = false;
                }
              }
              break;
            }
            case "endDate": {
              if (newFormFields[key]?.value) {
                if (newFormFields.startDate.value) {
                  if (
                    new Date(newFormFields[key].value) >
                    new Date(newFormFields.startDate.value)
                  ) {
                    newFormFields[key].error = null;
                    newFormFields[key].isDirty = false;
                  } else {
                    newFormFields[key].error = `*${getValueFromCurrentLang(
                      "pages.General.invalid"
                    )} Date`;
                    isFormValid = false;
                  }
                } else {
                  newFormFields[key].error = null;
                  newFormFields[key].isDirty = false;
                }
              } else {
                newFormFields[key].error = null;
                newFormFields[key].isDirty = false;
              }
              break;
            }
            default:
          }
        }
      });

      setFormFields(newFormFields);

      resolve(isFormValid);
    });
  };

  const _onChangeFormFields = (key, value) => {
    const newFormFields = { ...formFields };

    if (key === "startDate" || key === "endDate")
      newFormFields[key].value = value ? new Date(value).toISOString() : "";
    else newFormFields[key].value = value;

    newFormFields[key].isDirty = true;
    setFormFields(newFormFields);
    _validateFormFields(newFormFields);
  };

  const _resetDisplayPicture = () => {
    const newFormFields = { ...formFields };
    newFormFields.displayPicture = {
      value: {
        uploadData: null,
        previewBlob: null,
        type: null,
        uploadUrl: null,
      },
      error: null,
      isDirty: false,
      isValidationRequired: false,
    };
    setFormFields(newFormFields);
  };

  const _onChangeDisplayPicture = (e) => {
    try {
      if (e?.target?.files?.length) {
        const newFormFields = { ...formFields };

        const file = e.target.files[0];

        const fileType = file.type.split("/")[0];

        if (fileType === "image") {
          const previewBlob = URL.createObjectURL(file);

          newFormFields.displayPicture.value["uploadData"] = file;
          newFormFields.displayPicture.value["previewBlob"] = previewBlob;
          newFormFields.displayPicture.value["type"] = fileType;
          newFormFields.displayPicture.value["uploadUrl"] = null;
        } else {
          showToast("Only image file is allowed", "error");
          return;
        }

        setFormFields(newFormFields);
      }
    } catch (error) {
      console.log("error>>", error);
      showToast(
        getValueFromCurrentLang("pages.General.somethingWentWrong"),
        "error"
      );
    }
  };

  const _updateChatGroup = (id, payload) => {
    updateChatGroup(id, payload)
      .then((res) => {
        showToast("Group updated successfully.", "success");
        getAllGroups();
        _closeModal();
      })
      .catch((error) => {
        console.log("error>>", error);
        setLoading(false);
        showToast(
          error?.reason?.length
            ? error.reason
            : getValueFromCurrentLang("pages.General.serverError"),
          "error"
        );
      });
  };

  const _createChatGroup = (payload) => {
    createChatGroup(payload)
      .then((res) => {
        showToast("Group created successfully.", "success");
        getAllGroups();
        _closeModal();
      })
      .catch((error) => {
        console.log("error>>", error);
        setLoading(false);
        showToast(
          error?.reason?.length
            ? error.reason
            : getValueFromCurrentLang("pages.General.serverError"),
          "error"
        );
      });
  };

  const _createGroup = async () => {
    try {
      const newFormFields = { ...formFields };
      Object.keys(newFormFields).forEach(
        (key) => (newFormFields[key].isDirty = true)
      );
      setFormFields(newFormFields);
      const isFormValid = await _validateFormFields(newFormFields);

      if (isFormValid) {
        setLoading(true);

        const newFormFields = { ...formFields };

        const payload = {
          name: newFormFields.name.value.trim(),
          displayPicture: "",
        };

        if (newFormFields.startDate.value && newFormFields.endDate.value) {
          payload["startDate"] = newFormFields.startDate.value;
          payload["endDate"] = newFormFields.endDate.value;
        }

        if (newFormFields.displayPicture?.value?.uploadData) {
          const uploadDataFilter = [
            {
              uploadData: newFormFields.displayPicture.value.uploadData,
              previewBlob: newFormFields.displayPicture.value.previewBlob,
              type: newFormFields.displayPicture.value.type,
            },
          ];
          const response = await uploadFileOnServer(uploadDataFilter);
          payload["displayPicture"] = response[0].url;
          newFormFields.displayPicture.value.uploadUrl = response[0].url;
          newFormFields.displayPicture.value.uploadData = null;
          setFormFields(newFormFields);
        } else {
          payload["displayPicture"] =
            newFormFields?.displayPicture?.value?.uploadUrl || "";
        }

        if (data?.id) {
          _updateChatGroup(data.id, payload);
        } else {
          _createChatGroup(payload);
        }
      }
    } catch (error) {
      console.log("error>>", error);
      setLoading(false);
      showToast(
        error?.reason?.length
          ? error.reason
          : getValueFromCurrentLang("pages.General.somethingWentWrong"),
        "error"
      );
    }
  };

  return (
    <Modal
      className="create-group"
      isOpen={isOpen}
      toggle={() => _closeModal()}
      centered
    >
      <ModalHeader toggle={() => _closeModal()} className="text-center">
        {data?.id
          ? getValueFromCurrentLang("pages.General.update")
          : getValueFromCurrentLang("pages.General.create")}{" "}
        <LanguageParsedText keyString="pages.General.group" />
      </ModalHeader>
      <ModalBody>
        <div className="innerForm">
          <FormGroup>
            <Label>
              <LanguageParsedText keyString="pages.General.name" />
            </Label>
            <InputGroup>
              <InputGroupText>
                <img
                  src={require("../../assets/img/user.svg").default}
                  alt="user"
                  className="img-fluid"
                  loading="lazy"
                />
              </InputGroupText>
              <Input
                type="text"
                placeholder={getValueFromCurrentLang(
                  "pages.GuestGroups.enterTheGroupName"
                )}
                autoComplete="off"
                name="name"
                value={formFields.name.value}
                onChange={(e) => _onChangeFormFields("name", e.target.value)}
              />
            </InputGroup>
            {formFields.name.error ? (
              <div className="form-error">{formFields.name.error}</div>
            ) : null}
          </FormGroup>

          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>
                  <LanguageParsedText keyString="pages.General.startDate" />
                </Label>
                <InputGroup>
                  <InputGroupText>
                    <img
                      src={require("../../assets/img/calendar.svg").default}
                      alt="user"
                      className="img-fluid w-20"
                      loading="lazy"
                    />
                    <ReactDatetime
                      inputProps={{
                        disabled: loading,
                        className: "form-control",
                        placeholder: getValueFromCurrentLang(
                          "pages.General.selectTheStartDate"
                        ),
                        value: formatDate(formFields.startDate.value),
                      }}
                      value={
                        formFields.startDate.value
                          ? new Date(formFields.startDate.value)
                          : ""
                      }
                      onChange={(e) => _onChangeFormFields("startDate", e._d)}
                      isValidDate={(current) =>
                        current.isAfter(getYesterdayDate())
                      }
                      dateFormat={true}
                      timeFormat={false}
                      closeOnSelect={true}
                    />
                  </InputGroupText>
                </InputGroup>
                {formFields.startDate.error ? (
                  <div className="form-error">{formFields.startDate.error}</div>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={6} className="mobilePadding-0">
              <FormGroup>
                <Label>
                  <LanguageParsedText keyString="pages.General.endDate" />
                </Label>
                <InputGroup>
                  <InputGroupText>
                    <img
                      src={require("../../assets/img/calendar.svg").default}
                      alt="user"
                      className="img-fluid w-20"
                      loading="lazy"
                    />
                    <ReactDatetime
                      inputProps={{
                        disabled: loading,
                        className: "form-control",
                        placeholder: getValueFromCurrentLang(
                          "pages.General.selectTheEndDate"
                        ),
                        value: formatDate(formFields.endDate.value),
                      }}
                      value={
                        formFields.endDate.value
                          ? new Date(formFields.endDate.value)
                          : ""
                      }
                      onChange={(e) => _onChangeFormFields("endDate", e._d)}
                      isValidDate={(current) =>
                        formFields.startDate.value
                          ? current.isAfter(moment(formFields.startDate.value))
                          : current.isAfter(getYesterdayDate())
                      }
                      dateFormat={true}
                      timeFormat={false}
                      closeOnSelect={true}
                    />
                  </InputGroupText>
                </InputGroup>
                {formFields.endDate.error ? (
                  <div className="form-error">{formFields.endDate.error}</div>
                ) : null}
              </FormGroup>
            </Col>
          </Row>

          <div className="amenity-location">
            <div className="mt-3 d-flex align-items-center">
              {formFields.displayPicture.value.previewBlob ||
              formFields.displayPicture.value.uploadUrl ? (
                <div className={`input-gallery-col mr-4`}>
                  <div className="group_image m-auto">
                    <img
                      src={
                        formFields.displayPicture.value.previewBlob ||
                        formFields.displayPicture.value.uploadUrl
                      }
                      alt="cover"
                      loading="lazy"
                    />
                    {!loading ? (
                      <i
                        className="fa fa-close"
                        disabled={loading}
                        onClick={() => _resetDisplayPicture()}
                      />
                    ) : null}
                  </div>
                  <Label>
                    <span className="replace_map">
                      <Input
                        type="file"
                        value=""
                        className="d-none"
                        disabled={loading}
                        accept="image/*"
                        onChange={(e) => _onChangeDisplayPicture(e)}
                      />
                      <LanguageParsedText keyString="pages.General.replace" />
                    </span>
                  </Label>
                </div>
              ) : (
                <div className="group_image plus mr-4">
                  <Label className="mb-0">
                    <Input
                      type="file"
                      value=""
                      disabled={loading}
                      className="d-none"
                      accept="image/*"
                      onChange={(e) => _onChangeDisplayPicture(e)}
                    />
                    <img
                      src={require("../../assets/img/plus.png").default}
                      alt="category"
                      className="img-fluid"
                      loading="lazy"
                    />
                  </Label>
                </div>
              )}

              <span className="fs-15 d-block location_text">
                <div className="custom-file-text">
                  <h3 className="fs-18 text-theme fw-bold mt-0 mb-1">
                    <LanguageParsedText keyString="pages.GuestGroups.uploadGroupIcon" />
                  </h3>
                  <span className="fs-14 text-secondary">
                    Group icon should be in the standard format PNG, JPG &amp;
                    no more than 5 MB
                  </span>
                </div>
              </span>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center my-4">
          <Button
            color="primary"
            className="btn-cancel px-5 mr-3"
            onClick={() => _closeModal()}
          >
            <LanguageParsedText keyString="pages.General.cancel" />
          </Button>
          <Button
            className="btn-primary px-5"
            disabled={loading}
            onClick={() => _createGroup()}
          >
            {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
            {data?.id
              ? getValueFromCurrentLang("pages.General.update")
              : getValueFromCurrentLang("pages.General.create")}
          </Button>
        </div>
      </ModalBody>

      {!data?.id ? (
        <ModalFooter>
          <LanguageParsedText keyString="pages.GuestGroups.QRCodeWillCreateAutomatically" />
        </ModalFooter>
      ) : null}
    </Modal>
  );
};

export default AddChatGroupModal;
