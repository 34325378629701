import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  InputGroup,
  FormControl,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Card, FormGroup, Label, Input } from "reactstrap";
import { CustomInput, Spinner } from "reactstrap";
import AddTeamMemberModal from "../../components/Modals/add-team-member-modal";
import {
  updateTeamMember,
  getAllTeamMember,
  getAmenitiesList,
} from "../../http/http-calls";
import { capitalize, formatPhoneNumber, showToast } from "../../helper-methods";
import { DEFAULT_PROFILE_PICTURE } from "../../config";
import ReactPaginate from "react-paginate";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  assignToComplimentaryServicesConfig,
  permissionsConfig,
} from "../../config/helper-config";
import { useSelector } from "react-redux";
import LanguageParsedText, {
  getValueFromCurrentLang,
} from "../../multi-lang/lang-parsed-text/LanguageParsedText";

let searchInterval;

const TeamMembers = () => {
  const userCredential = useSelector((state) => state["userCredential"]);

  const mountedRef = useRef(true);

  const [amenityArray, setAmenityArray] = useState([]);

  const [addTeamMemberModal, setAddTeamMemberModal] = useState({
    isOpen: false,
    data: null,
  });

  const [getAllTeamMemberPayload, setGetAllTeamMemberPayload] = useState({
    filters: {},
    search: "",
    skip: 0,
    pageNumber: 0,
    limit: 8,
  });

  const [teamMembers, setTeamMembers] = useState([]);
  const [teamMembersCount, setTeamMembersCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [isActiveLoading, setIsActiveLoading] = useState(false);
  const [alert, setAlert] = useState(null);

  const [activeResortId, setActiveResortId] = useState(null);

  const [filtersLoading, setFiltersLoading] = useState(false);
  const [filters, setFilters] = useState({
    permissions: "",
    roles: "",
  });

  const _toggleAddTeamMemberModal = (isOpen = false, data = null) => {
    setAddTeamMemberModal({
      isOpen,
      data,
    });
  };

  const _getAmenitiesList = () => {
    getAmenitiesList()
      .then((res) => {
        setAmenityArray(res.amenity?.length ? res.amenity : []);
      })
      .catch((error) => {
        console.log("error>>", error);
        showToast(
          error?.reason?.length
            ? error.reason
            : getValueFromCurrentLang("pages.General.serverError"),
          "error"
        );
      });
  };

  const _getAllTeamMember = (payload) => {
    setLoading(true);

    getAllTeamMember(payload)
      .then((res) => {
        if (!mountedRef.current) return null;

        setTeamMembers(res.teamMembers);
        setTeamMembersCount(res.count);
        setLoading(false);
        setSearchLoading(false);
        setFiltersLoading(false);
      })
      .catch((error) => {
        if (!mountedRef.current) return null;

        console.log("error>>", error);
        setLoading(false);
        setSearchLoading(false);
        setFiltersLoading(false);
        showToast(
          error?.reason?.length
            ? error.reason
            : getValueFromCurrentLang("pages.General.serverError"),
          "error"
        );
      });
  };
 // console.log(teamMembers,"teamMembers")

  const _onPageChange = (pageNumber = 0) => {
    const newGetAllTeamMemberPayload = { ...getAllTeamMemberPayload };
    newGetAllTeamMemberPayload["skip"] =
      pageNumber * newGetAllTeamMemberPayload["limit"];
    newGetAllTeamMemberPayload["pageNumber"] = pageNumber;
    setGetAllTeamMemberPayload(newGetAllTeamMemberPayload);
    _getAllTeamMember(newGetAllTeamMemberPayload);
  };

  const _onChangeSearch = (searchValue) => {
    clearInterval(searchInterval);
    setSearchLoading(true);
    const newGetAllTeamMemberPayload = { ...getAllTeamMemberPayload };
    newGetAllTeamMemberPayload["search"] = searchValue;
    newGetAllTeamMemberPayload["skip"] = 0;
    newGetAllTeamMemberPayload["pageNumber"] = 0;
    setGetAllTeamMemberPayload(newGetAllTeamMemberPayload);
    searchInterval = setTimeout(() => {
      _getAllTeamMember(newGetAllTeamMemberPayload);
    
    }, 1000);
  };

  const _onChangeFilters = (key, value) => {
    setFiltersLoading(true);

    const newFilters = { ...filters };
    newFilters[key] = value;
    setFilters(newFilters);

    const newGetAllTeamMemberPayload = { ...getAllTeamMemberPayload };
    newGetAllTeamMemberPayload["skip"] = 0;
    newGetAllTeamMemberPayload["pageNumber"] = 0;
    newGetAllTeamMemberPayload["filters"] = {};

    Object.keys(newFilters).forEach((eachFilterKey) => {
      if (newFilters[eachFilterKey]) {
        newGetAllTeamMemberPayload.filters[eachFilterKey] =
          newFilters[eachFilterKey];
      }
    });

    setGetAllTeamMemberPayload(newGetAllTeamMemberPayload);

    _getAllTeamMember(newGetAllTeamMemberPayload);
  };

  const _clearFilters = () => {
    setFiltersLoading(true);

    const newFilters = { ...filters };
    Object.keys(newFilters).forEach((key) => {
      newFilters[key] = "";
    });
    setFilters(newFilters);

    const newGetAllTeamMemberPayload = { ...getAllTeamMemberPayload };
    newGetAllTeamMemberPayload["search"] = "";
    newGetAllTeamMemberPayload["skip"] = 0;
    newGetAllTeamMemberPayload["pageNumber"] = 0;
    newGetAllTeamMemberPayload["filters"] = {};
    setGetAllTeamMemberPayload(newGetAllTeamMemberPayload);

    _getAllTeamMember(newGetAllTeamMemberPayload);
  };

  const _deactivateOrDeleteTeamMember = (key, value, id) => {
    setIsActiveLoading(id);

    const newTeamMembers = [...teamMembers];
    const findMember = newTeamMembers.find((member) => member._id === id);
    if (!findMember) {
      showToast(
        getValueFromCurrentLang("pages.General.somethingWentWrong"),
        "error"
      );
      return;
    }
    findMember[key] = value;
    setTeamMembers(newTeamMembers);

    updateTeamMember(id, { [key]: value })
      .then((res) => {
        setIsActiveLoading(false);
        if (key === "isDeleted") _successDelete();
        showToast(
          key === "isActive"
            ? value
              ? "Activated successfully"
              : "Deactivated successfully"
            : key === "isDeleted"
              ? "Member Deleted successfully"
              : getValueFromCurrentLang("pages.General.serverError"),
          "success"
        );
      })
      .catch((error) => {
        console.log("error>>", error);
        setIsActiveLoading(false);
        if (key === "isDeleted") _deleteAccountErrorAlert(error);
        _getAllTeamMember(getAllTeamMemberPayload);
        showToast(
          error?.reason?.length
            ? error.reason
            : getValueFromCurrentLang("pages.General.serverError"),
          "error"
        );
      });
  };

  const _successDelete = () => {
    _getAllTeamMember(getAllTeamMemberPayload);
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Account Deleted!"
        onConfirm={() => _hideAlert()}
        onCancel={() => _hideAlert()}
      />
    );
  };

  const _hideAlert = () => {
    setAlert(null);
  };

  const _deleteAccountErrorAlert = (error) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title="Acount is not deleted"
        onConfirm={() => _hideAlert()}
        onCancel={() => _hideAlert()}
        confirmBtnBsStyle="info"
      >
        {error?.reason?.length
          ? error.reason
          : getValueFromCurrentLang("pages.General.serverError")}
      </SweetAlert>
    );
  };

  const _onConfirmAlert = (teamMember) => {
    _deactivateOrDeleteTeamMember("isDeleted", true, teamMember._id);

    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={
          <div className="d-flex flex-wrap justify-content-center align-items-center">
            <Spinner className="mr-2" /> Deleting
            <span>
              {teamMember?.name?.first
                ? `${capitalize(teamMember.name.first)}'s`
                : teamMember?.email || "N/A"}{" "}
            </span>
            Account
          </div>
        }
        onConfirm={() => _hideAlert()}
        onCancel={() => _hideAlert()}
        showConfirm={false}
        cancelBtnBsStyle="danger"
        cancelBtnText={getValueFromCurrentLang("pages.General.dismiss")}
        showCancel
      />
    );
  };

  const _onDeleteTeamMemberAlert = (teamMember) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={getValueFromCurrentLang("pages.General.areYouSure")}
        onConfirm={() => _onConfirmAlert(teamMember)}
        onCancel={() => _hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText={getValueFromCurrentLang("pages.General.yesDeleteIt")}
        cancelBtnText={getValueFromCurrentLang("pages.General.cancel")}
        showCancel
      >
        You are deleting{" "}
        {teamMember?.name?.first
          ? `${capitalize(teamMember.name.first)}'s`
          : teamMember?.email || "N/A"}{" "}
        account.{" "}
        <LanguageParsedText keyString="pages.General.youWillNotRecoverIt" />
      </SweetAlert>
    );
  };

  useEffect(() => {
    // To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function
    return () => {
      mountedRef.current = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userCredential?.selectedResort?._id) {
      setActiveResortId(userCredential.selectedResort._id);

      _getAmenitiesList();

      _getAllTeamMember(getAllTeamMemberPayload);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCredential?.selectedResort?._id]);

  return (
    <>
      {alert}

      <div className="content">
        <Container fluid className="mobilePadding-0">
          <div className="page_Inner_header">
            <Row className="align-items-center">
              <Col xs={6} sm={8}>
                <div className="page_title">
                  <h1 className="space_remove">
                    <LanguageParsedText keyString="pages.TeamMembers.teamMembers" />{" "}
                    {filtersLoading || loading ? <Spinner /> : null}
                  </h1>
                </div>
              </Col>
              <Col
                xs={6} sm={4}
                className="text-right"
              >
                <Button
                  variant="primary"
                  onClick={() => _toggleAddTeamMemberModal(true)}
                >
                  <LanguageParsedText keyString="pages.General.createNew" />
                </Button>
              </Col>
            </Row>
          </div>

          {/* ======== Filter ======= */}
          <Card body className="search-filter pt-2 pb-3 mb-4">
            <Row>
              <Col xs={6} md={3} lg={2}>
                <FormGroup className="mb-0">
                  <Label>
                    <LanguageParsedText keyString="pages.TeamMembers.assignment" />
                  </Label>
                  <Input
                    type="select"
                    value={filters.roles}
                    onChange={(e) =>
                      _onChangeFilters("roles", e.target.value)
                    }
                  >
                    <option value="">
                      {getValueFromCurrentLang("pages.General.all")}
                    </option>
                    {assignToComplimentaryServicesConfig.map((each) => (
                      <option
                        key={each.value}
                        value={`${activeResortId}-${each.value}`}
                      >
                        {each.label}
                      </option>
                    ))}
                    {amenityArray.map((each) => (
                      <option key={each._id} value={each._id}>
                        {capitalize(each.name)}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col xs={6} md={3} lg={2}>
                <FormGroup className="mb-0">
                  <Label>
                    <LanguageParsedText keyString="pages.TeamMembers.permissions" />
                  </Label>
                  <Input
                    type="select"
                    value={filters.permissions}
                    onChange={(e) =>
                      _onChangeFilters("permissions", e.target.value)
                    }
                  >
                    <option value="">
                      {getValueFromCurrentLang("pages.General.all")}
                    </option>
                    {permissionsConfig.map((each) => (
                      <option
                        key={each.value}
                        value={`${activeResortId}-${each.value}`}
                      >
                        {each.label}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col>
                <div className="mt-3 mt-md-0 d-flex justify-content-md-end align-items-end">
                  <FormGroup className="mb-0">
                    <Label>
                      <LanguageParsedText keyString="pages.General.searchByName" />
                    </Label>
                    <InputGroup>
                      <FormControl
                        type="text"
                        placeholder={getValueFromCurrentLang(
                          "pages.General.enter"
                        )}
                        autoComplete="off"
                        value={getAllTeamMemberPayload.search}
                        onChange={(e) => _onChangeSearch(e.target.value)}
                      />
                      <InputGroup.Text id="basic-addon2">
                        {searchLoading ? (
                          <i className="fa fa-spinner fa-spin" />
                        ) : getAllTeamMemberPayload.search ? (
                          <i
                            className="fas fa-times"
                            onClick={() => _onChangeSearch("")}
                          />
                        ) : (
                          <i className="fas fa-search" />
                        )}
                      </InputGroup.Text>
                    </InputGroup>
                  </FormGroup>
                  <Button
                    color="primary"
                    className="btn-clear ml-4"
                    onClick={() => _clearFilters()}
                  >
                    <i className="fa fa-eraser fs-15" />
                  </Button>
                </div>
              </Col>
            </Row>
          </Card>

          <div className="mt-4 main_inner gridBox bg-white">
            <div className="userList">
              <Row className="text-center">
                {teamMembers?.length ? (
                  teamMembers.map((teamMember) => (
                    <Col key={teamMember._id} xl="3" lg="4" md="6" sm="12">
                      <div className="user_box">
                        <div className="user_image">
                          <img
                            src={
                              teamMember?.avatar
                                ? teamMember.avatar
                                : DEFAULT_PROFILE_PICTURE
                            }
                            onError={(e) =>
                              (e.target.src = DEFAULT_PROFILE_PICTURE)
                            }
                            alt="avatar"
                            loading="lazy"
                          />
                          <div className="switch_box">
                            <CustomInput
                              type="switch"
                              id={`team-member-custom-switch-${teamMember._id}`}
                              disabled={isActiveLoading === teamMember._id}
                              checked={teamMember.isActive}
                              onChange={(e) =>
                                _deactivateOrDeleteTeamMember(
                                  "isActive",
                                  e.target.checked,
                                  teamMember._id
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="user_content">
                          <h1>
                            {teamMember?.name?.full
                              ? capitalize(teamMember.name.full)
                              : "N/A"}

                            <span>
                              <i
                                title="Update Member"
                                className="fas fa-pencil-alt edit"
                                onClick={() =>
                                  _toggleAddTeamMemberModal(true, teamMember)
                                }
                              />
                              <i
                                title="Remove Member"
                                className="far fa-trash-alt detele"
                                onClick={() =>
                                  _onDeleteTeamMemberAlert(teamMember)
                                }
                              />
                            </span>
                          </h1>
                          <ul>
                            <li>
                              <img
                                src={
                                  require("../../assets/img/phone.png").default
                                }
                                alt="phone"
                                loading="lazy"
                              />
                              {teamMember?.phone
                                ? formatPhoneNumber(teamMember.phone)
                                : "N/A"}
                            </li>
                            <li>
                              <i className="far fa-envelope" />
                              {teamMember?.email || "N/A"}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  ))
                ) : loading ? (
                  <Col sm="12">
                    <Spinner />
                  </Col>
                ) : (
                  <Col sm="12">
                    <LanguageParsedText keyString="pages.General.noData" />
                  </Col>
                )}
              </Row>
            </div>
          </div>

          {teamMembers?.length && teamMembersCount ? (
            <div className="row mt-4">
              <div className="col-md-6">
                <span className="showng_entries">
                  <LanguageParsedText
                    keyString="pages.General.showingEntriesOutOf"
                    showCount={teamMembers?.length}
                    totalCount={teamMembersCount}
                  />
                </span>
              </div>
              <div className="col-md-6">
                <ReactPaginate
                  forcePage={getAllTeamMemberPayload.pageNumber}
                  className="pagination justify-content-end mb-2"
                  breakLabel="..."
                  nextLabel={<i className="fa fa-chevron-right" />}
                  previousLabel={<i className="fa fa-chevron-left" />}
                  previousClassName="previous"
                  nextClassName="next"
                  onPageChange={({ selected }) => _onPageChange(selected)}
                  pageRangeDisplayed={3}
                  pageCount={Math.ceil(
                    teamMembersCount / getAllTeamMemberPayload.limit
                  )}
                  pageClassName="page-item"
                  renderOnZeroPageCount={null}
                  containerClassName={"pagination"}
                  previousLinkClassName={"page-link"}
                  nextLinkClassName={"page-link"}
                  activeClassName={"active"}
                  disabledClassName={"page-item"}
                />
              </div>
            </div>
          ) : null}
        </Container>
      </div>

      {/* <AdminFooter /> */}

      {/* modal */}
      <AddTeamMemberModal
        isOpen={addTeamMemberModal.isOpen}
        data={addTeamMemberModal.data}
        activeResortId={activeResortId}
        amenityArray={amenityArray}
        toggle={() => _toggleAddTeamMemberModal()}
        getAllTeamMembers={() => _onPageChange()}
        resetCurrentData={() => _getAllTeamMember(getAllTeamMemberPayload)}
      />
    </>
  );
};

export default TeamMembers;
