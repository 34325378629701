export const ADD_USER_CREDENTIAL = "ADD_USER_CREDENTIAL";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const UPDATE_ASSIGNED_RESORTS = "UPDATE_ASSIGNED_RESORTS";
export const SWITCH_ASSIGNED_RESORTS = "SWITCH_ASSIGNED_RESORTS";
export const CLEAR_USER_CREDENTIAL = "CLEAR_USER_CREDENTIAL";

// loader data actions
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

// settings actions
export const UPDATE_SETTINGS = "UPDATE_SETTINGS";
export const CLEAR_SETTINGS = "CLEAR_SETTINGS";

// Language actions
export const UPDATE_CURRENT_LANGUAGE = "STORE UPDATE_CURRENT_LANGUAGE";

// Notification counter
export const UPDATE_COUNTER="UPDATE_COUNTER"