import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";
import { StripeProvider } from "react-stripe-elements";
import { OneSignalKey, STRIPE_API_KEY } from "./config/index";
import { persistor, store } from "./redux/store";
import ResortLayout from "./views/containers/ResortLayout";
import FullPageLoader from "./views/containers/full-page-loader";
import LoginPage from "./views/publicPages/LoginPage";
import RegisterPage from "./views/publicPages/RegisterPage";
import ForgotPassword from "./views/publicPages/ForgotPassowrdPage";
import PublicRoute from "./components/public-route";
import ProtectedRoute from "./components/protected-routes";
import ErrorNotFound from "./components/Error-not-found";
import MultiLang from "./multi-lang";
import { en, sp } from "./multi-lang/langs";
import { getCurrentLangFromRedux } from "./helper-methods";
import { updateCurrentLanguage } from "./redux/actions/language-data";

const App = () => {
  const _languageSetup = async () => {
    try {
      // Initialize multi lang
      MultiLang.setLangModules({
        en,
        sp,
      });

      // Set default lang
      MultiLang.setDefaultLang("en");

      // Set current lang
      const currentLanguage = await getCurrentLangFromRedux();

      MultiLang.setCurrentLang(currentLanguage);

      store.dispatch(updateCurrentLanguage(currentLanguage));
    } catch (error) {
     // console.log("error>>", error);
    }
  };

  const _oneSignalSetup = () => {
    var OneSignal = window.OneSignal || [];
    OneSignal.push(function () {
      OneSignal.init({
        appId: OneSignalKey,
        notifyButton: {
          enable: false,
        },
        requiresUserPrivacyConsent: false,
        autoResubscribe: true,
      });
    });
  };

  useEffect(() => {
    // multi language setup
    _languageSetup();

    // one signal setup
    _oneSignalSetup();
  }, []);

  return (
    <StripeProvider apiKey={STRIPE_API_KEY}>
      <Provider store={store}>
        <PersistGate loading={<div></div>} persistor={persistor}>
          <Router>
            {/* //Public Routes */}
            <div className="vayco-auth">
              <FullPageLoader />

              <Toaster position="bottom-right" reverseOrder={false} />

              <Switch>
                <PublicRoute
                  exact
                  path="/register"
                  component={RegisterPage}
                  redirectRoute={"/"}
                />
                <PublicRoute
                  exact
                  path="/forgot-password"
                  component={ForgotPassword}
                  redirectRoute={"/"}
                />
                <PublicRoute
                  exact
                  path="/login"
                  component={LoginPage}
                  redirectRoute={"/"}
                />

                <ProtectedRoute
                  exact
                  path="*"
                  component={ResortLayout}
                  redirectRoute="/login"
                />

                <Route component={ErrorNotFound} />
              </Switch>
            </div>
          </Router>
        </PersistGate>
      </Provider>
    </StripeProvider>
  );
};

export default App;
