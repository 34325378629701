import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Col, Container, Row, List, Spinner } from "reactstrap";
import {
  formatTimeFromNow,
  generateNotificationCalenderDate,
  showToast,
} from "../../helper-methods";
import {
  getAllNotifications,
  readAllNotifications,
  readNotification
} from "../../http/http-calls";
import LanguageParsedText, {
  getValueFromCurrentLang,
} from "../../multi-lang/lang-parsed-text/LanguageParsedText";
import { DEFAULT_PROFILE_PICTURE } from "../../config/index.js";
import InfiniteScroll from "react-infinite-scroller";

const NotificationsPage = () => {
  const history = useHistory();

  const userCredential = useSelector((state) => state["userCredential"]);
  const [getAllNotificationsPayload, setGetAllNotificationsPayload] = useState({
    skip: 0,
    limit: 20,
  });
  const [notifications, setNotifications] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadMoreNotifications, setLoadMoreNotifications] = useState(false);

  const [requestId , setRequestId] = useState({"notification_id":""});
  
  const _readAllNotifications = (resortId) => {
    readAllNotifications(resortId)
      .then((res) => {
        console.log("read");
      })
      .catch((error) => {
        console.log("error>>", error);
      });
  };

  const _readNotification = (Id) => {
    let countVal = localStorage.getItem("countVal")-1;
        localStorage.setItem("countVal", countVal);
    setRequestId({"notification_id":"resortId"})
    readNotification(Id)
      .then((res) => {
        console.log("read");
        
      })
      .catch((error) => {
        console.log("error>>", error);
      });
  }; 

  const _getAllNotifications = (payload) => {
    setLoading(true);
    getAllNotifications(payload)
      .then((res) => {
        if (payload.skip > 0)
          setNotifications((prev) => [...prev, ...res.notifications]);
        else setNotifications(res.notifications || []);

        setNotificationsCount(res.count || []);
        setLoading(false);
        setLoadMoreNotifications(false);
      })
      .catch((error) => {
        console.log("error>>", error);
        setLoading(false);
        showToast(
          error?.reason?.length
            ? error.reason
            : getValueFromCurrentLang("pages.General.serverError"),
          "error"
        );
      });
  };

  const _loadMoreNotifications = (isReset = false) => {
   // console.log("vibhu  kaushik")
    if (!loadMoreNotifications) {
      setLoadMoreNotifications(true);

      const newGetAllNotificationsPayload = { ...getAllNotificationsPayload };
      if (isReset) newGetAllNotificationsPayload["skip"] = 0;
      else newGetAllNotificationsPayload["skip"] = notifications.length;
      setGetAllNotificationsPayload(newGetAllNotificationsPayload);
      _getAllNotifications(newGetAllNotificationsPayload);
    }
  };

  useEffect(() => {
    _loadMoreNotifications(true);

    return () => {
   //_readAllNotifications(userCredential?.selectedResort?._id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCredential?.selectedResort?._id]);

  const _redirectNotification = (notification) => {
   // console.log("vivivimmmm")
    _readNotification(notification._id)
    if (
      (notification?.type === "amenityBooking" ||
        notification?.type === "serviceBooking") &&
      notification?._booking?._id
    ) {
      history.push(`/requests?requestId=${notification._booking._id}`);
    }
  };

  return (
    <div className="content">
      <Container fluid>
        {/* ===== Page Title ===== */}
        <div className="page_Inner_header">
          <Row className="align-items-center no-margin">
            <Col xl={8} className="mobilePadding-0">
              <div className="page_title">
                <Button
                  color="link"
                  className="pl-0 pr-2"
                  onClick={() => history.goBack()}
                >
                  <i className="fa fa-chevron-left" />
                </Button>
                <h1 className="space_remove">
                  <LanguageParsedText keyString="pages.General.notifications" />{" "}
                  {loading ? <Spinner /> : null}
                </h1>
              </div>
            </Col>
          </Row>
        </div>

        <div className="mt-4 main_inner bg-white">
          <InfiniteScroll
            hasMore={
              notifications.length < notificationsCount ? true || false : false
            }
            loadMore={() => {console.log("kites"); _loadMoreNotifications()}}
            loader={
              notifications.length < notificationsCount &&
              loadMoreNotifications ? (
                <div className="text-center" key="notificationsSpinner">
                  <Spinner className="chatSpinner" />
                </div>
              ) : null
            }
            useWindow={true}
          >
            <div className="notificationContentWrap">
              {notifications.length ? (
                notifications.map((notification, index) => (
                  <div
                    key={notification.id || index}
                    className={[
                      (notification.type === "amenityBooking" ||
                        notification.type === "serviceBooking") &&
                      notification?._booking?._id
                        ? "cursorPointer"
                        : "",
                      "notificationInnerRow",
                    ].join(" ")}
                    onClick={() => _redirectNotification(notification)}
                  >
                    {index === 0 ||
                    generateNotificationCalenderDate(notification.createdAt) !==
                      generateNotificationCalenderDate(
                        notifications[index - 1].createdAt
                      ) ? (
                      <>
                        {index > 0 ? <hr /> : null}
                        <div className="notificationDay">
                          {generateNotificationCalenderDate(
                            notification.createdAt
                          )}
                        </div>
                      </>
                    ) : null}

                    <List
                      className={`${
                        notification.isRead ? "" : "readNotification"
                      }`}
                    >
                      <li>
                        <div className="d-flex flex-column align-items-center">
                          <img
                            src={
                              notification._from.avatar ||
                              DEFAULT_PROFILE_PICTURE
                            }
                            alt="avatar"
                            loading="lazy"
                            onError={(e) =>
                              (e.target.src = DEFAULT_PROFILE_PICTURE)
                            }
                          />
                        </div>

                        <div className="ml-3 d-flex flex-column justify-content-center">
                          <h2 className="d-flex align-items-center">
                            <b>{notification._from.name.full}</b>{" "}
                            {/* <p className="mb-0 ml-2 fs-14">(@Mark_Bill)</p> */}
                          </h2>

                          <p className="Notification_content">
                            {notification.text}
                            <span className="commentTime">
                              {formatTimeFromNow(notification.createdAt, true)}
                            </span>
                          </p>
                        </div>
                      </li>
                    </List>
                  </div>
                ))
              ) : (
                <div className="text-center">
                  {loading ? (
                    <Spinner />
                  ) : (
                    "There is no notification to display."
                  )}
                </div>
              )}
            </div>
          </InfiniteScroll>
        </div>
      </Container>
    </div>
  );
};

export default NotificationsPage;
