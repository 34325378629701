import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupText,
  Input,
  Spinner,
} from "reactstrap";
import { countryCodes } from "../../config/country-codes";
import { StatesConfig } from "../../config/states";
import { showToast } from "../../helper-methods";
import { requestForAddNewResort } from "../../http/http-calls";
import SweetAlert from "react-bootstrap-sweetalert";
import { APP_NAME } from "../../config";
import LanguageParsedText, {
  getValueFromCurrentLang,
} from "../../multi-lang/lang-parsed-text/LanguageParsedText";

const AddResort = () => {
  const history = useHistory();

  const [formFields, setFormFields] = useState({
    resortName: {
      value: "",
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
    resortAddress: {
      value: "",
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
    country: {
      value: "US",
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
    state: {
      value: "",
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
    city: {
      value: "",
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
    zipcode: {
      value: "",
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
    numberOfRooms: {
      value: "",
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
    comments: {
      value: "",
      error: null,
      isDirty: false,
      isValidationRequired: false,
    },
  });
  const [loading, setLoading] = useState(false);

  const [alert, setAlert] = useState(null);

  const _validateFormFields = (newFormFields) => {
    return new Promise((resolve) => {
      let isFormValid = true;

      Object.keys(newFormFields).forEach((key) => {
        if (newFormFields[key].isDirty) {
          switch (key) {
            case "zipcode":
            case "city":
            case "state":
            case "country":
            case "resortAddress":
            case "resortName":
            case "numberOfRooms": {
              if (formFields[key].value?.trim().length) {
                formFields[key].error = null;
                formFields[key].isDirty = false;
              } else {
                formFields[key].error = getValueFromCurrentLang(
                  "pages.General.required"
                );
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      setFormFields(newFormFields);

      resolve(isFormValid);
    });
  };

  const _onChangeFormFields = (key, value) => {
    if (key === "numberOfRooms" && (isNaN(value) || value.includes(".")))
      return;

    const newFormFields = { ...formFields };

    newFormFields[key].value = value;
    newFormFields[key].isDirty = true;
    if (key === "country") {
      newFormFields.state.value = "";
      newFormFields.state.error = null;
      newFormFields.state.isDirty = false;
    }

    setFormFields(newFormFields);
    _validateFormFields(newFormFields);
  };

  const _hideAlert = () => {
    setAlert(null);
    history.goBack();
  };

  const _successSubmission = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={getValueFromCurrentLang(
          "pages.General.afterResortRegisterReviewNote",
          { appName: APP_NAME }
        )}
        onConfirm={() => _hideAlert()}
        onCancel={() => _hideAlert()}
      />
    );
  };

  const _requestForAddNewResort = (payload) => {
    requestForAddNewResort(payload)
      .then((res) => {
        setLoading(false);
        _successSubmission();
      })
      .catch((error) => {
        console.log("error>>", error);
        setLoading(false);
        showToast(
          error?.reason?.length
            ? error.reason
            : getValueFromCurrentLang("pages.General.serverError"),
          "error"
        );
      });
  };

  const _onSubmitAddResortForm = async (event) => {
    try {
      if (event) event.preventDefault();

      const newFormFields = { ...formFields };
      Object.keys(newFormFields).forEach(
        (key) => (newFormFields[key].isDirty = true)
      );
      setFormFields(newFormFields);
      const isFormValid = await _validateFormFields(newFormFields);

      if (isFormValid) {
        setLoading(true);

        const payload = {
          organizationName: formFields.resortName.value.trim(),
          address: {
            street: formFields.resortAddress.value.trim(),
            city: formFields.city.value.trim(),
            state: formFields.state.value.trim(),
            zip: formFields.zipcode.value.trim(),
            country: formFields.country.value.trim(),
          },
          numberOfRooms: formFields.numberOfRooms.value.trim(),
          comments: formFields.comments.value.trim(),
        };

        _requestForAddNewResort(payload);
      }
    } catch (error) {
      console.log("error>>", error);
      setLoading(false);
      showToast(
        error?.reason?.length
          ? error.reason
          : getValueFromCurrentLang("pages.General.somethingWentWrong"),
        "error"
      );
    }
  };

  return (
    <>
      {alert}

      <div className="content">
        <Container fluid className="mobilePadding-0">
          <div className="page_Inner_header">
            <Row className="align-items-center">
              <Col xl={5} md={4}>
                <div className="page_title">
                  <Button
                    color="link"
                    className="pl-0 pr-2"
                    onClick={() => history.goBack()}
                  >
                    <i className="fa fa-chevron-left" />
                  </Button>
                  <h1 className="space_remove pr-2">
                    <LanguageParsedText keyString="pages.Resorts.addResort" />{" "}
                    {loading ? <Spinner /> : null}
                  </h1>
                </div>
              </Col>
            </Row>
          </div>

          {/* ====== add resort Form ====== */}
          <div className="mt-4 main_inner gridBox bg-white">
            <div className="innerForm py-3">
              <Form onSubmit={(e) => _onSubmitAddResortForm(e)}>
                <Row className="no-margin">
                  <Col md={6} className="mobilePadding-0">
                    <FormGroup>
                      <Label>
                        <LanguageParsedText keyString="pages.Resorts.resortName" />
                      </Label>
                      <InputGroup>
                        <InputGroupText>
                          <img
                            src={require("../../assets/img/hotel.svg").default}
                            alt="user"
                            className="img-fluid w-20"
                            loading="lazy"
                          />
                        </InputGroupText>
                        <Input
                          type="text"
                          placeholder={getValueFromCurrentLang(
                            "pages.Resorts.enterYourResortName"
                          )}
                          autoComplete="off"
                          value={formFields.resortName.value}
                          onChange={(e) =>
                            _onChangeFormFields("resortName", e.target.value)
                          }
                          disabled={loading}
                        />
                      </InputGroup>
                      {formFields.resortName.error ? (
                        <div className="form-error">
                          {formFields.resortName.error}
                        </div>
                      ) : null}
                    </FormGroup>
                  </Col>

                  <Col md={6} className="mobilePadding-0">
                    <FormGroup>
                      <Label>
                        <LanguageParsedText keyString="pages.Resorts.numberOfRooms" />
                      </Label>
                      <InputGroup>
                        <InputGroupText>
                          <img
                            src={require("../../assets/img/hotel.svg").default}
                            alt="user"
                            className="img-fluid w-20"
                            loading="lazy"
                          />
                        </InputGroupText>
                        <Input
                          type="text"
                          placeholder={getValueFromCurrentLang(
                            "pages.Resorts.enterTheNumberOfRooms"
                          )}
                          autoComplete="off"
                          value={formFields.numberOfRooms.value}
                          onChange={(e) =>
                            _onChangeFormFields("numberOfRooms", e.target.value)
                          }
                          disabled={loading}
                        />
                      </InputGroup>
                      {formFields.numberOfRooms.error ? (
                        <div className="form-error">
                          {formFields.numberOfRooms.error}
                        </div>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>

                <FormGroup>
                  <Label>
                    <LanguageParsedText keyString="pages.Resorts.resortAddress" />
                  </Label>
                  <InputGroup>
                    <InputGroupText>
                      <img
                        src={require("../../assets/img/location.svg").default}
                        alt="user"
                        className="img-fluid"
                        loading="lazy"
                      />
                    </InputGroupText>
                    <Input
                      type="text"
                      placeholder={getValueFromCurrentLang(
                        "pages.Resorts.enterYourResortAddress"
                      )}
                      autoComplete="off"
                      value={formFields.resortAddress.value}
                      onChange={(e) =>
                        _onChangeFormFields("resortAddress", e.target.value)
                      }
                      disabled={loading}
                    />
                  </InputGroup>
                  {formFields.resortAddress.error ? (
                    <div className="form-error">
                      {formFields.resortAddress.error}
                    </div>
                  ) : null}
                </FormGroup>

                <Row className="no-margin">
                  <Col md={6} className="mobilePadding-0">
                    <FormGroup>
                      <Label>
                        <LanguageParsedText keyString="pages.Resorts.country" />
                      </Label>
                      <InputGroup>
                        <InputGroupText>
                          <img
                            src={require("../../assets/img/city.svg").default}
                            alt="State"
                            className="img-fluid"
                            loading="lazy"
                          />
                        </InputGroupText>
                        <Input
                          type="select"
                          value={formFields.country.value}
                          onChange={(e) =>
                            _onChangeFormFields("country", e.target.value)
                          }
                          disabled={loading}
                        >
                          {countryCodes.map((each) => (
                            <option key={each.code} value={each.code}>
                              {each.name}
                            </option>
                          ))}
                        </Input>
                      </InputGroup>
                      {formFields.country.error ? (
                        <div className="form-error">
                          {formFields.country.error}
                        </div>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md={6} className="mobilePadding-0">
                    <FormGroup>
                      <Label>
                        <LanguageParsedText keyString="pages.Resorts.state" />
                      </Label>
                      <InputGroup>
                        <InputGroupText>
                          <img
                            src={require("../../assets/img/city.svg").default}
                            alt="city"
                            className="img-fluid"
                            loading="lazy"
                          />
                        </InputGroupText>

                        {formFields.country.value &&
                        (formFields.country.value === "IN" ||
                          formFields.country.value === "US" ||
                          formFields.country.value === "MX") ? (
                          <Input
                            type="select"
                            name="state"
                            value={formFields.state.value}
                            onChange={(e) =>
                              _onChangeFormFields("state", e.target.value)
                            }
                            disabled={loading}
                          >
                            <option value="">
                              {getValueFromCurrentLang(
                                "pages.Resorts.selectState"
                              )}
                            </option>
                            {StatesConfig[formFields.country.value].map(
                              (stateCode, stateIndex) => (
                                <option key={stateIndex} value={stateCode.code}>
                                  {stateCode.name}
                                </option>
                              )
                            )}
                          </Input>
                        ) : (
                          <Input
                            type="text"
                            placeholder={getValueFromCurrentLang(
                              "pages.Resorts.enterTheState"
                            )}
                            name="state"
                            value={formFields.state.value}
                            onChange={(e) =>
                              _onChangeFormFields("state", e.target.value)
                            }
                            disabled={loading}
                          />
                        )}
                      </InputGroup>
                      {formFields.state.error ? (
                        <div className="form-error">
                          {formFields.state.error}
                        </div>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md={6} className="mobilePadding-0">
                    <FormGroup>
                      <Label>
                        <LanguageParsedText keyString="pages.Resorts.city" />
                      </Label>
                      <InputGroup>
                        <InputGroupText>
                          <img
                            src={require("../../assets/img/zip.svg").default}
                            alt="city"
                            className="img-fluid"
                            loading="lazy"
                          />
                        </InputGroupText>
                        <Input
                          type="text"
                          placeholder={getValueFromCurrentLang(
                            "pages.Resorts.enterTheCity"
                          )}
                          value={formFields.city.value}
                          onChange={(e) =>
                            _onChangeFormFields("city", e.target.value)
                          }
                          disabled={loading}
                        />
                      </InputGroup>
                      {formFields.city.error ? (
                        <div className="form-error">
                          {formFields.city.error}
                        </div>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md={6} className="mobilePadding-0">
                    <FormGroup>
                      <Label>
                        <LanguageParsedText keyString="pages.Resorts.zipcode" />
                      </Label>
                      <InputGroup>
                        <InputGroupText>
                          <img
                            src={require("../../assets/img/city.svg").default}
                            alt="State"
                            className="img-fluid"
                            loading="lazy"
                          />
                        </InputGroupText>
                        <Input
                          type="text"
                          placeholder={getValueFromCurrentLang(
                            "pages.Resorts.enterTheZipcode"
                          )}
                          value={formFields.zipcode.value}
                          onChange={(e) =>
                            _onChangeFormFields("zipcode", e.target.value)
                          }
                          disabled={loading}
                        />
                      </InputGroup>
                      {formFields.zipcode.error ? (
                        <div className="form-error">
                          {formFields.zipcode.error}
                        </div>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>

                <FormGroup className="commentArea">
                  <Label>
                    <LanguageParsedText keyString="pages.Resorts.commentsIfAny" />
                  </Label>
                  <InputGroup className="textarea">
                    <InputGroupText>
                      <img
                        src={require("../../assets/img/comment.svg").default}
                        alt="user"
                        className="img-fluid"
                        loading="lazy"
                      />
                    </InputGroupText>
                    <Input
                      type="textarea"
                      placeholder={getValueFromCurrentLang(
                        "pages.Resorts.enterTheComments"
                      )}
                      name="comments"
                      value={formFields.comments.value}
                      onChange={(e) =>
                        _onChangeFormFields("comments", e.target.value)
                      }
                      disabled={loading}
                    />
                  </InputGroup>
                </FormGroup>

                <Row noGutters className="mt-sm-5 mt-3 align-items-center">
                  <Col xs={12} className="text-center mt-sm-0 mt-3">
                    <Button
                      color="primary"
                      className="btn-cancel px-5 mr-3"
                      disabled={loading}
                      onClick={() => history.goBack()}
                    >
                      <LanguageParsedText keyString="pages.General.cancel" />
                    </Button>
                    <Button
                      type="submit"
                      className="btn-primary px-5"
                      disabled={loading}
                    >
                      {loading ? (
                        <i className="fa fa-spinner fa-spin mr-1" />
                      ) : null}{" "}
                      <LanguageParsedText keyString="pages.Resorts.addResort" />
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default AddResort;
