import React, { useEffect } from "react";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { Table, Button, Container, Row, Col, Spinner } from "reactstrap";
import PaymentModal from "../../components/Modals/PaymentModal";
import { BASE_URL } from "../../config";
import {
  formatCurrencyValue,
  formatDate,
  showToast,
} from "../../helper-methods";
import { getAllResortBillings, payResortBill } from "../../http/http-calls";
import { getAndUpdateUserData } from "../../redux/actions/user-credential";
import { useDispatch } from "react-redux";
import { useMemo } from "react";
import { subscriptionPricing } from "../../config/helper-config";
import LanguageParsedText, {
  getValueFromCurrentLang,
} from "../../multi-lang/lang-parsed-text/LanguageParsedText";

const SubscriptionInvoice = () => {
  const userCredential = useSelector((state) => state["userCredential"]);

  const dispatch = useDispatch();

  const [getAllBillingsPayload, setGetAllBillingsPayload] = useState({
    skip: 0,
    limit: 10,
    pageNumber: 0,
  });
  const [billings, setBillings] = useState([]);
  const [billingsCount, setBillingsCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const [paymentLoading, setPaymentLoading] = useState(false);

  const [paymentModal, setPaymentModal] = useState({
    isOpen: false,
    data: null,
  });

  const subscriptionPrice = useMemo(() => {
    const numberOfRooms = userCredential?.selectedResort?.numberOfRooms;

    if (numberOfRooms) {
      const findPriceSlab = subscriptionPricing.find(
        (each) =>
          (!each.rooms.max &&
            each.rooms.min &&
            each.rooms.min < numberOfRooms) ||
          (each.rooms.max &&
            each.rooms.min &&
            each.rooms.max >= numberOfRooms &&
            each.rooms.min <= numberOfRooms)
      );

      return findPriceSlab?.monthlyPrice ? findPriceSlab.monthlyPrice : 0;
    }

    return 0;
  }, [userCredential?.selectedResort?.numberOfRooms]);

  const _getAllResortBillings = (payload) => {
    setLoading(true);

    getAllResortBillings(payload)
      .then((res) => {
        setBillings(res.billings.length ? res.billings : []);
        setBillingsCount(res.count ? res.count : 0);
        setLoading(false);
        setPaymentLoading(false);
      })
      .catch((error) => {
        console.log("error>>", error);
        setLoading(false);
        setPaymentLoading(false);
        showToast(
          error?.reason?.length
            ? error.reason
            : getValueFromCurrentLang("pages.General.serverError"),
          "error"
        );
      });
  };

  const _onPageChange = (pageNumber = 0) => {
    const newGetAllBillingsPayload = { ...getAllBillingsPayload };
    newGetAllBillingsPayload["skip"] =
      pageNumber * newGetAllBillingsPayload["limit"];
    newGetAllBillingsPayload["pageNumber"] = pageNumber;
    setGetAllBillingsPayload(newGetAllBillingsPayload);
    _getAllResortBillings(newGetAllBillingsPayload);
  };

  const _downloadSubscriptionInvoice = (bill) => {
    const params = {
      userId: userCredential?.user?._id,
      billingId: bill?._id,
      resortId: userCredential?.selectedResort?._id,
    };

    window.open(
      `${BASE_URL}/resort/download/bill?userId=${params.userId}&billingId=${params.billingId}&resortId=${params.resortId}`,
      "_blank"
    );
  };

  const _payResortBill = () => {
    if (paymentModal?.data?._id) {
      _togglePaymentModal();
      setPaymentLoading(paymentModal.data._id);

      payResortBill()
        .then((res) => {
          _getAllResortBillings(getAllBillingsPayload);
          dispatch(getAndUpdateUserData());

          showToast("Subscription purchased successfully", "success");
        })
        .catch((error) => {
          console.log("error>>", error);
          setPaymentLoading(false);
          showToast(
            error?.reason?.length
              ? error.reason
              : getValueFromCurrentLang("pages.General.serverError"),
            "error"
          );
        });
    } else {
      _togglePaymentModal();
      showToast(
        getValueFromCurrentLang("pages.General.somethingWentWrong"),
        "error"
      );
    }
  };

  const _togglePaymentModal = (isOpen = false, data = null) => {
    setPaymentModal({ isOpen, data });
  };

  useEffect(() => {
    if (userCredential?.selectedResort?._id) {
      _getAllResortBillings(getAllBillingsPayload);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCredential?.selectedResort?._id]);

  return (
    <>
      <div className="content">
        <Container fluid className="mobilePadding-0">
          <div className="page_Inner_header">
            <Row className="align-items-center">
              <Col xl={5} md={4}>
                <div className="page_title">
                  <h1 className="space_remove">
                    <LanguageParsedText keyString="pages.Billings.billing" />{" "}
                    {loading ? <Spinner /> : null}
                  </h1>
                </div>
              </Col>
            </Row>
          </div>

          {/* ======== Table ======== */}
          <Table responsive className="m-0">
            <thead>
              <tr>
                <th>
                  <LanguageParsedText keyString="pages.Billings.plan" />
                </th>
                <th>
                  <LanguageParsedText keyString="pages.Billings.billingPeriod" />
                </th>
                <th>
                  <LanguageParsedText keyString="pages.Billings.numberOfRooms" />
                </th>
                <th>
                  <LanguageParsedText keyString="pages.Billings.amount" />
                </th>
                <th>
                  <LanguageParsedText keyString="pages.Billings.dueDate" />
                </th>
                <th>
                  <LanguageParsedText keyString="pages.Billings.date" />
                </th>
                <th>
                  <LanguageParsedText keyString="pages.General.status" />
                </th>
                <th>
                  <LanguageParsedText keyString="pages.General.action" />
                </th>
              </tr>
            </thead>
            <tbody>
              {billings?.length ? (
                billings.map((bill) => (
                  <tr key={bill._id}>
                    <td>{bill.plan || "N/A"}</td>
                    <td>
                      {bill.start ? formatDate(bill.start) : "N/A"}{" "}
                      {bill.end ? `to ${formatDate(bill.end)}` : ""}
                    </td>
                    <td>{bill.rooms || "N/A"}</td>
                    <td>
                      {bill.amount || bill.amount === 0
                        ? formatCurrencyValue(bill.amount)
                        : "N/A"}
                    </td>
                    <td>{bill.end ? formatDate(bill.end) : "N/A"}</td>
                    <td>
                      {bill.createdAt ? formatDate(bill.createdAt) : "N/A"}
                    </td>
                    <td>{bill.status || "N/A"}</td>
                    <td>
                      {bill.status === "Paid" ? (
                        <Button
                          className="btn-primary-outline px-4 mw-130"
                          onClick={() => _downloadSubscriptionInvoice(bill)}
                        >
                          <LanguageParsedText keyString="pages.General.download" />
                        </Button>
                      ) : bill.status === "Failed" ? (
                        <Button
                          color="primary"
                          className="px-4 mw-130"
                          onClick={() => _togglePaymentModal(true, bill)}
                          disabled={paymentLoading === bill._id}
                        >
                          {paymentLoading === bill._id ? (
                            <i className="fa fa-spinner fa-spin mr-1" />
                          ) : null}
                          {paymentLoading === bill._id
                            ? getValueFromCurrentLang(
                                "pages.Billings.processings"
                              )
                            : getValueFromCurrentLang("pages.Billings.pay")}
                        </Button>
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="text-center">
                  <td colSpan="8">
                    {loading ? (
                      <Spinner />
                    ) : (
                      <LanguageParsedText keyString="pages.General.noData" />
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          {billings?.length && billingsCount ? (
            <div className="row mt-4">
              <div className="col-md-6">
                <span className="showng_entries">
                  <LanguageParsedText
                    keyString="pages.General.showingEntriesOutOf"
                    showCount={billings?.length}
                    totalCount={billingsCount}
                  />
                </span>
              </div>
              <div className="col-md-6">
                <ReactPaginate
                  forcePage={getAllBillingsPayload.pageNumber}
                  className="pagination justify-content-end mb-2"
                  breakLabel="..."
                  nextLabel={<i className="fa fa-chevron-right" />}
                  previousLabel={<i className="fa fa-chevron-left" />}
                  previousClassName="previous"
                  nextClassName="next"
                  onPageChange={({ selected }) => _onPageChange(selected)}
                  pageRangeDisplayed={3}
                  pageCount={Math.ceil(
                    billingsCount / getAllBillingsPayload.limit
                  )}
                  pageClassName="page-item"
                  renderOnZeroPageCount={null}
                  containerClassName={"pagination"}
                  previousLinkClassName={"page-link"}
                  nextLinkClassName={"page-link"}
                  activeClassName={"active"}
                  disabledClassName={"page-item"}
                />
              </div>
            </div>
          ) : null}
        </Container>
      </div>

      <PaymentModal
        isOpen={paymentModal.isOpen}
        data={paymentModal.data}
        toggle={() => _togglePaymentModal()}
        onBuySubscription={(bill) => _payResortBill(bill)}
        subscriptionPrice={subscriptionPrice}
      />
    </>
  );
};

export default SubscriptionInvoice;
