import React, { useState } from "react";
import {
  Button,
  Form,
  Label,
  FormGroup,
  InputGroup,
  InputGroupText,
  Input,
} from "reactstrap";
import AuthFooter from "../../components/Footers/AuthFooter";
import { hideLoader, showLoader } from "../../redux/actions/loader-data";
import { showToast } from "../../helper-methods";
import { forgotPassword } from "../../http/http-calls";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { RegexConfig } from "../../config/RegexConfig";
import { APP_LOGO, LOGIN_PAGE_BANNER } from "../../config/index";
import LanguageParsedText, {
  getValueFromCurrentLang,
} from "../../multi-lang/lang-parsed-text/LanguageParsedText";

const ForgotPassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [credentials, setCredentials] = useState({
    handle: "",
  });
  const [isDirty, setIsDirty] = useState({
    handle: false,
  });
  const [errors, setErrors] = useState({});

  const _validateFormFields = (newCredentials, newIsDirty) => {
    return new Promise((resolve) => {
      const newErrors = { ...errors };
      let isFormValid = true;

      Object.keys(newCredentials).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "handle": {
              if (newCredentials[key]?.trim().length) {
                if (
                  RegexConfig.email.test(
                    String(newCredentials[key]).toLowerCase()
                  )
                ) {
                  delete newErrors[key];
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = `*${getValueFromCurrentLang(
                    "pages.General.invalid"
                  )} email`;
                  isFormValid = false;
                }
              } else {
                newErrors[key] = getValueFromCurrentLang(
                  "pages.General.required"
                );
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      setErrors(newErrors);
      setIsDirty(newIsDirty);

      resolve(isFormValid);
    });
  };

  const _onChangeFormFields = (key, value) => {
    const newCredentials = { ...credentials };
    const newIsDirty = { ...isDirty };
    newCredentials[key] = value;
    newIsDirty[key] = true;
    setCredentials(newCredentials);
    setIsDirty(newIsDirty);
    _validateFormFields(newCredentials, newIsDirty);
  };

  const _onSubmitForm = async (event) => {
    if (event) event.preventDefault();

    const newIsDirty = {
      handle: true,
    };

    const isFormValid = await _validateFormFields(credentials, newIsDirty);

    if (isFormValid) {
      dispatch(showLoader("Loading..."));

      const payload = {
        handle: credentials.handle.trim(),
        userType: "ResortAdmin",
      };

      forgotPassword(payload)
        .then((res) => {
          dispatch(hideLoader());
          showToast(
            "We have emailed the reset password instructions.",
            "success"
          );
          history.push("/login");
        })
        .catch((error) => {
          console.log("error>>", error);
          dispatch(hideLoader());
          showToast(
            error?.reason?.length
              ? error.reason
              : getValueFromCurrentLang("pages.General.serverError"),
            "error"
          );
        });
    }
  };

  return (
    <div className="vayco-public login_singup">
      <div className="public-header">
        <img
          src={APP_LOGO}
          onClick={() => history.push("/login")}
          alt="Logo"
          className="company-logo"
          width="140"
          loading="lazy"
        />

        <Button
          color="link"
          onClick={() => history.push("/login")}
          className="loginLink"
        >
          <span>Sign In</span>
        </Button>
      </div>
      <div className="app animated fadeIn ">
        <div className="login_wrapepr d-flex align-items-center">
          <div className="login_left">
            <img
              src={LOGIN_PAGE_BANNER}
              alt="banner"
              className="img-fluid"
              loading="lazy"
            />
          </div>
          <div className="login_right">
            <div className="login_Container">
              <div className="form-title">
                <h2 className="mt-0">
                  <LanguageParsedText keyString="pages.Login.forgotPassword" />
                </h2>
              </div>

              <Form onSubmit={(e) => _onSubmitForm(e)}>
                <FormGroup>
                  <Label>
                    <LanguageParsedText keyString="pages.General.email" />
                  </Label>
                  <InputGroup>
                    <InputGroupText>
                      <i className="fas fa-user" />
                    </InputGroupText>
                    <Input
                      type="text"
                      placeholder={getValueFromCurrentLang(
                        "pages.General.enterYourEmail"
                      )}
                      autoComplete="off"
                      name="handle"
                      value={credentials.handle}
                      onChange={(e) =>
                        _onChangeFormFields("handle", e.target.value)
                      }
                    />
                  </InputGroup>
                  {errors["handle"] ? (
                    <div className="form-error">{errors["handle"]}</div>
                  ) : null}
                </FormGroup>

                <div className="d-flex flex-column align-items-center justify-content-center">
                  <Button color="primary" type="submit" className="btn-submit">
                    <LanguageParsedText keyString="pages.Login.forgotPassword" />
                  </Button>
                  <Button
                    className="d-block back-login my-2 fs-15"
                    onClick={() => history.push("/login")}
                  >
                    <i className="fa fa-arrow-left mr-2" />
                    <LanguageParsedText keyString="pages.Login.backToLogin" />
                  </Button>
                </div>
              </Form>
            </div>
            <AuthFooter />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
