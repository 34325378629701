import React, { useState } from "react";
import {
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentLanguage } from "../redux/actions/language-data";
import MultiLang from "../multi-lang";
import { availableLanguages } from "../config/helper-config";

const LanguageSwitcher = ({ className, isShowSelectInput, isShowDropdown }) => {
  const dispatch = useDispatch();

  const currentLang = useSelector((state) => state?.languageData?.currentLang);

  const _updateLanguage = (currentLang) => {
    MultiLang.setCurrentLang(currentLang);
    dispatch(updateCurrentLanguage(currentLang));

    // window.location.reload();
  };
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  const _toggleDropdown = () => setIsOpenDropdown((prevState) => !prevState);

  return (
    <div className={className}>
      {isShowSelectInput ? (
        <Input
          type="select"
          name="language"
          value={currentLang || ""}
          onChange={(e) => _updateLanguage(e.target.value)}
        >
          {availableLanguages.map((each) => (
            <option key={each.key} value={each.key}>
              {each.label}
            </option>
          ))}
        </Input>
      ) : null}
      {isShowDropdown ? (
        <>
          <Dropdown isOpen={isOpenDropdown} toggle={_toggleDropdown}>
            <DropdownToggle caret>
              {availableLanguages.find((each) => each.key === currentLang)
                ?.label || "English"}
            </DropdownToggle>
            <DropdownMenu right>
              {availableLanguages
                .filter((each) => each.key !== currentLang)
                .map((each) => (
                  <DropdownItem
                    key={each.key}
                    onClick={() => _updateLanguage(each.key)}
                  >
                    {each.label}
                  </DropdownItem>
                ))}
            </DropdownMenu>
          </Dropdown>
        </>
      ) : null}
    </div>
  );
};

export default LanguageSwitcher;
