import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import logo from "../../assets/img/logo.png";
import {
  capitalize,
  capitalizeEveryFirstLetter,
  showToast,
} from "../../helper-methods";
import { useReactToPrint } from "react-to-print";
import QRCode from "qrcode.react";
import { useSelector } from "react-redux";
import LanguageParsedText from "../../multi-lang/lang-parsed-text/LanguageParsedText";

const QRCodeModal = ({ isOpen, toggle, data }) => {
  let modalBodyRef = useRef(null);

  const userCredential = useSelector((state) => state["userCredential"]);

  const [qrValueObject, setQrValueObject] = useState({
    _resort: null,
    _group: null,
  });

  useEffect(() => {
    if (isOpen && data) {
      setQrValueObject({
        _resort: {
          _id: userCredential?.selectedResort?._id,
          name: userCredential?.selectedResort?.name,
        },
        _group: {
          _id: data?._id,
          name: data?.name,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, data?._id, userCredential?.selectedResort?._id]);

  /**
   * this function will dowload the user Qr-code
   */
  const _qrCodeDownload = () => {
    if (!data._id) {
      showToast("Something went wrong, Try again!", "error");
      return;
    }
    const canvas = document.getElementById(
      `groupChatQRCode__${data?._id}__${userCredential?.selectedResort?._id}`
    );
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${data?.name}_${userCredential?.selectedResort?.name}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const _qrCodePrint = useReactToPrint({
    content: () => modalBodyRef,
    copyStyles: true,
    bodyClass: "qrModal",
  });

  return (
    <Modal className="qrModal" isOpen={isOpen} toggle={toggle} centered>
      <div ref={(ref) => (modalBodyRef = ref)}>
        <ModalHeader toggle={toggle} className="text-center">
          <img
            src={
              userCredential?.selectedResort?.logo
                ? userCredential?.selectedResort?.logo
                : require("../../assets/img/hotel.svg").default
            }
            alt="logo"
            className="qr-logo"
            loading="lazy"
          />
        </ModalHeader>

        <ModalBody>
          <h4 className="text-center fs-22 fw-bold mt-2 mb-4 text-darkblue">
            {data?.name ? capitalize(data.name) : "N/A"}
          </h4>

          <div className="qr-code">
            {data?.id ? (
              <QRCode
                id={`groupChatQRCode__${data?._id}__${userCredential?.selectedResort?._id}`}
                value={JSON.stringify(qrValueObject)}
                level={"L"}
                size={250}
                includeMargin={true}
              />
            ) : (
              <div className="form-error">*Invalid data</div>
            )}
          </div>
          {userCredential?.selectedResort?.name ? (
            <h3 className="text-second fs-18 fw-bold text-center">
              {capitalizeEveryFirstLetter(userCredential.selectedResort.name)}
            </h3>
          ) : null}
        </ModalBody>
      </div>

      <ModalFooter className="justify-content-center mt-4">
        <Button
          className="btn-primary px-5 mr-3"
          onClick={() => _qrCodeDownload()}
        >
          <i className="fa fa-download" />{" "}
          <LanguageParsedText keyString="pages.General.download" />
        </Button>

        <Button className="btn-primary px-5" onClick={() => _qrCodePrint()}>
          <i className="fa fa-print" />{" "}
          <LanguageParsedText keyString="pages.General.print" />
        </Button>
      </ModalFooter>

      <div className="poweredBy">
        <p>Powered by</p>
        <img src={logo} alt="logo" loading="lazy" />
      </div>
    </Modal>
  );
};

export default QRCodeModal;
