import React, { Component } from "react";
import {
  Button,
  Col,
  Form,
  Label,
  FormGroup,
  InputGroup,
  InputGroupText,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import { connect } from "react-redux";
import AuthFooter from "../../components/Footers/AuthFooter";
import { countryCodes } from "../../config/country-codes";
import { RegexConfig } from "../../config/RegexConfig";
import {
  checkAvailability,
  getIpData,
  requestInvitation,
} from "../../http/http-calls";
import { deepClone, showToast } from "../../helper-methods";
import { StatesConfig } from "../../config/states";
import { showLoader, hideLoader } from "../../redux/actions/loader-data";
import { APP_NAME } from "../../config";
import SweetAlert from "react-bootstrap-sweetalert";
import { APP_LOGO, REGISTER_PAGE_BANNER } from "../../config/index";
import DeviceMetaData from "../../device-metadata";
import OneSignalHelper from "../../onesignal-helper";
import LanguageParsedText, {
  getValueFromCurrentLang,
} from "../../multi-lang/lang-parsed-text/LanguageParsedText";
import { Country, State, City } from "country-state-city";
class Register extends Component {
  state = {
    formFields: {
      name: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      email: {
        value: "",
        error: null,
        isDirty: false,
        isDublicateLoading: false,
        isValidationRequired: true,
      },
      phoneCode: {
        value: "+1",
        error: null,
        isDirty: false,
        isValidationRequired: false,
      },
      phone: {
        value: "",
        error: null,
        isDirty: false,
        isDublicateLoading: false,
        isValidationRequired: true,
      },
      resortName: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      resortAddress: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      SelectedCountry: {
        value: null,
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      SelectedState: {
        value: null,
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      SelectedCity: {
        value: null,
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      zipcode: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      numberOfRooms: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      comments: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: false,
      },
    },
    sumbitLoading: false,
    alert: null,


  };

  _getDeviceDetails = () => {

    return new Promise(async (resolve, reject) => {
      try {
        const deviceDetails = {};
        deviceDetails["name"] =
          DeviceMetaData.getBrowser() + " on " + DeviceMetaData.getOs();

        deviceDetails["ipAddress"] = (await getIpData())["ip"];
        // Get device id
        // First try to get from one signal
        let onesignalDeviceId = null;
        onesignalDeviceId = await OneSignalHelper.getDeviceId();
        if (onesignalDeviceId) {
          deviceDetails["allowNotification"] = true;
          deviceDetails["deviceId"] = onesignalDeviceId;
        } else {
          deviceDetails["allowNotification"] = false;
          deviceDetails["deviceId"] = DeviceMetaData.getDeviceId();
        }
        resolve(deviceDetails);
      } catch (error) {
        showToast(
          error?.reason?.length
            ? error.reason
            : getValueFromCurrentLang("pages.General.serverError"),
          "error"
        );
        reject(error);
      }
    });
  };

  /**
   * Check for duplicate username, email, phone
   */
  _checkDuplicateFields = (key, value) => {
    return new Promise(async (resolve) => {
      const { formFields } = deepClone(this.state);

      formFields[key].isDublicateLoading = true;

      this.setState({ formFields }, () => {
        const payload = {
          handle: value,
          handleType: key,
        };

        checkAvailability(payload)
          .then((res) => {
            resolve([res, null]);
          })
          .catch((error) => {
            resolve([null, error]);
          });
      });
    });
  };

  /**
   * validation or Check for duplicate username, email, phone , validate one at a time
   */
  _formFieldsApiValidation = (key) => {
    return new Promise(async (resolve) => {
      const { formFields: oldFormFields } = deepClone(this.state);

      let isFieldValid = true;

      if (
        oldFormFields[key].isDirty &&
        oldFormFields[key].isValidationRequired

      ) {

        if (oldFormFields[key].value?.trim().length) {
          if (
            RegexConfig[key].test(
              String(oldFormFields[key].value).toLowerCase()
            )
          ) {
            const checkingValue =
              key !== "phone"
                ? oldFormFields[key].value.trim()
                : `(${oldFormFields.phoneCode.value})${oldFormFields[
                  key
                ].value.trim()}`;

            // always resolve
            const [res, resError] = await this._checkDuplicateFields(
              key,
              checkingValue
            );

            oldFormFields[key].isDublicateLoading = false;

            if (res?.error || resError?.error) {
              oldFormFields[key].error = `${oldFormFields[
                key
              ].value.trim()} already in use`;
              isFieldValid = false;
            } else {
              oldFormFields[key].error = null;
              oldFormFields[key].isDirty = false;
            }
          } else {
            oldFormFields[key].error = `*${getValueFromCurrentLang(
              "pages.General.invalid"
            )} ${getValueFromCurrentLang(`pages.General.${key}`)}`;
            isFieldValid = false;
          }
        } else {
          oldFormFields[key].error = getValueFromCurrentLang(
            "pages.General.required"
          );
          isFieldValid = false;
        }
      }
      const { formFields: newFormFields } = deepClone(this.state);

      newFormFields[key].error = oldFormFields[key].error;
      newFormFields[key].isDirty = oldFormFields[key].isDirty;
      newFormFields[key].isDublicateLoading =
        oldFormFields[key].isDublicateLoading;

      this.setState({ formFields: newFormFields }, () => resolve(isFieldValid));
    });
  };

  _validateFormFields = () => {
    return new Promise((resolve) => {
      const { formFields } = deepClone(this.state);

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (formFields[key].isDirty && formFields[key].isValidationRequired) {
          switch (key) {
            case "zipcode":
            case "SelectedCity":
            case "SelectedState":
            case "SelectedCountry":
            case "resortAddress":
            case "resortName":
            case "numberOfRooms":
            case "name": {
              if (formFields[key].value?.trim().length) {
                formFields[key].error = null;
                formFields[key].isDirty = false;
              } else {
                formFields[key].error = getValueFromCurrentLang(
                  "pages.General.required"
                );
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      this.setState({ formFields }, () => resolve(isFormValid));
    });
  };

  _onChangeFormFields = (key, value) => {
    if (
      (key === "numberOfRooms" || key === "phone") &&
      (isNaN(value) || value.includes("."))
    )
      return;

    const { formFields } = deepClone(this.state);
    formFields[key].value = value;
    if (key === "country") {
      formFields.state.value = "";
      formFields.state.error = null;
      formFields.state.isDirty = false;
    }
    this.setState({ formFields });
  };

  _onBlurFormFields = (key) => {
    const { formFields } = deepClone(this.state);
    formFields[key].isDirty = true;
    if (key === "email" || key === "phone") {
      this.setState({ formFields }, () => this._formFieldsApiValidation(key));
    } else {
      this.setState({ formFields }, () => this._validateFormFields());
    }
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve) => {
      const { formFields } = deepClone(this.state);
      Object.keys(formFields).forEach((key) => {
        formFields[key].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };

  _hideAlert = () => {
    this.setState({ alert: null });
    this.props.history.push("/login");
  };

  _successSubmission = () => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={getValueFromCurrentLang(
            "pages.General.afterResortRegisterReviewNote",
            { appName: APP_NAME }
          )}
          onConfirm={() => this._hideAlert()}
          onCancel={() => this._hideAlert()}
        />
      ),
    });
  };

  _onSubmitRequestForm = async (event) => {
    try {
      if (event) event.preventDefault();

      this.setState({ sumbitLoading: true });

      await this._markAllFieldDirty();

      const [isFormValid, isEmailFieldValid, isPhoneFieldValid] =
        await Promise.all([
          this._validateFormFields(),
          this._formFieldsApiValidation("email"),
          this._formFieldsApiValidation("phone"),
        ]);

      this.setState({ sumbitLoading: false });

      if (isFormValid && isEmailFieldValid && isPhoneFieldValid) {
        this.props.showLoader(
          getValueFromCurrentLang("pages.Login.registerYourResort")
        );

        const { formFields } = deepClone(this.state);

        let splitName = formFields.name.value.trim().split(" ");

        if (splitName?.length) {
          splitName = {
            first:
              splitName.length > 1
                ? splitName.slice(0, -1).join(" ")
                : splitName[0],
            last: splitName.length > 1 ? splitName[splitName.length - 1] : "",
          };
        }

        const payload = {
          contact: {
            name: {
              first: splitName.first?.trim() ? splitName.first.trim() : "",
              last: splitName.last?.trim() ? splitName.last.trim() : "",
            },
            email: formFields.email.value.trim(),
            phone: `(${formFields.phoneCode.value
              })${formFields.phone.value.trim()}`

          },
          address: {
            street: formFields.resortAddress.value.trim(),
            city: formFields.SelectedCity.value.trim(),
            state: formFields.SelectedState.value.trim(),
            zip: formFields.zipcode.value.trim(),
            country: formFields.SelectedCountry.value.trim(),
          },
          organizationName: formFields.resortName.value.trim(),
          numberOfRooms: formFields.numberOfRooms.value.trim(),
          comments: formFields.comments.value.trim(),
          invitationType: "resort",
          userCategory: "resortadmin",
        };




        requestInvitation(payload)
          .then((res) => {
            this.props.hideLoader();
            this._successSubmission();
          })
          .catch((error) => {
            console.log("error>>", error);
            this.props.hideLoader();
            showToast(
              error?.reason?.length
                ? error.reason
                : getValueFromCurrentLang("pages.General.serverError"),
              "error"
            );
          });
      }
    } catch (error) {
      console.log("error>>", error);
      this.setState({ sumbitLoading: false });
      showToast(
        error?.reason?.length
          ? error.reason
          : getValueFromCurrentLang("pages.General.somethingWentWrong"),
        "error"
      );
    }
  };


  componentDidMount() {
    const { SelectedCountry } = this.state.formFields;
  }
  componentDidUpdate() {
    const { SelectedCountry, SelectedCity } = this.state.formFields;
  }

  handleCountryChange = (e) => {
    const { value } = e.target;

    this.setState({
      formFields: {
        ...this.state.formFields,
        SelectedCountry: {
          value: value,
          error: null,
          isDirty: false,
          isValidationRequired: true,
        },
      },
    });

  };

  handleStateChange = (e) => {
    const { value } = e.target;


    this.setState({
      formFields: {
        ...this.state.formFields,
        SelectedState: {
          value: value,
          error: null,
          isDirty: false,
          isValidationRequired: true,
        },
      },
    });
  };


  handleChange = (event) => {
    const { name, value } = event.target;
    const alphaRegex = /^[A-Za-z]+$/; // Regular expression to match alphabetical values


    this.setState((prevState) => ({
      formFields: {
        ...prevState.formFields,
        [name]: {
          ...prevState.formFields[name],
          value,
          isDirty: true,
          error: null
        }
      }
    }));

    if (name === 'name') {
      if (!alphaRegex.test(value) && value !== '') {
        this.setState((prevState) => ({
          formFields: {
            ...prevState.formFields,
            [name]: {
              ...prevState.formFields[name],
              error: 'Only alphabetical values are allowed'
            }
          }
        }));
      }
    }
  };

  render() {
    const { formFields, sumbitLoading, alert } = this.state;
    const { SelectedCountry, SelectedState, SelectedCity } = this.state.formFields;
    const countries = Country.getAllCountries();
    const states = State.getStatesOfCountry(SelectedCountry.value);
    const cities = City.getCitiesOfState(SelectedCountry.value, SelectedState.value);


    return (
      <>
        {alert}

        <div className="vayco-public login_singup registerPage">
          <div className="public-header">
            <img
              src={APP_LOGO}
              onClick={() => this.props.history.push("/login")}
              alt="Logo"
              className="company-logo"
              width="140"
              loading="lazy"
            />

            <Button
              color="link"
              onClick={() => this.props.history.push("/login")}
              className="loginLink"
            >
              <span>Sign In</span>
            </Button>
          </div>
          <div className="app animated fadeIn ">
            <div className="login_wrapepr d-flex align-items-center">
              <div className="login_left">
                <img
                  src={REGISTER_PAGE_BANNER}
                  alt="banner"
                  className="img-fluid"
                  loading="lazy"
                />
              </div>
              <div className="login_right with_lognform">
                <div className="login_Container">
                  <div className="form-title">
                    <h2 className="mt-0">
                      <LanguageParsedText keyString="pages.Login.registerYourResort" />
                    </h2>
                  </div>

                  <Form onSubmit={(e) => this._onSubmitRequestForm(e)}>
                    <p className="public-title">
                      <LanguageParsedText keyString="pages.Login.contactPersonDetails" />
                    </p>

                    <Row className="no-margin">
                      <Col md={6} className="mobilePadding-0">
                        <FormGroup>
                          <Label>
                            <LanguageParsedText keyString="pages.General.name" />
                          </Label>
                          <InputGroup>
                            <InputGroupText>
                              <img
                                src={
                                  require("../../assets/img/user.svg").default
                                }
                                alt="user"
                                className="img-fluid"
                                loading="lazy"
                              />
                            </InputGroupText>
                            <Input
                              type="text"
                              placeholder={getValueFromCurrentLang(
                                "pages.General.enterYourName"
                              )}
                              autoComplete="off"
                              name="name"
                              value={formFields.name.value}
                              onChange={this.handleChange}

                              onBlur={() => this._onBlurFormFields("name")}
                            />
                          </InputGroup>
                          {formFields.name.error ? (
                            <div className="form-error">
                              {formFields.name.error}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>

                      <Col md={6} className="mobilePadding-0">
                        <FormGroup>
                          <Label>
                            <LanguageParsedText keyString="pages.General.email" />
                          </Label>
                          <InputGroup>
                            <InputGroupText>
                              <img
                                src={require("../../assets/img/at.svg").default}
                                alt="user"
                                className="img-fluid"
                                loading="lazy"
                              />
                            </InputGroupText>
                            <Input
                              type="text"
                              placeholder={getValueFromCurrentLang(
                                "pages.General.enterYourEmail"
                              )}
                              autoComplete="email"
                              name="email"
                              value={formFields.email.value}
                              onChange={(e) =>
                                this._onChangeFormFields(
                                  "email",
                                  e.target.value
                                )
                              }
                              onBlur={() => this._onBlurFormFields("email")}
                            />
                            {formFields.email.isDublicateLoading ? (
                              <div className="spinnerLogin">
                                <Spinner
                                  style={{ width: "1rem", height: "1rem" }}
                                />
                              </div>
                            ) : null}
                          </InputGroup>
                          {formFields.email.error ? (
                            <div className="form-error">
                              {formFields.email.error}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>

                    <FormGroup>
                      <Label>
                        <LanguageParsedText keyString="pages.General.phoneNumber" />
                      </Label>
                      <InputGroup className="countryCode">
                        <InputGroupText>
                          <img
                            src={require("../../assets/img/phone.svg").default}
                            alt="user"
                            className="img-fluid"
                            loading="lazy"
                          />
                          <Input
                            type="select"
                            className="pl-0"
                            value={formFields.phoneCode.value}
                            onChange={(e) =>
                              this._onChangeFormFields(
                                "phoneCode",
                                e.target.value
                              )
                            }
                          >
                            {countryCodes.map((each) => (
                              <option key={each.code} value={each.dial_code}>
                                {each.code} ({each.dial_code})
                              </option>
                            ))}
                          </Input>
                        </InputGroupText>
                        <Input
                          type="text"
                          placeholder={getValueFromCurrentLang(
                            "pages.General.enterYourPhoneNumber"
                          )}
                          autoComplete="off"
                          value={formFields.phone.value}
                          onChange={(e) =>
                            this._onChangeFormFields("phone", e.target.value)
                          }
                          onBlur={() => this._onBlurFormFields("phone")}
                        />
                        {formFields.phone.isDublicateLoading ? (
                          <div className="spinnerLogin">
                            <Spinner
                              style={{ width: "1rem", height: "1rem" }}
                            />
                          </div>
                        ) : null}
                      </InputGroup>
                      {formFields.phone.error ? (
                        <div className="form-error">
                          {formFields.phone.error}
                        </div>
                      ) : null}
                    </FormGroup>

                    <p className="public-title mt-4">
                      <LanguageParsedText keyString="pages.Resorts.resortDetails" />
                    </p>

                    <Row className="no-margin">
                      <Col md={6} className="mobilePadding-0">
                        <FormGroup>
                          <Label>
                            <LanguageParsedText keyString="pages.Resorts.resortName" />
                          </Label>
                          <InputGroup>
                            <InputGroupText>
                              <img
                                src={
                                  require("../../assets/img/hotel.svg").default
                                }
                                alt="user"
                                className="img-fluid w-20"
                                loading="lazy"
                              />
                            </InputGroupText>
                            <Input
                              type="text"
                              placeholder={getValueFromCurrentLang(
                                "pages.Resorts.enterYourResortName"
                              )}
                              autoComplete="off"
                              value={formFields.resortName.value}
                              onChange={(e) =>
                                this._onChangeFormFields(
                                  "resortName",
                                  e.target.value
                                )
                              }
                              onBlur={() =>
                                this._onBlurFormFields("resortName")
                              }
                            />
                          </InputGroup>
                          {formFields.resortName.error ? (
                            <div className="form-error">
                              {formFields.resortName.error}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>

                      <Col md={6} className="mobilePadding-0">
                        <FormGroup>
                          <Label>
                            <LanguageParsedText keyString="pages.Resorts.numberOfRooms" />
                          </Label>
                          <InputGroup>
                            <InputGroupText>
                              <img
                                src={
                                  require("../../assets/img/hotel.svg").default
                                }
                                alt="user"
                                className="img-fluid w-20"
                                loading="lazy"
                              />
                            </InputGroupText>
                            <Input
                              type="text"
                              placeholder={getValueFromCurrentLang(
                                "pages.Resorts.enterTheNumberOfRooms"
                              )}
                              autoComplete="off"
                              value={formFields.numberOfRooms.value}
                              onChange={(e) =>
                                this._onChangeFormFields(
                                  "numberOfRooms",
                                  e.target.value
                                )
                              }
                              onBlur={() =>
                                this._onBlurFormFields("numberOfRooms")
                              }
                            />
                          </InputGroup>
                          {formFields.numberOfRooms.error ? (
                            <div className="form-error">
                              {formFields.numberOfRooms.error}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>

                    <FormGroup>
                      <Label>
                        <LanguageParsedText keyString="pages.Resorts.resortAddress" />
                      </Label>
                      <InputGroup>
                        <InputGroupText>
                          <img
                            src={
                              require("../../assets/img/location.svg").default
                            }
                            alt="user"
                            className="img-fluid"
                            loading="lazy"
                          />
                        </InputGroupText>
                        <Input
                          type="text"
                          placeholder={getValueFromCurrentLang(
                            "pages.Resorts.enterYourResortAddress"
                          )}
                          autoComplete="off"
                          value={formFields.resortAddress.value}
                          onChange={(e) =>
                            this._onChangeFormFields(
                              "resortAddress",
                              e.target.value
                            )
                          }
                          onBlur={() => this._onBlurFormFields("resortAddress")}
                        />
                      </InputGroup>
                      {formFields.resortAddress.error ? (
                        <div className="form-error">
                          {formFields.resortAddress.error}
                        </div>
                      ) : null}
                    </FormGroup>

                    <Row className="no-margin">
                      <Col md={6} className="mobilePadding-0">
                        <FormGroup>
                          <Label>
                            <LanguageParsedText keyString="pages.Resorts.country" />
                          </Label>
                          <InputGroup>
                            <InputGroupText>
                              <img
                                src={
                                  require("../../assets/img/city.svg").default
                                }
                                alt="State"
                                className="img-fluid"
                                loading="lazy"
                              />
                            </InputGroupText>

                            <Input
                              type="select"
                              id="countrySelect"
                              value={SelectedCountry.value}
                              onChange={this.handleCountryChange}
                              onBlur={() => this._onBlurFormFields("SelectedCountry")}
                            >
                              <option value="">Select a country</option>
                              {countries.map((country) => (
                                <option key={country.isoCode} value={country.isoCode}>
                                  {country.name}
                                </option>
                              ))}
                            </Input>
                          </InputGroup>
                          {formFields.SelectedCountry.error ? (
                            <div className="form-error">
                              {formFields.SelectedCountry.error}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md={6} className="mobilePadding-0">
                        <FormGroup>
                          <Label>
                            <LanguageParsedText keyString="pages.Resorts.state" />
                          </Label>
                          <InputGroup>
                            <InputGroupText>
                              <img
                                src={
                                  require("../../assets/img/city.svg").default
                                }
                                alt="city"
                                className="img-fluid"
                                loading="lazy"
                              />
                            </InputGroupText>



                            <Input
                              type="select"
                              id="stateSelect"
                              value={SelectedState.value}
                              onChange={this.handleStateChange}
                              disabled={!SelectedCountry.value}
                              onBlur={() => this._onBlurFormFields("SelectedState")}
                            >
                              <option value="">Select a state</option>
                              {states &&
                                states.map((state) => (
                                  <option key={state.isoCode} value={state.isoCode}>
                                    {state.name}
                                  </option>
                                ))}
                            </Input>




                          </InputGroup>
                          {formFields.SelectedState.error ? (
                            <div className="form-error">
                              {formFields.SelectedState.error}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md={6} className="mobilePadding-0">
                        <FormGroup>
                          <Label>
                            <LanguageParsedText keyString="pages.Resorts.city" />
                          </Label>
                          <InputGroup>
                            <InputGroupText>
                              <img
                                src={
                                  require("../../assets/img/zip.svg").default
                                }
                                alt="city"
                                className="img-fluid"
                                loading="lazy"
                              />
                            </InputGroupText>


                            <Input
                              type="select"
                              id="citySelect"
                              value={SelectedCity.value}
                              onChange={(e) => this.setState({
                                formFields: {
                                  ...this.state.formFields,
                                  SelectedCity: {
                                    value: e.target.value,
                                    error: null,
                                    isDirty: false,
                                    isValidationRequired: true,
                                  },
                                },
                              })}


                              disabled={!SelectedState.value}
                              onBlur={() => this._onBlurFormFields("SelectedCity")}
                            >
                              <option value="">Select a city</option>

                              {
                                cities &&
                                cities.map((city) => (

                                  <option key={city.name} value={city.name}>
                                    {city.name}
                                  </option>
                                ))}
                            </Input>
                          </InputGroup>
                          {formFields.SelectedCity.error ? (
                            <div className="form-error">
                              {formFields.SelectedCity.error}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md={6} className="mobilePadding-0">
                        <FormGroup>
                          <Label>
                            <LanguageParsedText keyString="pages.Resorts.zipcode" />
                          </Label>
                          <InputGroup>
                            <InputGroupText>
                              <img
                                src={
                                  require("../../assets/img/city.svg").default
                                }
                                alt="State"
                                className="img-fluid"
                                loading="lazy"
                              />
                            </InputGroupText>
                            <Input
                              type="text"
                              placeholder={getValueFromCurrentLang(
                                "pages.Resorts.enterTheZipcode"
                              )}
                              value={formFields.zipcode.value}
                              onChange={(e) =>
                                this._onChangeFormFields(
                                  "zipcode",
                                  e.target.value
                                )
                              }
                              onBlur={() => this._onBlurFormFields("zipcode")}
                            />
                          </InputGroup>
                          {formFields.zipcode.error ? (
                            <div className="form-error">
                              {formFields.zipcode.error}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>

                    <FormGroup className="commentArea">
                      <Label>
                        <LanguageParsedText keyString="pages.Resorts.commentsIfAny" />
                      </Label>
                      <InputGroup>
                        <InputGroupText>
                          <img
                            src={
                              require("../../assets/img/comment.svg").default
                            }
                            alt="user"
                            className="img-fluid"
                            loading="lazy"
                          />
                        </InputGroupText>
                        <Input
                          type="textarea"
                          placeholder={getValueFromCurrentLang(
                            "pages.Resorts.enterTheComments"
                          )}
                          name="comments"
                          value={formFields.comments.value}
                          onChange={(e) =>
                            this._onChangeFormFields("comments", e.target.value)
                          }
                        />
                      </InputGroup>
                    </FormGroup>

                    <div className="float-right">
                      <Button
                        color="primary"
                        type="submit"
                        className="btn-submit"
                        disabled={sumbitLoading}
                      >
                        {sumbitLoading ? (
                          <i className="fa fa-spinner fa-spin mr-1" />
                        ) : null}{" "}
                        <LanguageParsedText keyString="pages.Login.registerResort" />
                      </Button>
                    </div>
                  </Form>
                </div>
                <AuthFooter />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(null, mapDispatchToProps)(Register);