import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import LanguageParsedText, {
  getValueFromCurrentLang,
} from "../../multi-lang/lang-parsed-text/LanguageParsedText";
import Chart from "react-apexcharts";
import { getEButlerGraphData } from "../../http/http-calls";
import { errorHandler } from "../../helper-methods/index1";
import LoadingSkeleton from "react-loading-skeleton";

const EButlerGraph = ({ payload }) => {
  // #3.1
  const [eConciergeServiceBarGraph, setEConciergeServiceBarGraph] = useState({
    barOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        stacked: true,
        type: "bar",
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        labels: {
          formatter: (value) => value.toFixed(0),
        },
      },
      xaxis: {
        categories: [],
      },
      colors: ["#008ffb", "#FF1654", "#feb019", "#F1512E", "#255aee"],
      tooltip: {
        shared: false,
        x: {
          show: false,
        },
      },
    },
    barSeries: [
      {
        name: getValueFromCurrentLang("pages.KPIs.frontDesk"),
        data: [],
      },
      {
        name: getValueFromCurrentLang("pages.KPIs.houseKeeping"),
        data: [],
      },
      {
        name: getValueFromCurrentLang("pages.KPIs.conciergeDesk"),
        data: [],
      },
      {
        name: getValueFromCurrentLang("pages.KPIs.inRoomDining"),
        data: [],
      },
    ],
  });

  // #3.2
  const [eConciergeResponseTimeGraph, seteConciergeResponseTimeGraph] =
    useState({
      lineOptions: {
        chart: {
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        stroke: {
          curve: "smooth",
        },
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return `${value.toFixed(0)} min`;
            },
            style: {
              colors: "#247BA0",
            },
          },
        },
        xaxis: {
          categories: [],
        },
        colors: ["#247BA0"],
        tooltip: {
          shared: false,
          x: {
            show: false,
          },
        },
      },
      lineSeries: [
        {
          name: getValueFromCurrentLang("pages.KPIs.averageResponseTime"),
          data: [],
        },
      ],
    });

  const [loading, setLoading] = useState(false);

  const _setGuestUsingEConciergeGraph = (guestUsingEConcierge = []) => {
    const newEConciergeServiceBarGraph = { ...eConciergeServiceBarGraph };

    newEConciergeServiceBarGraph.barOptions.xaxis.categories = [];
    newEConciergeServiceBarGraph.barSeries[0].data = []; // # frontDesk
    newEConciergeServiceBarGraph.barSeries[1].data = []; // # houseKeeping
    newEConciergeServiceBarGraph.barSeries[2].data = []; // # conciergeDesk
    newEConciergeServiceBarGraph.barSeries[3].data = []; // # inRoomDining

    guestUsingEConcierge.forEach((each) => {
      newEConciergeServiceBarGraph.barOptions.xaxis.categories.push(
        `Week ${each._id.split("-")[1]}`
      );
      newEConciergeServiceBarGraph.barSeries[0].data.push(
        each.details.find((each) => each.serviceType === "frontDesk")?.count ||
          0
      );
      newEConciergeServiceBarGraph.barSeries[1].data.push(
        each.details.find((each) => each.serviceType === "houseKeeping")
          ?.count || 0
      );
      newEConciergeServiceBarGraph.barSeries[2].data.push(
        each.details.find(
          (each) =>
            each.serviceType === "conciergeDesk" ||
            each.serviceType === "concierge"
        )?.count || 0
      );
      newEConciergeServiceBarGraph.barSeries[3].data.push(
        each.details.find((each) => each.serviceType === "inRoomDining")
          ?.count || 0
      );
    });

    setEConciergeServiceBarGraph(newEConciergeServiceBarGraph);
  };

  const _setEConciergeResponseTimeGraph = (eConciergeResponseTime = []) => {
    const newEConciergeResponseTimeGraph = { ...eConciergeResponseTimeGraph };

    newEConciergeResponseTimeGraph.lineOptions.xaxis.categories = [];
    newEConciergeResponseTimeGraph.lineSeries[0].data = []; // # frontDesk

    eConciergeResponseTime.forEach((each) => {
      newEConciergeResponseTimeGraph.lineOptions.xaxis.categories.push(
        `Week ${each._id.split("-")[1]}`
      );

      const totalResponseTime = each.details.reduce((acc, obj) => {
        const _milliseconds =
          new Date(obj.responseTime) - new Date(obj.createdAt);

        var minutes = _milliseconds / (1000 * 60);

        return acc + minutes;
      }, 0);

      const averageResponseTime = Math.ceil(
        totalResponseTime / each.details.length
      );

      newEConciergeResponseTimeGraph.lineSeries[0].data.push(
        averageResponseTime || 0
      );
    });

    seteConciergeResponseTimeGraph(newEConciergeResponseTimeGraph);
  };

  const _getEButlerGraphData = (payload) => {
    setLoading(true);
    getEButlerGraphData(payload)
      .then((res) => {
        if (res.result) {
          _setGuestUsingEConciergeGraph(res.result.guestUsingEConcierge);
          _setEConciergeResponseTimeGraph(res.result.eConciergeResponseTime);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        errorHandler(error);
      });
  };

  useEffect(() => {
    if (payload?.startDate && payload?.endDate && payload?.groupByUnit)
      _getEButlerGraphData(payload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload?.startDate, payload?.endDate, payload?.groupByUnit]);

  return (
    <div className="graphWrapper">
      <div className="graphTitleWrap">
        <h1>
          <LanguageParsedText keyString="pages.KPIs.eButler" />
        </h1>
      </div>

      <Row>
        {/* eButler guest Using EConcierge graph*/}
        <Col lg="6">
          <p className="subHeading">
            #
            <LanguageParsedText keyString="pages.KPIs.guestUsingEConcierge" />
          </p>

          {loading ? (
            <LoadingSkeleton height={200} />
          ) : (
            <>
              {eConciergeServiceBarGraph.barSeries[0]?.data?.length ? (
                <Chart
                  options={eConciergeServiceBarGraph.barOptions}
                  series={eConciergeServiceBarGraph.barSeries}
                  type="bar"
                  width="100%"
                  height="275"
                  className="chartWrap"
                />
              ) : (
                <p>There is no data to display.</p>
              )}
            </>
          )}
        </Col>

        {/* eButler eConcierge Response Time graph */}
        <Col lg="6">
          <p className="subHeading" style={{ marginTop: 45 }}>
            <LanguageParsedText keyString="pages.KPIs.eConciergeResponseTime" />
          </p>

          {loading ? (
            <LoadingSkeleton height={200} />
          ) : (
            <>
              {eConciergeResponseTimeGraph.lineSeries[0]?.data?.length ? (
                <Chart
                  options={eConciergeResponseTimeGraph.lineOptions}
                  series={eConciergeResponseTimeGraph.lineSeries}
                  type="area"
                  width="100%"
                  height="275"
                  className="chartWrap"
                />
              ) : (
                <p>There is no data to display.</p>
              )}
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default EButlerGraph;
