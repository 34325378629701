import React, { Component } from "react";
import { connect } from "react-redux";
import { Elements } from "react-stripe-elements";
import { Button, FormGroup, CustomInput } from "reactstrap";
import { showToast } from "../helper-methods";
import {
  fetchAllCards,
  makeCardDefault,
  addCardAndMakeDefault,
  deleteCard,
} from "../http/http-calls";
import StripeElement from "./stripeElement";
import SweetAlert from "react-bootstrap-sweetalert";
import LanguageParsedText, {
  getValueFromCurrentLang,
} from "../multi-lang/lang-parsed-text/LanguageParsedText";

class Payment extends Component {
  state = {
    cards: [],
    preferredType: "exisiting", // 'new'
    isLoaderActive: false,
    defaultCard: null,
    selectedCard: null,
    error: null,
    alert: null,
  };

  _onTypeChange = (preferredType = "new", selectedCard = null) => {
    this.setState({
      preferredType,
      selectedCard,
    });
  };

  _getDefaultCard = () => {
    return new Promise(async (resolve, reject) => {
      this._showLoader();

      try {
        let res = await fetchAllCards();
        if (res.cards?.length) {
        //  console.log("res cards ",res)
          this.setState({ cards: res.cards });
          await this._setDefaultCard(res.cards);
          this._hideLoader();
          resolve();
        } else {
          // No cards available
          this.setState({ preferredType: "new", defaultCard: null }, () => {
            this._hideLoader();
            resolve();
          });
        }
      } catch (error) {
      //  console.log("jjjj")
        this._hideLoader();
        reject(error);
      }
    });
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (
      this.props.userCredential?.selectedResort?._id &&
      this.props.userCredential?.selectedResort?._id !==
        prevProps.userCredential?.selectedResort?._id
    ) {
      try {
        this.setState({ error: null });

        await this._getDefaultCard();
      } catch (error) {
        this.setState({ error });
        showToast(
          error?.reason?.length
            ? error.reason
            : getValueFromCurrentLang("pages.General.serverError"),
          "error"
        );
      }
    }
  };

  componentDidMount = async () => {
    try {
      await this._getDefaultCard();
    } catch (error) {
      
      this.setState({ error });
      showToast(
        error?.reason?.length
          ? error.reason
          : getValueFromCurrentLang("pages.General.serverError"),
        "error"
      );
    }
  };

  componentWillUnmount = () => {
    this._hideLoader();
  };

  _setDefaultCard = (cards) => {
    return new Promise((resolve, reject) => {
      let defaultCard = cards.find((card) => card.isDefault);

      this.setState({ defaultCard, selectedCard: defaultCard }, () => {
        resolve();
      });
    });
  };

  _onDismiss = () => {
    // Reset state
    this.setState({
      preferredType: "exisiting",
      isLoaderActive: false,
    });
    this.props.onDismiss();
  };

  _showLoader = () => {
    if (this.props.isManageLoading) {
      this.props.manageLoading(true);
    }
    this.setState({ isLoaderActive: true });
  };

  _hideLoader = () => {
    if (this.props.isManageLoading) {
      this.props.manageLoading(false);
    }
    this.setState({ isLoaderActive: false });
  };

  _processPayment = async () => {
    const { preferredType, selectedCard } = this.state;
    if (preferredType === "new") {
      // Try to add card
      this._addCard();
    } else {
      // preferredType === 'existing'
      if (selectedCard) {
        try {
          this.setState({ isLoaderActive: true });
          await this._makeCardAsDefault();
        } catch (error) {}
      }
      this.props.onPaymentConfirmation();
    }
  };

  _makeCardAsDefault = () => {
    const { selectedCard } = this.state;

    return new Promise((resolve, reject) => {
      makeCardDefault(selectedCard.id)
        .then((res) => {
          if (!res.error) {
            resolve();
          }
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  };

  _onStripeError = (e) => {
    this._hideLoader();
  };

  _onCardAdded = async (payload) => {
    try {
      // Add card
      await addCardAndMakeDefault(payload.token.id);
      // Card added
      // Process payment
      this.props.onPaymentConfirmation();
    } catch (error) {
      showToast(
        error?.reason?.length
          ? error.reason
          : getValueFromCurrentLang("pages.General.serverError"),
        "error"
      );
      this._hideLoader();
    }
  };

  _hideAlert = () => {
    this.setState({ alert: null });
  };

  _deleteCard = (cardId) => {
    // hide alert
    this._hideAlert();

    this._showLoader();

    deleteCard(cardId)
      .then(async (res) => {
        await this._getDefaultCard();
        this._hideLoader();
      })
      .catch((error) => {
        showToast(
          error?.reason?.length
            ? error.reason
            : getValueFromCurrentLang("pages.General.serverError"),
          "error"
        );
        this._hideLoader();
      });
  };

  _deleteCardAlert = (card) => {
    if (card?.id) {
      this.setState({
        alert: (
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title={getValueFromCurrentLang("pages.General.areYouSure")}
            onConfirm={() => this._deleteCard(card.id)}
            onCancel={() => this._hideAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText={getValueFromCurrentLang(
              "pages.General.yesRemoveIt"
            )}
            cancelBtnText={getValueFromCurrentLang("pages.General.cancel")}
            showCancel
          >
            You want to remove{" "}
            {`Card ending with ${card.last4} (${card.exp_month}/${card.exp_year
              ?.toString()
              .substr(2)})`}
            .{" "}
            <LanguageParsedText keyString="pages.General.youWillNotRecoverIt" />
          </SweetAlert>
        ),
      });
    } else {
      showToast("Somthing went wrong, Try again after sometime.", "error");
    }
  };

  render() {
    const {
      preferredType,
      isLoaderActive,
      defaultCard,
      cards,
      error,
      selectedCard,
      alert,
    } = this.state;
   // {console.log("fish",defaultCard)}
    const { cancelButton, submitButtonText } = this.props;

    return (
      <>
        {alert}

        <div className="selectPlan">
          {defaultCard
            ? cards.map((card) => (
                <div className="d-flex align-items-center mb-2" key={card.id}>
                  <FormGroup check className="radio">
                    <CustomInput
                      type="radio"
                      name="cardType"
                      value="exisiting"
                      checked={
                        preferredType === "exisiting" &&
                        selectedCard.id === card.id
                          ? true
                          : false
                      }
                      onChange={() => this._onTypeChange("exisiting", card)}
                      id={`cards_data_${card.id}`}
                      label={
                        card.isDefault
                          ? `Primary Card ending with ${card.last4} (${
                              card.exp_month
                            }/${card.exp_year?.toString().substr(2)})`
                          : `Card ending with ${card.last4} (${
                              card.exp_month
                            }/${card.exp_year?.toString().substr(2)})`
                      }
                    />
                  </FormGroup>

                  {!card.isDefault ? (
                    <Button
                      color="link"
                      className="remove p-0"
                      title="Remove Card"
                      disabled={error || isLoaderActive ? true : false}
                      onClick={() => this._deleteCardAlert(card)}
                    >
                      <i className="fa fa-trash" />
                    </Button>
                  ) : null}
                </div>
              ))
            : null}

          <>
            {defaultCard ? (
              <FormGroup check className="radio mb-3">
                <CustomInput
                  type="radio"
                  name="cardType"
                  value="new"
                  onChange={() => this._onTypeChange("new")}
                  checked={preferredType === "new" ? true : false}
                  id="add_new_card_radio"
                  label={"Add New Card"}
                />
              </FormGroup>
            ) : null}

            {preferredType === "new" ? (
              <div className="stripeWrapper">
                <Elements>
                  <StripeElement
                    {...this.props}
                    onCardAdded={(payload) => this._onCardAdded(payload)}
                    onStripeError={this._onStripeError}
                    showLoader={this._showLoader}
                    hidePostalCode={false}
                    hideLoader={this._hideLoader}
                    addCard={(ref) => (this._addCard = ref)}
                    className="stripeCard"
                  />
                </Elements>
              </div>
            ) : null}
          </>
        </div>

        <div style={{ marginTop: 20, marginBottom: 20 }}>
          {cancelButton && (
            <Button
              className="btn-cancel mw-130 fw-bold"
              onClick={() => this.props.onCancelButton()}
            >
              <LanguageParsedText keyString="pages.General.cancel" />
            </Button>
          )}

          <Button
            className="btn btn-primary btn-save mw-130 fw-bold"
            disabled={error || isLoaderActive ? true : false}
            onClick={() => this._processPayment()}
          >
            {isLoaderActive ? (
              <i className="fa fa-spinner fa-spin mr-1" />
            ) : null}
            {submitButtonText ? submitButtonText : "Save & Pay Now"}
          </Button>

          {error ? (
            <div className="form-error">
              {error?.reason?.length
                ? error.reason
                : getValueFromCurrentLang("pages.General.serverError")}
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userCredential: state.userCredential,
  };
};

export default connect(mapStateToProps, null)(Payment);
