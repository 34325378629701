import { config as AWSConfig, S3 } from "aws-sdk";
import { getAWSBucketName } from "../helper-methods/index1";

// import ThumbnailGenerator from 'video-thumbnail-generator';
class S3BucketUploader {
  /**
   * Static properties
   */
  static config = null;

  static setConfigs(config) {
    S3BucketUploader.config = config;
  }

  static getCreds = (url, authToken = null) => {
    return new Promise(async (resolve, reject) => {
      let headers = {};
      if (authToken) {
        headers["Authorization"] = "Bearer " + authToken;
      }
      // console.log(url, authToken, headers);
      fetch(url, {
        method: "GET",
        headers,
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          resolve(S3BucketUploader.prepareAWSConfigs(res));
        })
        .catch((error) => {
         // console.log("error>>", error);
          reject(error);
        });
    });
  };

 

  static prepareAWSConfigs = (config) => {
    console.log(config,"config")
    return {
      accessKeyId: config.AccessKeyId,
      secretAccessKey: config.SecretAccessKey,
      sessionToken: config.SessionToken,
      S3BucketName: config.S3BucketName,
      S3Region: config.S3Region,
    };
  };

  
  // AccessKeyId: "ASIAXRRFIKFDP744YQSD"
  // Expiration: "2023-02-08T09:22:12.000Z"
  // S3BucketName: "vaycoplus-dev"
  // S3Region: "us-east-1"
  // SecretAccessKey: "wW47MIstO3z0FTRZp+VkRRxKY5Ggg+OdEOFFylCd"
  // SessionToken: "FwoGZXIvYXdzEPr//////////wEaDCmzFzqAXmzJQ4KqFCJq5NjmLnRkW1SvrPnUnBa6T/tiCeczPQa/8XXO9T35ZXDRxLS2m1/n9j9nANzBM+CQNK+d7Bh/KnKap+YUKwcvNWiKEsYPx00Ppd0lcEI8sw7SSlbE2rRB63Yd90crcyvCW/UusTekOlUmWSi0t42fBjIoyZ6uEtRYr/pzn6pKmWkAR3jzczgXt0T0SNqmJ5ngCEw2uqqWd2Abhg=="
  // error: false
  /**
   * Non static properties
   */

  constructor(config) {
    S3BucketUploader.setConfigs(config);
  }

  uploadFile = (
    file,
    onComplete = null,
    onProgressCallBack = () => {},
    fileType = "image"
  ) => {
    return new Promise((resolve, reject) => {
      const { config } = S3BucketUploader;
      AWSConfig.update(config);
      // Configure your region
      AWSConfig.region = config.S3Region;
      var bucket = new S3({
        params: {
          Bucket: getAWSBucketName(fileType, file.isPublicContent),
        },
      });
      const Key = String(+new Date());
      var params = { Key, ContentType: file.type, Body: file };
      bucket
        .upload(params)
        .on("httpUploadProgress", onProgressCallBack)
        .send((err, success) => {
          if (err) {
            reject(err);
          } else {
            resolve(success);
          }
        });
    });
  };
}

export default S3BucketUploader;
