import React from "react";
import { useState } from "react";
import { Modal, ModalHeader, ModalBody, Spinner } from "reactstrap";
import { formatDate } from "../../helper-methods";
import Payment from "../payment";

const PaymentModal = ({
  isOpen,
  toggle,
  data,
  onBuySubscription,
  subscriptionPrice,
}) => {
  const [loading, setLoading] = useState(false);

  const _closeModal = () => {
    toggle();
  };

  return (
    <Modal
      className="plus_subscription"
      isOpen={isOpen}
      toggle={() => _closeModal()}
      centered
    >
      <ModalHeader toggle={() => _closeModal()} className="text-center">
        Subscription {loading ? <Spinner /> : null}
      </ModalHeader>

      <ModalBody className="p-0">
        <div className="innerForm py-2">
          <div className="plan_selected">
            Plan Selected:{" "}
            {subscriptionPrice ? (
              <span>{subscriptionPrice} USD per month</span>
            ) : (
              <div className="form-error">Something went wrong</div>
            )}
          </div>

          <div className="selectCard">
            <Payment
              isManageLoading={true}
              manageLoading={(value) => setLoading(value)}
              cancelButton={true}
              onCancelButton={() => _closeModal()}
              submitButtonText="Confirm"
              onPaymentConfirmation={() => onBuySubscription()}
            />
          </div>

          {data?.end ? (
            <div className="billingText">
              <p>
                Next Billing On: <span>{formatDate(data.end)}</span>
              </p>
            </div>
          ) : null}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PaymentModal;
