import React from "react";
import { Container } from "react-bootstrap";
import { APP_NAME } from "../../config";

function AdminFooter() {
  return (
    <>
      <footer className="footer">
        <Container fluid className="p-0">
          <div className="d-flex align-items-center justify-content-between">
            <span>&copy; 2022 {APP_NAME}</span>
          </div>
        </Container>
      </footer>
    </>
  );
}

export default AdminFooter;
