import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  InputGroup,
  InputGroupText,
  FormGroup,
  Input,
  Row,
  Label,
} from "reactstrap";
import AuthFooter from "../../components/Footers/AuthFooter";
import GoogleLoginComponent from "../../components/google-login";
import FacebookLoginComponent from "../../components/facebook-login";
import { useHistory } from "react-router-dom";
import { RegexConfig } from "../../config/RegexConfig";
import { APP_LOGO, LOGIN_PAGE_BANNER } from "../../config/index";
import { showLoader } from "../../redux/actions/loader-data";
import { useDispatch } from "react-redux";
import { login, socialLogin, getIpData } from "../../http/http-calls";
import { showToast } from "../../helper-methods";
import { hideLoader } from "../../redux/actions/loader-data";
import {
  addUserCredential,
  updateAssignedResorts,
} from "../../redux/actions/user-credential";
import OneSignalHelper from "../../onesignal-helper/index";
import DeviceMetaData from "../../device-metadata/index";
import LanguageParsedText, {
  getValueFromCurrentLang,
} from "../../multi-lang/lang-parsed-text/LanguageParsedText";
import LanguageSwitcher from "../../components/LanguageSwitcher";

const LoginPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [credentials, setCredentials] = useState({
    handle: "",
    password: "",
  });
  const [isDirty, setIsDirty] = useState({
    handle: false,
    password: false,
  });
  const [errors, setErrors] = useState({});

  const _initializeOneSignal = async () => {
    const hasPermission = await OneSignalHelper.getNotificationPermission();
    if (!hasPermission) {
      OneSignalHelper.showPrompt();
    }
  };

  // eslint-disable-next-line no-unused-vars
  const _getDeviceDetails = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const deviceDetails = {};
        deviceDetails["name"] =
          DeviceMetaData.getBrowser() + " on " + DeviceMetaData.getOs();
        deviceDetails["ipAddress"] = (await getIpData())["ip"];
        // Get device id
        // First try to get from one signal
        let onesignalDeviceId = null;
        onesignalDeviceId = await OneSignalHelper.getDeviceId();
        if (onesignalDeviceId) {
          deviceDetails["allowNotification"] = true;
          deviceDetails["deviceId"] = onesignalDeviceId;
        } else {
          deviceDetails["allowNotification"] = false;
          deviceDetails["deviceId"] = DeviceMetaData.getDeviceId();
        }
        resolve(deviceDetails);
      } catch (error) {
        // showToast(
        //   error?.reason?.length
        //     ? error.reason
        //     : getValueFromCurrentLang("pages.General.serverError"),
        //   "error"
        // );
        reject(error);
      }
    });
  };

  const _validateFormFields = (newCredentials, newIsDirty) => {
    return new Promise((resolve) => {
      const newErrors = { ...errors };
      let isFormValid = true;

      Object.keys(newCredentials).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "handle": {
              if (newCredentials[key]?.trim().length) {
                if (
                  RegexConfig.email.test(
                    String(newCredentials[key]).toLowerCase()
                  )
                ) {
                  delete newErrors[key];
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = `*${getValueFromCurrentLang(
                    "pages.General.invalid"
                  )} email`;
                  isFormValid = false;
                }
              } else {
                newErrors[key] = getValueFromCurrentLang(
                  "pages.General.required"
                );
                isFormValid = false;
              }
              break;
            }
            case "password": {
              if (newCredentials[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = getValueFromCurrentLang(
                  "pages.General.required"
                );
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      setErrors(newErrors);
      setIsDirty(newIsDirty);

      resolve(isFormValid);
    });
  };

  const _onChangeFormFields = (key, value) => {
    const newCredentials = { ...credentials };
    const newIsDirty = { ...isDirty };
    newCredentials[key] = value;
    newIsDirty[key] = true;
    setCredentials(newCredentials);
    setIsDirty(newIsDirty);
    _validateFormFields(newCredentials, newIsDirty);
  };

  const _completedAuthorization = (res) => {
    dispatch(hideLoader());
    dispatch(addUserCredential({ token: res.token, user: res.user }));
    dispatch(updateAssignedResorts(res._assignedResorts));
    history.replace("/");
  };

  const _onSubmitLoginForm = async (event) => {
    if (event) event.preventDefault();

    const newIsDirty = {
      handle: true,
      password: true,
    };

    const isFormValid = await _validateFormFields(credentials, newIsDirty);

    if (isFormValid) {
      dispatch(showLoader(getValueFromCurrentLang("pages.Login.login")));

      const payload = {
        ...credentials,
        userType: "ResortAdmin",
      };
//console.log("login",payload)
      try {
        const deviceDetails = await _getDeviceDetails();
        if (deviceDetails) {
          payload["deviceDetails"] = deviceDetails;
          payload["allowNotification"] = deviceDetails.allowNotification;
        }
      } catch (error) {
        console.log("deviceDetails error>>", error);
      }

      login(payload)
        .then((res) => {
          _completedAuthorization(res);
        })
        .catch((error) => {
          console.log("error>>", error);
          dispatch(hideLoader());
          showToast(
            error?.reason?.length
              ? error.reason
              : getValueFromCurrentLang("pages.General.serverError"),
            "error"
          );
        });
    }
  };

  const _socialLogin = async (platform, accessToken) => {
    dispatch(
      showLoader(
        `${getValueFromCurrentLang("pages.Login.login")} through ${platform}...`
      )
    );

    const payload = {
      accessToken,
      userType: "ResortAdmin",
    };

    try {
      const deviceDetails = await _getDeviceDetails();
      if (deviceDetails) {
        payload["deviceDetails"] = deviceDetails;
        payload["allowNotification"] = deviceDetails.allowNotification;
      }
    } catch (error) {
      console.log("deviceDetails error>>", error);
    }

    socialLogin(platform, payload)
      .then((res) => {
        _completedAuthorization(res);
      })
      .catch((error) => {
        console.log("error>>", error);
        dispatch(hideLoader());
        showToast(
          error?.reason?.length
            ? error.reason
            : getValueFromCurrentLang("pages.General.serverError"),
          "error"
        );
      });
  };

  useEffect(() => {
    _initializeOneSignal();
  }, []);

  return (
    <div className="vayco-public login_singup">
      <div className="public-header">
        <img
          src={APP_LOGO}
          onClick={() => history.push("/register")}
          alt="Logo"
          className="company-logo"
          width="130"
          loading="lazy"
        />
        <div className="d-flex">
          {/* language Switcher */}
          <LanguageSwitcher className="languageList" isShowDropdown={true} />

          <Button
            color="link"
            onClick={() => history.push("/register")}
            className="loginLink"
          >
            <span>Sign Up</span>
          </Button>
        </div>
      </div>

      <div className="app animated fadeIn ">
        <div className="login_wrapepr d-flex align-items-center">
          <div className="login_left">
            <img
              src={LOGIN_PAGE_BANNER}
              alt="banner"
              className="img-fluid"
              loading="lazy"
            />
          </div>
          <div className="login_right">
            <div className="login_Container">
              <div className="form-title text-center">
                <h2 className="mt-0">
                  <LanguageParsedText keyString="pages.Login.login" />
                </h2>
              </div>

              <Form onSubmit={(e) => _onSubmitLoginForm(e)}>
                <FormGroup>
                  <Label>
                    <LanguageParsedText keyString="pages.General.email" />
                  </Label>
                  <InputGroup>
                    <InputGroupText>
                      <i className="fas fa-user" />
                    </InputGroupText>
                    <Input
                      type="text"
                      placeholder={getValueFromCurrentLang(
                        "pages.General.enterYourEmail"
                      )}
                      autoComplete="off"
                      name="handle"
                      value={credentials.handle}
                      onChange={(e) =>
                        _onChangeFormFields("handle", e.target.value)
                      }
                    />
                  </InputGroup>
                  {errors["handle"] ? (
                    <div className="form-error">{errors["handle"]}</div>
                  ) : null}
                </FormGroup>

                <FormGroup className="mb-2">
                  <Label>
                    <LanguageParsedText keyString="pages.General.password" />
                  </Label>
                  <InputGroup>
                    <InputGroupText>
                      <i className="fas fa-unlock-alt" />
                    </InputGroupText>
                    <Input
                      type={showPassword ? "text" : "password"}
                      placeholder={getValueFromCurrentLang(
                        "pages.General.enterYourPassword"
                      )}
                      autoComplete="off"
                      name="password"
                      value={credentials.password}
                      onChange={(e) =>
                        _onChangeFormFields("password", e.target.value)
                      }
                    />
                    <InputGroupText>
                      <i
                        className={`far ${
                          showPassword ? "fa-eye" : "fa-eye-slash"
                        }`}
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      />
                    </InputGroupText>
                  </InputGroup>
                  {errors["password"] ? (
                    <div className="form-error">{errors["password"]}</div>
                  ) : null}
                </FormGroup>

                {/* <div className="text-right d-block">
                  <Button
                    color="link"
                    onClick={() => history.push("/forgot-password")}
                    className="p-0"
                  >
                    <span className="forgotPassword">
                      <LanguageParsedText keyString="pages.Login.forgotPassword" />
                    </span>
                  </Button>
                </div> */}

                <Row className="no-margin">
                  <Col className="text-center">
                    <Button
                      type="submit"
                      color="primary"
                      className="btn-submit login_btn"
                    >
                      <LanguageParsedText keyString="pages.Login.login" />
                    </Button>
                  </Col>
                </Row>
              </Form>

              <div className="or">
                <span>OR</span>
              </div>

              <div className="socialLogin">
                <GoogleLoginComponent
                  className="googleSignUpBtn"
                  response={(res) => _socialLogin("google", res.accessToken)}
                />
                <FacebookLoginComponent
                  className="fbSignUpBtn"
                  response={(res) => _socialLogin("facebook", res.accessToken)}
                />
              </div>
            </div>

            <AuthFooter />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
