import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import routes from "../../routes";
import { getAndUpdateUserData } from "../../redux/actions/user-credential";
import { getAndUpdateSettings } from "../../redux/actions/settings";
import { connectToSocket, disconnectToSocket } from "../../socket-io";
import { showToast } from "../../helper-methods";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import AdminFooter from "../../components/Footers/AdminFooter";
import HotelAmenities from "../protectedPages/HotelAmenities";
import AddAmenity from "../protectedPages/AddAmenity";
import TeamMembers from "../protectedPages/TeamMembers";
import GroupChat from "../protectedPages/GroupChat";
import GuestRequests from "../protectedPages/GuestRequests";
import Sidebar from "../../components/Sidebar/Sidebar";
import MediaQueueViewer from "../../components/media-queue-viewer";
import ProtectedRoute from "../../components/protected-routes";
import OffersPage from "../protectedPages/OffersPage";
import NotificationsPage from "../protectedPages/NotificationsPage";
import SettingsPage from "../protectedPages/SettingsPage";
import SubscriptionInvoice from "../protectedPages/SubscriptionInvoice";
import ProfilePage from "../protectedPages/ProfilePage";
import ResortDetails from "../protectedPages/ResortDetails";
import TermsConditions from "../protectedPages/TermsConditions";
import PrivacyPolicy from "../protectedPages/PrivacyPolicy";
import SubscriptionPage from "../protectedPages/SubscriptionPage";
import ProtectedPermissionRoute from "../../components/protected-permission-routes";
import KpiDashboard from "../protectedPages/KpiDashboard";
import AddResort from "../protectedPages/AddResort";

const ResortLayout = (props) => {
  const dispatch = useDispatch();

  const userCredential = useSelector((state) => state["userCredential"]);

  const _connectToSocket = () => {
    connectToSocket()
      .then((res) => {
      //  console.log("ResortLayout: connected>>", res.connected);
      })
      .catch((error) => {
        showToast(
          error.reason && error.reason.length
            ? error.reason
            : "Server error. Try again after sometime.",
          "error"
        );
      });
  };

  const _disconnectToSocket = () => {
    disconnectToSocket()
      .then((flag) => {
        console.log("ResortLayout: disconnect>>", flag);
      })
      .catch((error) => {
        showToast(
          error.reason && error.reason.length
            ? error.reason
            : "Server error. Try again after sometime.",
          "error"
        );
      });
  };

  useEffect(() => {
    _connectToSocket();

    dispatch(getAndUpdateUserData());
    dispatch(getAndUpdateSettings());

    return () => {
      _disconnectToSocket();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="vayco-admin">
      {/* //Protected Routes */}
      <div className="wrapper">
        <Sidebar
          routes={routes}
          history={props.history}
          userCredential={userCredential}
        />
        <div className="main-panel">
          <AdminNavbar {...props} />
          <Switch>
            {/* ======= check permission and card ======= */}
            <ProtectedPermissionRoute
              exact
              path="/amenities"
              component={HotelAmenities}
              // redirectRoute="/user-profile"
              permissionName="manageamenity"
              isCheckPermission={true}
            />
            <ProtectedPermissionRoute
              exact
              path="/add-amenity"
              component={AddAmenity}
              // redirectRoute="/user-profile"
              permissionName="manageamenity"
              isCheckPermission={true}
            />
            <ProtectedPermissionRoute
              exact
              path="/team-members"
              component={TeamMembers}
              // redirectRoute="/user-profile"
              permissionName="manageteam"
              isCheckPermission={true}
            />
            <ProtectedPermissionRoute
              exact
              path="/property-profile"
              component={ResortDetails}
              // redirectRoute="/user-profile"
              permissionName="manageresort"
              isCheckPermission={true}
            />
            <ProtectedPermissionRoute
              exact
              path="/dashboard"
              component={KpiDashboard}
              // redirectRoute="/user-profile"
              permissionName="manageresort"
              isCheckPermission={true}
            />
            <ProtectedPermissionRoute
              exact
              path="/add-resort"
              component={AddResort}
              // redirectRoute="/user-profile"
              permissionName="manageresort"
              isCheckPermission={true}
            />
            <ProtectedPermissionRoute
              exact
              path="/groups"
              component={GroupChat}
              // redirectRoute="/user-profile"
              permissionName="managefeed"
              isCheckPermission={true}
            />
            <ProtectedPermissionRoute
              exact
              path="/settings"
              component={SettingsPage}
              // redirectRoute="/user-profile"
              permissionName="managesettings"
              isCheckPermission={true}
            />
            <ProtectedPermissionRoute
              exact
              path="/offers"
              component={OffersPage}
              // redirectRoute="/user-profile"
              permissionName="manageoffer"
              isCheckPermission={true}
            />
            <ProtectedPermissionRoute
              exact
              path="/billing"
              component={SubscriptionInvoice}
              // redirectRoute="/user-profile"
              permissionName="managesubscription"
              isCheckPermission={true}
            />
            {/* ======= check permission and card end ======= */}

            {/* ======= check only card ======= */}
            <ProtectedPermissionRoute
              exact
              path="/requests"
              component={GuestRequests}
              // redirectRoute="/subscription"
              isCheckPermission={false}
            />
            <ProtectedPermissionRoute
              exact
              path="/notifications"
              component={NotificationsPage}
              // redirectRoute="/subscription"
              isCheckPermission={false}
            />
            <ProtectedPermissionRoute
              exact
              path="/user-profile"
              component={ProfilePage}
              // redirectRoute="/subscription"
              isCheckPermission={false}
            />
            {/* ======= check only card end ======= */}

            {/* normal protected routes */}
            <ProtectedRoute
              exact
              path="/subscription"
              component={SubscriptionPage}
              redirectRoute="/login"
            />

            <ProtectedRoute
              exact
              path="/terms"
              component={TermsConditions}
              redirectRoute="/login"
            />
            <ProtectedRoute
              exact
              path="/privacy"
              component={PrivacyPolicy}
              redirectRoute="/login"
            />

            <Route exact path="*" render={() => <Redirect to="/amenities" />} />
          </Switch>

          <MediaQueueViewer />

          <AdminFooter />
        </div>
      </div>
    </div>
  );
};

export default ResortLayout;
