import { store as REDUX_STORE } from "../redux/store";

export const hasPermission = (permissionName) => {
  let hasPermission = false;

  if (permissionName) {
    const state = REDUX_STORE.getState();

    if (
      state?.userCredential?.user?.isSuper ||
      (!state?.userCredential?.user?.isSuper &&
        state?.userCredential?.selectedResort?._id &&
        state?.userCredential?.user?.permissions?.length &&
        state?.userCredential?.user?.permissions?.includes(
          `${state.userCredential.selectedResort._id}-${permissionName}`
        ))
    ) {
      hasPermission = true;
    }
  }

  return hasPermission;
};
