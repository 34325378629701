import { store } from "../redux/store";
import { decodeToken, logout } from "../helper-methods/index";
import { hasPermission } from "../helper-methods/has-permission";

export const isUserAuthenticated = () => {
 // console.log("userAuthenticated--------------------------");
  const state = store.getState();
  let isUserAuth = false;

  if (state?.userCredential?.token) {
    const tokenData = decodeToken(state.userCredential.token);

    const expDate = new Date(tokenData["exp"] * 1000); // expire in sec. convert in msec.

    if (expDate <= new Date()) {
      console.log("token expire...");
      logout();
    } else {
      isUserAuth = true;
    }
  }

  return isUserAuth;
};

export const isAuthenticatedAndHasCard = () => {
 // console.log("isAuthenticatedAndHasCard-----------------------------");
  
  const state = store.getState();

  let isUserAuth = false;

  if (
    state?.userCredential?.token &&
    state.userCredential.selectedResort?.isCardAdded
  ) {
    const tokenData = decodeToken(state.userCredential.token);

    const expDate = new Date(tokenData["exp"] * 1000); // expire in sec. convert in msec.

    if (expDate <= new Date()) {
      console.log("token expire...");
      logout();
    } else {
      isUserAuth = true;
    }
  }

  return isUserAuth;
};

export const isAuthenticatedHasPermissionAndCard = (permissionName) => {
  //console.log("isAuthenticatedHasPermissionAndCard-----------------------------");
  const state = store.getState();

  let isUserAuth = false;

  if (
    state?.userCredential?.token &&
    state?.userCredential?.selectedResort?.isCardAdded &&
    hasPermission(permissionName)
  ) {
   // console.log(state?.userCredential,"state?.userCredential--------%%%%%%%%%%%%%%%%%%%%%")
    const tokenData = decodeToken(state.userCredential.token);

    const expDate = new Date(tokenData["exp"] * 1000); // expire in sec. convert in msec.

    if (expDate <= new Date()) {
      console.log("token expire...");
      logout();
    } else {
      isUserAuth = true;
    }
  }

  return isUserAuth;
};
