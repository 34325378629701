import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Media,
  Table,
  Spinner,
} from "reactstrap";
import { capitalize, showToast } from "../../helper-methods/index1";
import { getAllPreviousRequest } from "../../http/http-calls";
import ReactPaginate from "react-paginate";
import { DEFAULT_PROFILE_PICTURE } from "../../config";
import { requestStatusConfig } from "../../config/helper-config";
import LanguageParsedText, {
  getValueFromCurrentLang,
} from "../../multi-lang/lang-parsed-text/LanguageParsedText";

const PreviousRequestModal = ({ isOpen, toggle, data }) => {
  const [previousRequestsPayload, setPreviousRequestsPayload] = useState({
    skip: 0,
    pageNumber: 0,
    limit: 5,
    vacationerId: "",
  });
  const [previousRequests, setPreviousRequest] = useState([]);
  const [previousRequestsCount, setPreviousRequestsCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const _resetModalState = () => {
    setPreviousRequestsPayload({
      skip: 0,
      pageNumber: 0,
      limit: 5,
      vacationerId: "",
    });
    setPreviousRequest([]);
    setPreviousRequestsCount(0);
    setLoading(false);
  };

  const _closeModal = () => {
    _resetModalState();
    toggle();
  };

  const _getAllPreviousRequest = (payload) => {
    setLoading(true);
    getAllPreviousRequest(payload)
      .then((res) => {
        setPreviousRequest(res.bookings?.length ? res.bookings : []);
        setPreviousRequestsCount(res.count || 0);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error>>", error);
        _closeModal();
        showToast(
          error?.reason?.length
            ? error.reason
            : getValueFromCurrentLang("pages.General.serverError"),
          "error"
        );
      });
  };

  const _onPageChange = (pageNumber = 0) => {
    const newPreviousRequestsPayload = { ...previousRequestsPayload };
    newPreviousRequestsPayload["skip"] =
      pageNumber * newPreviousRequestsPayload["limit"];
    newPreviousRequestsPayload["pageNumber"] = pageNumber;
    setPreviousRequestsPayload(newPreviousRequestsPayload);
    _getAllPreviousRequest(newPreviousRequestsPayload);
  };

  useEffect(() => {
    if (isOpen && data?._vacationer?.id) {
      const newPreviousRequestsPayload = { ...previousRequestsPayload };
      newPreviousRequestsPayload["vacationerId"] = data._vacationer.id;
      setPreviousRequestsPayload(newPreviousRequestsPayload);
      _getAllPreviousRequest(newPreviousRequestsPayload);
    } else {
      _closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, data]);

  const _getStatusColor = (status) => {
    switch (status) {
      case "unassigned":
        return "accepted";
      case "pending":
        return "accepted";
      case "inprogress":
        return "accepted";
      case "completed":
        return "accepted";
      case "cancelled":
        return "accepted";
      default:
        return "";
    }
  };

  const _getStatusInFormat = (status) => {
    const findStatus = requestStatusConfig.find(
      (each) => each.value === status
    );
    if (findStatus) return findStatus.label;
    else return "N/A";
  };

  return (
    <Modal
      className="request_service"
      isOpen={isOpen}
      toggle={() => _closeModal()}
      centered
    >
      <ModalHeader toggle={() => _closeModal()} className="text-center">
        Previous Requests {loading ? <Spinner /> : null}
      </ModalHeader>
      <ModalBody className="p-0">
        {/* ======== All Members ======== */}
        <div className="all-members-chat">
          <div className="all-members-chat-container ">
            <div className="all-members-chat-body">
              <Table className="table-responsive-xl">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Service</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {previousRequests?.length ? (
                    previousRequests.map((request) => (
                      <tr key={request.id}>
                        <td>
                          <div className="chat-profile">
                            <Media className="align-items-center">
                              <Media left>
                                <Media
                                  object
                                  src={
                                    request?._vacationer?.avatar ||
                                    DEFAULT_PROFILE_PICTURE
                                  }
                                />
                              </Media>
                              <Media body className="pl-2">
                                <Media
                                  heading
                                  className="fs-18 m-0 font-weight-bold lh-1"
                                >
                                  {request?._vacationer?.name?.full
                                    ? capitalize(request._vacationer.name.full)
                                    : "N/A"}
                                </Media>
                                {request?._vacationer?.username ? (
                                  <span className="fs-13 text-secondary d-block">
                                    @{request._vacationer.username}
                                  </span>
                                ) : null}
                              </Media>
                            </Media>
                          </div>
                        </td>
                        <td>
                          <span className="service">
                            {request?._service?.serviceType
                              ? capitalize(request._service.serviceType)
                              : "N/A"}
                          </span>
                        </td>
                        <td>
                          {request?.status ? (
                            <Button
                              className={`btn py-2 px-3 fs-13 lh-1 ${_getStatusColor(
                                request.status
                              )}`}
                            >
                              {_getStatusInFormat(request.status)}
                            </Button>
                          ) : (
                            "N/A"
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="text-center">
                      <td colSpan="9">
                        {loading ? (
                          <Spinner />
                        ) : (
                          <LanguageParsedText keyString="pages.General.noData" />
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>

              {/* ====== Pagination ====== */}
              {previousRequests?.length && previousRequestsCount ? (
                <div className="row mt-4">
                  <div className="col-md-6">
                    <span className="showng_entries">
                      <LanguageParsedText
                        keyString="pages.General.showingEntriesOutOf"
                        showCount={previousRequests?.length}
                        totalCount={previousRequestsCount}
                      />
                    </span>
                  </div>
                  <div className="col-md-6">
                    <ReactPaginate
                      forcePage={previousRequestsPayload.pageNumber}
                      className="pagination justify-content-end mb-2"
                      breakLabel="..."
                      nextLabel={<i className="fa fa-chevron-right" />}
                      previousLabel={<i className="fa fa-chevron-left" />}
                      previousClassName="previous"
                      nextClassName="next"
                      onPageChange={({ selected }) => _onPageChange(selected)}
                      pageRangeDisplayed={3}
                      pageCount={Math.ceil(
                        previousRequestsCount / previousRequestsPayload.limit
                      )}
                      pageClassName="page-item"
                      renderOnZeroPageCount={null}
                      containerClassName={"pagination"}
                      previousLinkClassName={"page-link"}
                      nextLinkClassName={"page-link"}
                      activeClassName={"active"}
                      disabledClassName={"page-item"}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center mt-5">
          <Button
            className="btn-primary-outline px-5 mr-3"
            onClick={() => _closeModal()}
          >
            Close
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PreviousRequestModal;
