export const availableLanguages = [
  {
    key: "en",
    label: "English",
  },
  {
    key: "sp",
    label: "Spanish",
  },
];

export const assignToComplimentaryServicesConfig = [
  { label: "Front Desk", value: "frontDesk" },
  { label: "House Keeping", value: "houseKeeping" },
  { label: "Concierge", value: "concierge" },
  { label: "In Room Dining", value: "inRoomDining" },
];

export const permissionsConfig = [
  // { label: "View Amenity", value: "viewamenity" },
  { label: "Manage Amenity", value: "manageamenity" },
  { label: "Manage Settings", value: "managesettings" },
  { label: "Manage Team Member", value: "manageteam" },
  { label: "Manage Resort Details", value: "manageresort" },
  { label: "Manage Feed", value: "managefeed" },
  { label: "Manage Offer", value: "manageoffer" },
  { label: "Manage Subscription", value: "managesubscription" },
];

export const daysOfWeek = [
  { label: "Sunday", value: "sunday" },
  { label: "Monday", value: "monday" },
  { label: "Tuesday", value: "tuesday" },
  { label: "Wednesday", value: "wednesday" },
  { label: "Thursday", value: "thursday" },
  { label: "Friday", value: "friday" },
  { label: "Saturday", value: "saturday" },
];

export const requestStatusConfig = [
  { label: "Unassigned", value: "unassigned" },
  { label: "Pending", value: "pending" },
  { label: "In Progress", value: "inprogress" },
  { label: "Completed", value: "completed" },
  { label: "Cancelled", value: "cancelled" },
];

export const MonthsInEngConfig = [
  { month: "01", short_name: "Jan", full_name: "January" },
  { month: "02", short_name: "Feb", full_name: "February" },
  { month: "03", short_name: "Mar", full_name: "March" },
  { month: "04", short_name: "Apr", full_name: "April" },
  { month: "05", short_name: "May", full_name: "May" },
  { month: "06", short_name: "Jun", full_name: "June" },
  { month: "07", short_name: "Jul", full_name: "July" },
  { month: "08", short_name: "Aug", full_name: "August" },
  { month: "09", short_name: "Sep", full_name: "September" },
  { month: "10", short_name: "Oct", full_name: "October" },
  { month: "11", short_name: "Nov", full_name: "November" },
  { month: "12", short_name: "Dec", full_name: "December" },
];

export const DaysOfWeekConfig = [
  { day: "01", short_name: "Sun", full_name: "Sunday" },
  { day: "02", short_name: "Mon", full_name: "Monday" },
  { day: "03", short_name: "Tue", full_name: "Tuesday" },
  { day: "04", short_name: "Wed", full_name: "Wednesday" },
  { day: "05", short_name: "Thu", full_name: "Thursday" },
  { day: "06", short_name: "Fri", full_name: "Friday" },
  { day: "07", short_name: "Sat", full_name: "Saturday" },
];

// in usd
export const subscriptionPricing = [
  { monthlyPrice: 75, rooms: { min: 1, max: 50 } },
  { monthlyPrice: 200, rooms: { min: 51, max: 100 } },
  { monthlyPrice: 300, rooms: { min: 101, max: 150 } },
  { monthlyPrice: 400, rooms: { min: 151, max: 200 } },
  { monthlyPrice: 500, rooms: { min: 201, max: 250 } },
  { monthlyPrice: 600, rooms: { min: 251, max: 300 } },
  { monthlyPrice: 700, rooms: { min: 301, max: 350 } },
  { monthlyPrice: 800, rooms: { min: 351, max: 400 } },
  { monthlyPrice: 900, rooms: { min: 401, max: 450 } },
  { monthlyPrice: 1000, rooms: { min: 451, max: 500 } },
  { monthlyPrice: 1100, rooms: { min: 500 } },
];

export const timeValues = [
  { label: "--:--", value: "" },
  { label: "12:00 AM", value: "00.00" },
  { label: "12:30 AM", value: "00.30" },
  { label: "01:00 AM", value: "1.00" },
  { label: "01:30 AM", value: "1.30" },
  { label: "02:00 AM", value: "2.00" },
  { label: "02:30 AM", value: "2.30" },
  { label: "03:00 AM", value: "3.00" },
  { label: "03:30 AM", value: "3.30" },
  { label: "04:00 AM", value: "4.00" },
  { label: "04:30 AM", value: "4.30" },
  { label: "05:00 AM", value: "5.00" },
  { label: "05:30 AM", value: "5.30" },
  { label: "06:00 AM", value: "6.00" },
  { label: "06:30 AM", value: "6.30" },
  { label: "07:00 AM", value: "7.00" },
  { label: "07:30 AM", value: "7.30" },
  { label: "08:00 AM", value: "8.00" },
  { label: "08:30 AM", value: "8.30" },
  { label: "09:00 AM", value: "9.00" },
  { label: "09:30 AM", value: "9.30" },
  { label: "10:00 AM", value: "10.00" },
  { label: "10:30 AM", value: "10.30" },
  { label: "11:00 AM", value: "11.00" },
  { label: "11:30 AM", value: "11.30" },
  { label: "12:00 PM", value: "12.00" },
  { label: "12:30 PM", value: "12.30" },
  { label: "01:00 PM", value: "13.00" },
  { label: "01:30 PM", value: "13.30" },
  { label: "02:00 PM", value: "14.00" },
  { label: "02:30 PM", value: "14.30" },
  { label: "03:00 PM", value: "15.00" },
  { label: "03:30 PM", value: "15.30" },
  { label: "04:00 PM", value: "16.00" },
  { label: "04:30 PM", value: "16.30" },
  { label: "05:00 PM", value: "17.00" },
  { label: "05:30 PM", value: "17.30" },
  { label: "06:00 PM", value: "18.00" },
  { label: "06:30 PM", value: "18.30" },
  { label: "07:00 PM", value: "19.00" },
  { label: "07:30 PM", value: "19.30" },
  { label: "08:00 PM", value: "20.00" },
  { label: "08:30 PM", value: "20.30" },
  { label: "09:00 PM", value: "21.00" },
  { label: "09:30 PM", value: "21.30" },
  { label: "10:00 PM", value: "22.00" },
  { label: "10:30 PM", value: "22.30" },
  { label: "11:00 PM", value: "23.00" },
  { label: "11:30 PM", value: "23.30" },
];
