import React from "react";
import { APP_NAME } from "../../config";

function AuthFooter() {
  return (
    <>
      <footer className="footer public">
        <div className="d-flex align-items-center justify-content-between">
          <span>&copy; 2022 {APP_NAME}</span>
          {/* <span className="powered-by" style={{ color: "#333" }}>
            Powered by
            <a
              href="https://www.logic-square.com/"
              target="_blank"
              className="ml-2"
              rel="noopener noreferrer"
            >
              Logic Square
            </a>
          </span> */}
        </div>
      </footer>
    </>
  );
}

export default AuthFooter;
