import { store } from "../redux/store";

export const getActiveResortId = () => {
  return new Promise((resolve, reject) => {
    let resortId = null;

    const { userCredential } = store.getState();

    if (userCredential?.selectedResort?._id) {
      resortId = userCredential.selectedResort._id;
    }

    resolve(resortId);
  });
};
