import KpiDashboard from "./views/protectedPages/KpiDashboard";
import HotelAmenities from "./views/protectedPages/HotelAmenities";
import TeamMembers from "./views/protectedPages/TeamMembers";
import GroupChat from "./views/protectedPages/GroupChat";
import GuestRequests from "./views/protectedPages/GuestRequests";
import OffersPage from "./views/protectedPages/OffersPage";
import SubscriptionInvoice from "./views/protectedPages/SubscriptionInvoice";
import ProfilePage from "./views/protectedPages/ProfilePage";
import ResortDetails from "./views/protectedPages/ResortDetails";

import KpiDashboardIcon from "./assets/img/KpiDashboardIcon.svg";
import HotelIcon from "./assets/img/hotel_amenities_icon.svg";
import ResortDetailsIcon from "./assets/img/resort_details_icon.svg";
import TeamIcon from "./assets/img/team.svg";
import ChatIcon from "./assets/img/chatgroup.svg";
import RequestIcon from "./assets/img/request.svg";
import OfferIcon from "./assets/img/OfferIcon.svg";
import SubscriptionIcon from "./assets/img/subscription.svg";
import profileIcon from "./assets/img/Icon_Profile.svg";

var routes = [
  {
    path: "/dashboard",
    layout: "",
    name: "Dashboard",
    multiLangString: "pages.Sidebar.dashboard",
    icon: "fa fa-users",
    imageIcon: KpiDashboardIcon,
    ImageSize: 30,
    component: KpiDashboard,
    permissionName: "manageresort",
    isCheckPermission: true,
  },
  {
    path: "/requests",
    layout: "",
    name: "Guest Requests",
    multiLangString: "pages.Sidebar.guestRequests",
    icon: "fa fa-user-circle",
    imageIcon: RequestIcon,
    ImageSize: 30,
    component: GuestRequests,
    isCheckPermission: false,
  },
  {
    path: "/groups",
    layout: "",
    name: "Guest Groups",
    multiLangString: "pages.Sidebar.guestGroups",
    icon: "fa fa-commenting",
    imageIcon: ChatIcon,
    ImageSize: 30,
    component: GroupChat,
    permissionName: "managefeed",
    isCheckPermission: true,
  },
  {
    path: "/offers",
    layout: "",
    name: "Special Offers",
    multiLangString: "pages.Sidebar.specialOffers",
    icon: "fa fa-user-circle",
    imageIcon: OfferIcon,
    ImageSize: 30,
    component: OffersPage,
    permissionName: "manageoffer",
    isCheckPermission: true,
  },
  {
    path: "/amenities",
    layout: "",
    name: "Property Amenities",
    multiLangString: "pages.Sidebar.propertyAmenities",
    icon: "fa fa-building",
    imageIcon: HotelIcon,
    ImageSize: 30,
    component: HotelAmenities,
    permissionName: "manageamenity",
    isCheckPermission: true,
  },
  {
    path: "/property-profile",
    layout: "",
    name: "Property Profile",
    multiLangString: "pages.Sidebar.propertyProfile",
    icon: "fa fa-users",
    imageIcon: ResortDetailsIcon,
    ImageSize: 30,
    component: ResortDetails,
    permissionName: "manageresort",
    isCheckPermission: true,
  },
  {
    path: "/team-members",
    layout: "",
    name: "Team Members",
    multiLangString: "pages.Sidebar.teamMembers",
    icon: "fa fa-users",
    imageIcon: TeamIcon,
    ImageSize: 30,
    component: TeamMembers,
    permissionName: "manageteam",
    isCheckPermission: true,
  },
  // {
  //   path: "/billing",
  //   layout: "",
  //   name: "Billing",
  //   multiLangString: "pages.Sidebar.billing",
  //   icon: "fa fa-user-circle",
  //   imageIcon: SubscriptionIcon,
  //   ImageSize: 30,
  //   component: SubscriptionInvoice,
  //   permissionName: "managesubscription",
  //   isCheckPermission: true,
  // },
  {
    path: "/user-profile",
    layout: "",
    name: "User Profile",
    multiLangString: "pages.Sidebar.userProfile",
    icon: "fa fa-user-circle",
    imageIcon: profileIcon,
    component: ProfilePage,
    ImageSize: 24,
    isCheckPermission: false,
  },
  // {
  //   collapse: true,
  //   path: "/components",
  //   name: "Components",
  //   state: "openComponents",
  //   icon: "nc-icon nc-app",
  //   views: [
  //     {
  //       path: "/buttons",
  //       layout: "",
  //       name: "Buttons",
  //       mini: "B",
  //       component: Buttons,
  //     },
  //     {
  //       path: "/grid-system",
  //       layout: "",
  //       name: "Grid System",
  //       mini: "GS",
  //       component: GridSystem,
  //     },
  //     {
  //       path: "/panels",
  //       layout: "",
  //       name: "Panels",
  //       mini: "P",
  //       component: Panels,
  //     },
  //     {
  //       path: "/sweet-alert",
  //       layout: "",
  //       name: "Sweet Alert",
  //       mini: "SA",
  //       component: SweetAlert,
  //     },
  //     {
  //       path: "/notifications",
  //       layout: "",
  //       name: "Notifications",
  //       mini: "N",
  //       component: Notifications,
  //     },
  //     {
  //       path: "/icons",
  //       layout: "",
  //       name: "Icons",
  //       mini: "I",
  //       component: Icons,
  //     },
  //     {
  //       path: "/typography",
  //       layout: "",
  //       name: "Typography",
  //       mini: "T",
  //       component: Typography,
  //     },
  //   ],
  // },
];
export default routes;
