import React, { Component } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Button,
} from "reactstrap";
import { deepClone } from "../../helper-methods/index1";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
  },
};

class MultiMediaModal extends Component {
  state = {
    activeIndex: 0,
    animating: false,
    isAudioPlay: false,
  };

  _next = () => {
    const { activeIndex, animating } = this.state;
    const { content } = deepClone(this.props);
    if (animating) return;
    const nextIndex = activeIndex === content.length - 1 ? 0 : activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  };

  _onToggleAudioPlayPause = (isAudioPlay = false) => {
    this.setState({ isAudioPlay });
  };

  _previous = () => {
    const { activeIndex, animating } = this.state;
    const { content } = deepClone(this.props);
    if (animating) return;
    const nextIndex = activeIndex === 0 ? content.length - 1 : activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  };

  _goToIndex = (newIndex) => {
    const { animating } = this.state;
    if (animating) return;
    this.setState({ activeIndex: newIndex });
  };

  _setAnimating = (value) => {
    this.setState({ animating: value });
  };

  _onEnter = (e, item) => {};

  _onExit = (e, item) => {
    if (item.mediaType === "video" || item.contentType === "video") {
      const video = e.getElementsByTagName("video");
      if (video && video.length) {
        if (!video[0].paused) {
          video[0].pause();
        }
      }
    } else if (item.mediaType === "audio" || item.contentType === "audio") {
      const audio = e.getElementsByTagName("audio");
      if (audio && audio.length) {
        if (!audio[0].paused) {
          audio[0].pause();
        }
      }
    }
  };

  _getSrcUrl = (item) => {
    let url = "";
    if (item) {
      if (item.previewBlob) {
        url = item.previewBlob;
      } else if (item.signedUrl) {
        url = item.signedUrl;
      } else if (item._contentId && item._contentId.url) {
        url = item._contentId.url;
      } else if (item._content && item._content.url) {
        url = item._content.url;
      } else if (item.url) {
        url = item.url;
      }
    }
    return url;
  };

  _getActiveContentItem = (item, index) => {
    if (item.type || item.mediaType) {
      switch (item.type || item.mediaType) {
        case "image":
          return (
            <img
              src={this._getSrcUrl(item)}
              alt="media"
              className="mediaPhoto-Feed"
              loading="lazy"
            />
          );
        case "video":
          return (
            <video
              className="videoInModal"
              src={this._getSrcUrl(item)}
              controls
              controlsList="nodownload"
              disablePictureInPicture
            />
          );
        case "audio":
          return (
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
              <div
                className="audioWrapper bg-white rounded"
                style={{ maxWidth: 580, height: 315, paddingTop: 5 }}
              >
                <div
                  className={`audioRipple ${
                    this.state.isAudioPlay ? "audioRippleStart" : ""
                  }`}
                >
                  <img
                    src="/assets/img/microphone.png"
                    alt="Microphone"
                    loading="lazy"
                  />
                </div>
                <audio
                  className="audioInModal"
                  style={{ width: "95%" }}
                  src={this._getSrcUrl(item)}
                  controls
                  controlsList="nodownload"
                  onPlay={() => this._onToggleAudioPlayPause(true)}
                  onPause={() => this._onToggleAudioPlayPause(false)}
                />
              </div>
            </div>
          );
        default: {
          return null;
        }
      }
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { activeIndex } = this.props;
    if (
      (activeIndex || activeIndex === 0) &&
      prevProps.activeIndex !== activeIndex
    ) {
      this.setState({ activeIndex });
    }
  };

  render() {
    const { isOpen, toggle, content, isHideTotalLength } = deepClone(
      this.props
    );

    const { activeIndex } = this.state;

    const slides =
      content && content.length
        ? content.map((item, index) => {
            return (
              <CarouselItem
                key={item._id || index}
                onExiting={() => this._setAnimating(true)}
                onExited={() => this._setAnimating(false)}
                onExit={(e) => this._onExit(e, item)}
                onEnter={(e) => this._onEnter(e, item)}
              >
                {this._getActiveContentItem(item, index)}
                {/* <CarouselCaption 
                        captionText={item.caption} 
                        captionHeader={item.caption} 
                    /> */}
              </CarouselItem>
            );
          })
        : null;

    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        style={customStyles}
        ariaHideApp={false}
        className="multipleMediaModal"
      >
        <Button onClick={toggle} className="closeModalFeed">
          <i className="fa fa-close modalFeed_closeIcon" />
        </Button>

        {content && content.length ? (
          <>
            {/* show active content page number / total content - ( 1 / 8 )*/}
            <div className="mediaCountCarousel">
              {activeIndex + 1}
              {!isHideTotalLength ? `/${content.length}` : null}
            </div>
            <Carousel
              activeIndex={activeIndex}
              next={this._next}
              previous={this._previous}
              ride="carousel"
              autoPlay={false}
              interval={false}
            >
              <CarouselIndicators
                items={content}
                activeIndex={activeIndex}
                onClickHandler={this._goToIndex}
              />
              {slides}
              <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={this._previous}
              />
              <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={this._next}
              />
            </Carousel>
          </>
        ) : (
          <div className="noContentFound">No Content found</div>
        )}
      </Modal>
    );
  }
}

export default MultiMediaModal;
