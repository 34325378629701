export const BASE_URL =
  process.env.REACT_APP_BACKEND_ENV === "live"
    ?// "https://api.vaycoplus.com/api/v1"
   "https://vaycoapi.appdevelopmentservices.in:2096/api/v1"
//:"https://api.vaycoplus.com/api/v1";
:"https://vaycoapi.appdevelopmentservices.in:2096/api/v1";
//: "https://api-vayco.logic-square.com/api/v1";
//:"https://api.vaycoplus.com/api/v1";

export const SOCKET_BASE_URL =
  process.env.REACT_APP_BACKEND_ENV === "live"
    ? "https://api.vaycoplus.com"
    : "https://api.vaycoplus.com";
    
//     ? "https://api.vaycoplus.com"
//     //: "https://api-vayco.logic-square.com";
// :"https://api.vaycoplus.com";

// export const STRIPE_API_KEY =
//   process.env.REACT_APP_BACKEND_ENV === "live"
//     ? "pk_live_51KBoDNCNv9oLY0GjH7uaa9U0E5tWWT9YlDMienfVZM8IWVCYXkgUrxETWFNiqbygKtWJKFtInD9ukkSYFue5BhVZ00L0taq3VI"
//     : //"pk_test_51KBoDNCNv9oLY0Gj9ckizSnaCFJEFsZLqwPlpZH084m8D99rE411tNo2BXv0TICbFF6HkHD81ZiaSJ5mMCFD9z8L00JPp8hfF8"
//       "pk_test_51J2aemGbmF3o6wbBym2X2PoGgzlmaqYKhDag9CwgDQMWlHrlA3KbCUq1VMQBWmXftzQ0v79gvfysh78LBTLF4tI600I2EiO6Bg";
//     


      //
      export const STRIPE_API_KEY =
  process.env.REACT_APP_BACKEND_ENV === "live"
    ? "pk_test_51My7tSSJI2djsijNfG0OPHHYHnVQy43XqUtocXoTDpL1o9nfp6tZFQDwPcj5csRmTJMPYp6L3IZF5FGyQCD1DsB600KdJKNtoM"
    : 
      "pk_test_51My7tSSJI2djsijNfG0OPHHYHnVQy43XqUtocXoTDpL1o9nfp6tZFQDwPcj5csRmTJMPYp6L3IZF5FGyQCD1DsB600KdJKNtoM";
 


export const SOCIAL_CREDENTIALS = {
  facebookAppId:
    process.env.REACT_APP_BACKEND_ENV === "live"
      ? "594500855125311"  
      : "594500855125311",
  googleClientId:
    process.env.REACT_APP_BACKEND_ENV === "live"
      // ? "894032215568-ta8bcs98t1ibmk52ol963c9mi2gaejad.apps.googleusercontent.com"
      // : "894032215568-ta8bcs98t1ibmk52ol963c9mi2gaejad.apps.googleusercontent.com",
     ?"602984572273-i5jrn9uqvgsrod0i1so6acss3inhkqr1.apps.googleusercontent.com"
      :"602984572273-i5jrn9uqvgsrod0i1so6acss3inhkqr1.apps.googleusercontent.com",
};

export const OneSignalKey =
  process.env.REACT_APP_BACKEND_ENV === "live"
    ? "ad62f87d-ba16-4a1f-81e5-5a66139b9218" // same as dev
    : "ad62f87d-ba16-4a1f-81e5-5a66139b9218";

export const IP_FETCH_URL = "https://ipapi.co/json/";

// export const ReCAPTCHA_SITE_KEY = "6Ld6hW0dAAAAAOKS5Qv4XAad1-iRqrVksjiOPCuu";
// export const ReCAPTCHA_SECRET_KEY = "6Ld6hW0dAAAAAJbrLP0nx_hJFv2ez-W0oSUBG4Ya";

export const AWS_IMAGE_BUCKET_NAME =
 // process.env.REACT_APP_BACKEND_ENV === "live" ? "vaycoplus" : "vayco-content";
  //process.env.REACT_APP_BACKEND_ENV === "live" ? "vaycoplus" : "vaycoplus-dev";
process.env.REACT_APP_BACKEND_ENV === "live" ? "vaycoplus" : "vaycoplus";
export const DEFAULT_PROFILE_PICTURE =
  require("../assets/img/default_user_icon.svg").default;

export const APP_NAME = "Vayco+";

export const APP_LOGO = require("../assets/img/logo_white.png").default;

export const APP_DASHBOARD_LINK = "www.vaycoplus.com";

export const LOGIN_PAGE_BANNER =
  require("../assets/img/login_banner.jpg").default;

export const REGISTER_PAGE_BANNER =
  require("../assets/img/register_banner.jpg").default;
