const sp = {
  pages: {
    Login: {
      login: "Acceso",
      forgotPassword: "¿Has olvidado tu contraseña?",
      continueWith: "Continua con",
      backToLogin: "Atrás para iniciar sesión",
      registerResort: "Registrarse",
      registerYourResort: "Registre su resort",
      contactPersonDetails: "Detalles de la persona de contacto",
    },
    TeamMembers: {
      teamMembers: "Miembros del equipo",
      assignment: "Asignación",
      permissions: "Permisos",
      createNew: "Crear nuevo",
      home: "Casa",
      company: "Empresa",
      portfolio: "portafolio",
      blog: "Blog",
    },
    CreateTeamMember: {
      teamMember: "Miembro del equipo",
      location: "Ubicación",
      enterTheLocation: "Ingrese la ubicación",
      permissions: "Permisos",
      assignTo: "Asignar a",
      complementaryServices: "Servicios de cortesía",
      amenities: "Comodidades",
    },
    Resorts: {
      resorts: "Resorts",
      roomCount: "Numero de Habitaciones",
      plan: "Plan",
      location: "Ubicación",
      verification: "Verificación",
      basic: "Básico",
      plus: "Plus",
      premium: "Premium",
      pending: "Pendiente",
      accepted: "Aceptado",
      enter: "Ingresar",
      addResort: "Agregar Propiedad",
      resort: "Propiedad",
      feed: "Comida",
      rooms: "Habitaciones",
      addedOn: "Agregado",
      poc: "Punto de Contacto",
      amenities: "Amenidades",
      staff: "Personal",
      offers: "Ofertas",
      request: "Petición",
      comments: "Comentarios",
      subject: "Asunto",
      enterTheSubject: "Introduzca el asunto",
      normal: "Normal",
      enterYourMessageHere: "Introduzca su mensaje aquí.",
      send: "Enviar",
      propertyProfile: "Perfil de propiedad",
      numberOfRooms: "Número de habitaciones",
      checkInTime: "Hora de entrada",
      checkOutTime: "Hora de salida",
      selectCheckInTime: "Seleccione Check In Time",
      selectCheckOutTime: "Seleccione el tiempo de salida",
      enterTheNumberOfRooms:
        "Ingrese el número de habitaciones en sus resorts.",
      enterYourResortName: "Ingrese su nombre del resort",
      resortAddress: "Dirección turística",
      city: "Ciudad",
      country: "País",
      state: "Expresar",
      zipcode: "Código postal",
      enterYourResortAddress: "Ingrese su dirección del resort",
      enterTheCity: "Entrar en la ciudad",
      selectState: "Seleccione estado",
      enterTheState: "Entrar en el estado",
      enterTheZipcode: "Ingrese el código postal",
      commentsIfAny: "Comentarios si hay alguno",
      enterTheComments: "Ingrese los comentarios",
      resortName: "Nombre del resort",
      resortDetails: "Detalles del complejo",
    },
    Vacationers: {
      vacationers: "Vacacionistas",
      trips: "Excursiones",
      outings: "Salidas",
      request: "Petición",
      lastActive: "Actividad",
      subject: "Asunto",
      enterTheSubject: "Introduzca el asunto",
      normal: "Normal",
      enterYourMessageHere: "Introduzca su mensaje aquí.",
      send: "Enviar",
    },
    Outings: {
      outings: "Salidas",
      resort: "Propiedad",
      pax: "Cantidad de personas",
      dateRange: "Rango de fechas",
      discount: "Descuento",
      booked: "Reservado",
      completed: "Terminado",
      cancelledByUser: "Cancelado por el usuario",
      cancelledByOperator: "CANCELADO POR OPERADOR",
      enter: "Ingresar",
      vacationer: "Veraneante",
      location: "Ubicación",
      amount: "Monto",
      date: "Fecha",
      invoice: "Factura",
    },
    KPIs: {
      dashboard: "Tablero",
      activeRegisteredResorts: "Propiedades activas registradas",
      activeUsers: "Usuarios activos",
      rooms: "Habitaciones",
      notGiven: "No proporcionado",
      selectPeriod: "Seleccione periodo ",
      outings: "Salidas",
      usingChat: "Usando chat",
      pageViews: "Visitas a la pagina",
      clickThroughRate: "Indice de Clicks",
      bookingResortServices: "Reservaciones de servicios de la propiedad",
      booking: "Reservacion",
      service: "Servicio",
      activeUsersBookingEConcierge: "Usuarios activos reservando ECONCIERGE",
      serviceReservations: "Reservas de servicio",
      reservations: "Reservas",
      guestDemographics: "Demografía invitada",
      overThePastWeek: "Durante la semana pasada",
      guestMakingReservation: "Invitados haciendo reserva",
      restaurants: "Restaurantes",
      spa: "Spa",
      kidsClub: "Club de Niños",
      services: "Servicios",
      serviceBreakdown: "Desglose de servicio",
      guestAge: "Edad del establecimiento",
      specialOffers: "Ofertas especiales",
      totalClicks: "Clic total",
      clickRate: "Tasa de clic",
      topOffers: "Ofertas principales",
      datePosted: "Fecha de publicación",
      feedPerformance: "Rendimiento de alimentación",
      guestViews: "Vistas de huéspedes",
      guestLikes: "Le gusta al invitado",
      topFeedPosts: "Postes principales de piensos",
      totalLikes: "Likes totales",
      views: "Puntos de vista",
      likes: "Gustos",
      feedPosts: "Publicaciones de piensos",
      eButler: "eButler",
      guestUsingEConcierge: "Huespedes que usan eConcierge",
      frontDesk: "Front Desk",
      houseKeeping: "Limpieza",
      conciergeDesk: "Servicio de conserje",
      inRoomDining: "Servicio de habitaciones",
      eConciergeResponseTime: "Tiempo de respuesta eConcierge",
      averageResponseTime: "Tiempo promedio de respuesta",
    },
    Profile: {
      profile: "Perfil",
      userProfile: "Perfil del usuario",
      enterYourPhoneNumber: "Ingrese su número telefónico",
      rolesAndPermissions: "Roles y Permisos",
    },
    Settings: {
      settings: "Ajustes",
      complementaryServices: "Servicios de cortesía",
      frontDesk: "Front Desk",
      addFrontDesk: "Agregar Front Desk",
      houseKeeping: "Limpieza",
      addHouseKeeping: "Agregar limpieza de habitaciones",
      concierge: "Conserje",
      addConcierge: "Agregar servicio de conserje",
      inRoomDining: "Servicio de habitaciones",
      addInRoomDining: "Agregar servicio de habitaciones",
      update: "Actualizar",
      reset: "Reiniciar",
      amenityCategories: "Categorias de amenidades",
      label: "Etiqueta",
      category: "Categoría",
      add: "Agregar",
      fitness: "Fitness",
      restaurants: "Restaurantes",
      spa: "Spa",
      pools: "Piscinas",
      kidsClub: "Club de Niños",
    },
    Sidebar: {
      dashboard: "Tablero",
      guestRequests: "Solicitudes de huespedes",
      guestGroups: "Grupos de huespedes",
      specialOffers: "Ofertas especiales",
      propertyAmenities: "Amenidades de la propiedad",
      propertyProfile: "Perfil de propiedad",
      teamMembers: "Miembros del equipo",
      billing: "Facturación",
      userProfile: "Perfil del usuario",
    },
    General: {
      somethingWentWrong: "Algo salió mal, inténtalo de nuevo pronto.",
      serverError: "Error del Servidor. Inténtalo de nuevo pronto.",
      required: "Requerido",
      invalid: "Inválido",
      logout: "Cerrar sesión",
      name: "Nombre",
      phone: "Teléfono",
      phoneNumber: "Número de teléfono",
      username: "Nombre de usuario",
      enterTheUsername: "Ingrese el usuario",
      email: "Correo electrónico",
      password: "Clave",
      addResort: "Agregar propiedad",
      seeMore: "Ver más",
      showingEntriesOutOf:
        "Mostrando <<showCount>> entradas fuera de <<totalCount>>",
        redColorForCancelRequest:"El mensaje rojo indica que la solicitud ha sido cancelada.",
      noData: "No hay informacion para mostrar",
      all: "Todos",
      enter: "Ingresar",
      active: "Activo",
      inactive: "Inactivo",
      create: "Crear",
      update: "Actualizar",
      reset: "Reiniciar",
      edit: "Editar",
      delete: "Borrar",
      remove: "Remover",
      save: "Guardar",
      dismiss: "Despedir",
      cancel: "Cancelar",
      add: "Agregar",
      replace: "Reemplazar",
      upload: "Subir",
      uploadLogo: "Subir Logo",
      uploadPhotos: "Subir fotos",
      enterYourName: "Introduzca su nombre",
      enterTheName: "Ingrese el nombre",
      enterYourPhoneNumber: "Ingrese su número telefónico",
      enterThePhoneNumber: "Ingrese el número de teléfono",
      enterYourEmail: "Introduce tu correo electrónico",
      enterTheEmail: "Ingrese el correo electrónico",
      enterYourPassword: "Ingresa tu contraseña",
      enterThePassword: "Introduce la contraseña",
      selectAll: "Seleccionar todo",
      select: "Seleccione",
      searchByName: "Buscar por nombre", 
      searchByOfferName:"Buscar por nombre de oferta",
      status: "Estado",
      settings: "Ajustes",
      createNew: "Crear nuevo",
      action: "Acción",
      typeYourMessageHere: "Escribe tu mensaje aquí...",
      archive: "Archivo",
      download: "Descargar",
      print: "Imprimir",
      startTime: "Hora de inicio",
      endTime: "Hora de finalización",
      startDate: "Fecha de inicio",
      endDate: "Fecha final",
      selectTheStartDate: "Seleccione la fecha de inicio",
      selectTheEndDate: "Seleccione la fecha de finalización",
      startDateAndTime: "Fecha y hora de inicio",
      endDateAndTime: "Fecha de finalización y hora",
      selectTheStartDateAndTime: "Seleccione la fecha y hora de inicio",
      selectTheEndDateAndTime: "Seleccione la fecha y hora de finalización",
      description: "Descripción",
      enterTheDescription: "Ingrese la descripción",
      checkInCheckOut: "Llegada Salida",
      group: "Grupo",
      coverPhoto: "Foto de cubierta",
      makeCover: "Cubrir",
      amenity: "Amenidad",
      readmore: "Lee mas",
      readless: "Leer menos",
      sendEmail: "Enviar correo electrónico",
      notifications: "Notificaciones",
      language: "Idioma",
      afterResortRegisterReviewNote:
        "Un ejecutivo de cuenta de <<appName>> revisará su envío y se comunicará con usted en breve para ayudarlo a comenzar y responder cualquier pregunta adicional que pueda tener.",
      areYouSure: "¿Está seguro?",
      yesDeleteIt: "Sí, borrarlo!",
      yesRemoveIt: "Sí, quítelo!",
      yesArchiveIt: "¡Sí, archivarlo!",
      youWillNotRecoverIt: "¡No podrás recuperarlo!",
    },
    Billings: {
      billing: "Facturación",
      plan: "Plan",
      billingPeriod: "Período de facturación",
      numberOfRooms: "# de habitaciones",
      amount: "Monto",
      dueDate: "Fecha de vencimiento",
      date: "Fecha",
      pay: "Pagar",
      processing: "Procesando",
    },
    GuestRequests: {
      guestRequests: "Solicitudes de huespedes",
      vacationer: "Veraneante",
      serviceCategory: "Categoría de servicio",
      assignee: "Cesionario",
      lastUpdate: "Última actualización",
      roomNumber: "Habitación #",
      groupName: "Nombre del grupo",
      requestStatus: "Estado de la solicitud",
      viewRequest: "Visión de solicitud",
      checkIn: "Registrarse",
      checkOut: "Verificar",
      serviceDate: "Fecha de servicio",
      serviceTime: "Tiempo de servicio",
      totalGuest: "Huésped total",
      selectTime: "Seleccione la hora",
      enterTheComment: "Ingrese el comentario",
      accept: "Aceptar",
      decline: "Disminución",
      hasCreatedNewRequest: "<<name>> ha creado una nueva solicitud para",
      selectAssignee: "Seleccione Asignado",
    },
    GuestGroups: {
      guestGroups: "Grupos de huespedes",
      allMembers: "Todos los miembros",
      createGroup: "Crea un grupo",
      noMember: "Ningún miembro encontrado en este grupo.",
      enterTheGroupName: "Ingrese el nombre del grupo",
      uploadGroupIcon: "Sube icono de grupo",
      QRCodeWillCreateAutomatically:
        "* El código QR para el grupo se creará automáticamente.",
    },
    SpecialOffers: {
      specialOffers: "Ofertas especiales",
      createOffer: "Crear oferta",
      offer: "Oferta",
      enterTheOfferName: "Ingrese el nombre de la oferta",
      selectAmenity: "Seleccione Servicios",
      showOfferToVacationer: "Mostrar oferta al vacacionista?",
      notInAdvance: "No por adelantado",
      groups: "Grupo",
      uploadOfferIcon: "Icono de oferta de carga",
    },
    CreateAmenity: {
      amenityDetails: "Detalles de amenidad",
      enterTheAmenityName: "Ingrese el nombre de Servicios",
      selectCategory: "selecciona una categoría",
      acceptBookingRequests: "Aceptar solicitudes de reserva",
      serviceMenu: "Menú de servicio",
      enterTheAmenityAddress: "Ingrese la dirección de amenidad",
      mapOfTheProperty: "Mapa de la propiedad",
      availability: "Solicitud de reserva franjas horarias",
      frequentlyAskedQuestions: "Preguntas frecuentes",
      question: "Pregunta",
      answer: "Responder",
      addMore: "Añadir más",
    },
  },
  components: {},
};

export default sp;
