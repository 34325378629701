import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import LanguageParsedText, {
  getValueFromCurrentLang,
} from "../../multi-lang/lang-parsed-text/LanguageParsedText";
import Chart from "react-apexcharts";
import { getOfferGraphData } from "../../http/http-calls";
import { capitalize, errorHandler, formatDate } from "../../helper-methods/index1";
import LoadingSkeleton from "react-loading-skeleton";

const SpecialOffersGraph = ({ payload }) => {
  // #4
  const [specialOfferClicksGraph, setSpecialOfferClicksGraph] = useState({
    lineOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      stroke: {
        curve: "smooth",
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: [
        {
          labels: {
            style: {
              colors: "#FF1654",
            },
          },
        },
        {
          opposite: true,
          tickAmount: 4,
          min: 0,
          max: 100,
          labels: {
            formatter: function (value) {
              return `${value}%`;
            },
            style: {
              colors: "#247BA0",
            },
          },
        },
      ],
      xaxis: {
        categories: ["Week 1", "Week 2", "Week 3", "Week 4"],
      },
      colors: ["#FF1654", "#247BA0"],
      tooltip: {
        // shared: false,
        x: {
          show: false,
        },
      },
    },
    lineSeries: [
      {
        name: `${getValueFromCurrentLang("pages.KPIs.totalClicks")} #`,
        data: [25, 30, 57, 53],
      },
      {
        name: `${getValueFromCurrentLang("pages.KPIs.clickRate")} %`,
        data: [50, 40, 23, 25],
      },
    ],
  });

  const [topOffers, setTopOffers] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [totalClicks, setTotalClicks] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [clickRate, setClickRate] = useState(0);
  const [loading, setLoading] = useState(false);

  const _setOfferGraph = (offerGraph = []) => {
    const newSpecialOfferClicksGraph = { ...specialOfferClicksGraph };

    newSpecialOfferClicksGraph.lineOptions.xaxis.categories = [];
    newSpecialOfferClicksGraph.lineSeries[0].data = []; // # total Clicks
    newSpecialOfferClicksGraph.lineSeries[1].data = []; // # click Rate

    offerGraph.forEach((each) => {
      newSpecialOfferClicksGraph.lineOptions.xaxis.categories.push(
        `Week ${each._id.split("-")[1]}`
      );
      newSpecialOfferClicksGraph.lineSeries[0].data.push(
        each.details[0].clicks
      );
      // newSpecialOfferClicksGraph.lineSeries[1].data.push(each.likes);
    });

    setSpecialOfferClicksGraph(newSpecialOfferClicksGraph);

    // setTotalClicks(offerGraph.reduce((acc, each) => acc + each.likes, 0));
    // setClickRate(offerGraph.reduce((acc, each) => acc + each.views, 0));
  };

  const _getOfferGraphData = (payload) => {
    setLoading(true);
    getOfferGraphData(payload)
      .then((res) => {
        if (res.result) {
          _setOfferGraph(res.result.offerGraph);
          setTopOffers(res.result.topOffers || []);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        errorHandler(error);
      });
  };

  useEffect(() => {
    if (payload?.startDate && payload?.endDate && payload?.groupByUnit)
      _getOfferGraphData(payload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload?.startDate, payload?.endDate, payload?.groupByUnit]);

  return (
    <div className="graphWrapper">
      <div className="graphTitleWrap">
        <h1>
          <LanguageParsedText keyString="pages.KPIs.specialOffers" />
        </h1>
        <div className="specialOffers">
          <div>
            {loading ? (
              <LoadingSkeleton />
            ) : (
              <h4>
                <span style={{ background: "#ff1654" }} /> {totalClicks}
              </h4>
            )}

            <p>
              <LanguageParsedText keyString="pages.KPIs.totalClicks" />
            </p>
          </div>

          <div>
            {loading ? (
              <LoadingSkeleton />
            ) : (
              <h4>
                <span style={{ background: "#247ba0" }} /> {clickRate}%
              </h4>
            )}
            <p>
              <LanguageParsedText keyString="pages.KPIs.clickRate" />
            </p>
          </div>
        </div>
      </div>

      {/* Special offers Table */}
      <Table className="cardTable" style={{ marginTop: 45 }}>
        <thead>
          <tr>
            <th>
              <LanguageParsedText keyString="pages.KPIs.topOffers" />
            </th>
            <th>
              <LanguageParsedText keyString="pages.KPIs.clickRate" />
            </th>
            <th>
              <LanguageParsedText keyString="pages.KPIs.totalClicks" />
            </th>
            <th>
              <LanguageParsedText keyString="pages.KPIs.datePosted" />
            </th>
          </tr>
        </thead>
        <tbody>
          {topOffers?.length ? (
            topOffers.map((each) => (
              <tr key={each._id}>
                <td>{capitalize(each.name)}</td>
                <td>{each.clickRate || 0}</td>
                <td>{each.totalClick || 0}</td>
                <td>{formatDate(each.createdAt)}</td>
              </tr>
            ))
          ) : loading ? (
            <>
              <tr>
                <td>
                  <LoadingSkeleton />
                </td>
                <td>
                  <LoadingSkeleton />
                </td>
                <td>
                  <LoadingSkeleton />
                </td>
                <td>
                  <LoadingSkeleton />
                </td>
              </tr>
              <tr>
                <td>
                  <LoadingSkeleton />
                </td>
                <td>
                  <LoadingSkeleton />
                </td>
                <td>
                  <LoadingSkeleton />
                </td>
                <td>
                  <LoadingSkeleton />
                </td>
              </tr>
            </>
          ) : (
            <tr>
              <td colSpan="4">There is no data to display.</td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* Special offers Graph */}
      {loading ? (
        <LoadingSkeleton height={200} />
      ) : (
        <>
          {specialOfferClicksGraph.lineSeries[0]?.data?.length ? (
            <Chart
              options={specialOfferClicksGraph.lineOptions}
              series={specialOfferClicksGraph.lineSeries}
              type="area"
              width="100%"
              height="275"
              className="chartWrap mb-1"
              style={{ marginTop: 50 }}
            />
          ) : (
            <p>There is no data to display.</p>
          )}
        </>
      )}
    </div>
  );
};

export default SpecialOffersGraph;
