import React, { useEffect, useRef, useState } from "react";
import { FormGroup, Spinner, Button } from "reactstrap";
import {
  InputGroup,
  FormControl,
  Card,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Label } from "reactstrap";
import { deleteAmenityById, getAllAmenities } from "../../http/http-calls";
import { capitalize, showToast } from "../../helper-methods";
import { DEFAULT_PROFILE_PICTURE } from "../../config";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { hasPermission } from "../../helper-methods/has-permission";
import { getAndUpdateSettings } from "../../redux/actions/settings";
import SweetAlert from "react-bootstrap-sweetalert";
import LanguageParsedText, {
  getValueFromCurrentLang,
} from "../../multi-lang/lang-parsed-text/LanguageParsedText";

let searchInterval;

const HotelAmenities = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const userCredential = useSelector((state) => state["userCredential"]);
  const settings = useSelector((state) => state["settings"]);

  const [getAllAmenitiesPayload, setGetAllAmenitiesPayload] = useState({
    filters: {},
    search: "",
    skip: 0,
    pageNumber: 0,
    limit: 100,
  });
  const [amenities, setAmenities] = useState([]);
  const [filterAmenities, setFilterAmenities] = useState([]);
  // const [amenitiesCount, setAmenitiesCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [removeAmenityLoading, setRemoveAmenityLoading] = useState(false);

  const [alert, setAlert] = useState(null);

  const mountedRef = useRef(true);

  const _getSettings = () => {
    dispatch(getAndUpdateSettings());
  };

  const _getAllAmenities = (payload) => {
    setLoading(true);

    getAllAmenities(payload)
      .then((res) => {
        if (!mountedRef.current) return null;

        setAmenities(res.amenities);
        setFilterAmenities(res.amenities);
        // setAmenitiesCount(res.count);

        setLoading(false);
        setSearchLoading(false);
      })
      .catch((error) => {
        if (!mountedRef.current) return null;

        console.log("error>>", error);
        setLoading(false);
        setSearchLoading(false);
        showToast(
          error?.reason?.length
            ? error.reason
            : getValueFromCurrentLang("pages.General.serverError"),
          "error"
        );
      });
  };

  const _searchFilter = (text = "") => {
    try {
      let newAmenities = [...amenities];

      newAmenities = amenities.filter(
        (each) =>
          each.name?.toLowerCase().includes(text.toLowerCase()) ||
          each.category?.toLowerCase().includes(text.toLowerCase())
        // || each.description?.toLowerCase().includes(text.toLowerCase())
      );

      setSearchLoading(false);

      setFilterAmenities(newAmenities);
    } catch (error) {
      console.log("error>>", error);
      showToast(
        getValueFromCurrentLang("pages.General.somethingWentWrong"),
        "error"
      );
    }
  };

  const _onChangeSearch = (searchValue) => {
    clearInterval(searchInterval);

    setSearchLoading(true);
    const newGetAllAmenitiesPayload = { ...getAllAmenitiesPayload };
    newGetAllAmenitiesPayload["search"] = searchValue;
    newGetAllAmenitiesPayload["skip"] = 0;
    newGetAllAmenitiesPayload["pageNumber"] = 0;
    setGetAllAmenitiesPayload(newGetAllAmenitiesPayload);

    searchInterval = setTimeout(() => {
      _searchFilter(searchValue);
    }, 1000);
  };

  const _formatAmenityByCategory = (amenityCategory, amenitiesList) => {
    if (amenityCategory?.category && amenitiesList?.length) {
      return amenitiesList.filter(
        (each) => each?._group?._id === amenityCategory?._id
      );
    }
    return [];
  };

  const _hideAlert = () => {
    setAlert(null);
  };

  const _successDelete = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Amenity Deleted!"
        onConfirm={() => _hideAlert()}
        onCancel={() => _hideAlert()}
      />
    );
  };

  const _deleteErrorAlert = (error) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title="Amenity is not deleted"
        onConfirm={() => _hideAlert()}
        onCancel={() => _hideAlert()}
        confirmBtnBsStyle="info"
      >
        {error?.reason?.length
          ? error.reason
          : getValueFromCurrentLang("pages.General.serverError")}
      </SweetAlert>
    );
  };

  const _deleteAmenityById = (amenityId) => {
    setRemoveAmenityLoading(amenityId);

    const newAmenities = [...amenities];
    const findAmenityIndex = newAmenities.findIndex(
      (each) => each._id === amenityId
    );
    if (findAmenityIndex < 0) {
      showToast(
        getValueFromCurrentLang("pages.General.somethingWentWrong"),
        "error"
      );
      return;
    }

    newAmenities.splice(findAmenityIndex, 1);

    deleteAmenityById(amenityId)
      .then((res) => {
        setRemoveAmenityLoading(false);
        setAmenities(newAmenities);
        setFilterAmenities(newAmenities);

        // _getAllAmenities(getAllAmenitiesPayload);

        _successDelete();

        showToast(
          getValueFromCurrentLang("pages.General.serverError"),
          "success"
        );
      })
      .catch((error) => {
        console.log("error>>", error);
        setRemoveAmenityLoading(false);

        _getAllAmenities(getAllAmenitiesPayload);
        _deleteErrorAlert(error);

        showToast(
          error?.reason?.length
            ? error.reason
            : getValueFromCurrentLang("pages.General.serverError"),
          "error"
        );
      });
  };

  const _onConfirmAlert = (amenity) => {
    _deleteAmenityById(amenity._id);

    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={
          <div className="d-flex flex-wrap justify-content-center align-items-center">
            <Spinner className="mr-2" /> Deleting
            <span>
              {amenity?.name
                ? `${capitalize(amenity.name)}`
                : amenity?.email || "N/A"}{" "}
            </span>
            Account
          </div>
        }
        onConfirm={() => _hideAlert()}
        onCancel={() => _hideAlert()}
        showConfirm={false}
        cancelBtnBsStyle="danger"
        cancelBtnText={getValueFromCurrentLang("pages.General.dismiss")}
        showCancel
      />
    );
  };

  const _onDeleteAmenityAlert = (amenity) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={getValueFromCurrentLang("pages.General.areYouSure")}
        onConfirm={() => _onConfirmAlert(amenity)}
        onCancel={() => _hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText={getValueFromCurrentLang("pages.General.yesDeleteIt")}
        cancelBtnText={getValueFromCurrentLang("pages.General.cancel")}
        showCancel
      >
        You are deleting {amenity?.name ? `${capitalize(amenity.name)}` : "N/A"}{" "}
        amenity.{" "}
        <LanguageParsedText keyString="pages.General.youWillNotRecoverIt" />
      </SweetAlert>
    );
  };

  useEffect(() => {
    // To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (userCredential?.selectedResort?._id) {
      _getSettings();
      _getAllAmenities(getAllAmenitiesPayload);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCredential?.selectedResort?._id]);

  return (
    <>
      {alert}

      <div className="content">
        <Container fluid className="mobilePadding-0">
          <div className="page_Inner_header">
            <Row className="align-items-center">
              <Col xl={5} md={4}>
                <div className="page_title">
                  <h1 className="space_remove pr-2">
                    <LanguageParsedText keyString="pages.Sidebar.propertyAmenities" />
                  </h1>
                  {hasPermission("managesettings") ? (
                    <Button
                      className="btn btn-primary mr-2"
                      onClick={() => history.push("/settings")}
                    >
                      <LanguageParsedText keyString="pages.General.settings" />
                    </Button>
                  ) : null}
                  {loading ? <Spinner /> : null}
                </div>
              </Col>

              {hasPermission("manageamenity") ? (
                <Col
                  xl={7}
                  md={8}
                  className="text-right d-flex justify-content-md-end justify-content-between mt-3 mt-md-0 align-items-center page_header_right"
                >
                  <Button
                    className="btn btn-primary"
                    onClick={() => history.push("/add-amenity")}
                  >
                    <LanguageParsedText keyString="pages.General.createNew" />
                  </Button>
                </Col>
              ) : null}
            </Row>
          </div>

          {/* ======== Filter ======= */}
          <Card body className="search-filter pt-2 pb-3 mb-4">

            <div className="d-flex justify-content-end align-items-end">
              <FormGroup className="mb-0">
                <Label>
                  <LanguageParsedText keyString="pages.General.searchByName" />
                </Label>
                <InputGroup>
                  <FormControl
                    type="text"
                    placeholder={getValueFromCurrentLang(
                      "pages.General.enter"
                    )}
                    autoComplete="off"
                    name="firstName"
                    value={getAllAmenitiesPayload.search}
                    onChange={(e) => _onChangeSearch(e.target.value)}
                  />
                  <InputGroup.Text id="basic-addon2">
                    {searchLoading ? (
                      <i className="fa fa-spinner fa-spin" />
                    ) : getAllAmenitiesPayload.search ? (
                      <i
                        className="fas fa-times"
                        onClick={() => _onChangeSearch("")}
                      />
                    ) : (
                      <i className="fas fa-search" />
                    )}
                  </InputGroup.Text>
                </InputGroup>
              </FormGroup>
            </div>

          </Card>

          {/* ==== Cards ====== */}
          {filterAmenities?.length && settings?.amenityCategories?.length ? (
            <div className="mt-4 main_inner gridBox bg-white">
              {settings.amenityCategories.map((each) =>
                _formatAmenityByCategory(each, filterAmenities)?.length ? (
                  <div className="tour_list" key={each._id}>
                    <Row className="text-center">
                      <Col xs={12}>
                        <h1 className="title">{capitalize(each.category)}</h1>
                      </Col>

                      {_formatAmenityByCategory(each, filterAmenities).map(
                        (amenity) => (
                          <Col
                            key={amenity._id}
                            xl="3"
                            lg="4"
                            md="4"
                            sm="6"
                            xs="12"
                          >
                            <Card>
                              <Card.Img
                                variant="top"
                                src={
                                  amenity.coverImage || amenity.photos?.length
                                    ? amenity.coverImage || amenity.photos[0]
                                    : DEFAULT_PROFILE_PICTURE
                                }
                                onError={(e) =>
                                  (e.target.src = DEFAULT_PROFILE_PICTURE)
                                }
                                className="img-responsive"
                              />
                              <Card.Body>
                                <Card.Title>
                                  {amenity.name
                                    ? capitalize(amenity.name)
                                    : "N/A"}
                                </Card.Title>
                                {/* <span>
                                {amenity._group?.category
                                  ? capitalize(amenity._group?.category)
                                  : "N/A"}
                              </span> */}

                                {hasPermission("manageamenity") ? (
                                  <span>
                                    {removeAmenityLoading === amenity._id ? (
                                      <i className="fa fa-spinner fa-spin mr-1" />
                                    ) : (
                                      <>
                                        <i
                                          title="Update Amenity"
                                          className="fas fa-pencil-alt edit cursorPointer m-1"
                                          onClick={() =>
                                            history.push(
                                              `/add-amenity?amenityId=${amenity._id}`
                                            )
                                          }
                                        />
                                        <i
                                          title="Remove Amenity"
                                          className="far fa-trash-alt detele cursorPointer m-1"
                                          onClick={() =>
                                            _onDeleteAmenityAlert(amenity)
                                          }
                                        />
                                      </>
                                    )}
                                  </span>
                                ) : null}
                              </Card.Body>
                            </Card>
                          </Col>
                        )
                      )}
                    </Row>
                  </div>
                ) : null
              )}
            </div>
          ) : (
            // {/* card loading  */}
            <div className="mt-4 main_inner gridBox bg-white">
              <div className="tour_list">
                <Row className="text-center">
                  <Col sm="12">
                    {loading ? (
                      <Spinner />
                    ) : (
                      <LanguageParsedText keyString="pages.General.noData" />
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          )}

          {/* {amenities?.length &&
        amenities?.length > 1 &&
        !getAllAmenitiesPayload.search ? (
          <div className="row mt-4">
            <div className="col-md-6">
              <span className="showng_entries">
                Total {amenitiesCount} amenities
              </span>
            </div>
          </div>
        ) : null} */}
        </Container>
      </div>
    </>
  );
};

export default HotelAmenities;
