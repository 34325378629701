import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, FormGroup, Label } from "reactstrap";
import CustomDateRangePicker from "../../components/date-range-picker";
import moment from "moment";
import LanguageParsedText from "../../multi-lang/lang-parsed-text/LanguageParsedText";
import { formatDateForApi } from "../../helper-methods/index1";
import GuestGraph from "../../components/DashboardGraph/GuestGraph";
import ServiceGraph from "../../components/DashboardGraph/ServiceGraph";
import FeedGraph from "../../components/DashboardGraph/FeedGraph";
import SpecialOffersGraph from "../../components/DashboardGraph/SpecialOffersGraph";
import EButlerGraph from "../../components/DashboardGraph/EButlerGraph";

const KpiDashboard = () => {
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
    maxDate: null,
  });
  const [focusedInput, setFocusedInput] = useState(null);
  const [payload, setPayload] = useState({
    startDate: null,
    endDate: null,
    groupByUnit: "week",
  });
 // console.log("jik", moment().subtract(3, "weeks"),"VIBHA", moment().endOf("week"))
  const _resetDateRange = () => {
    let to_date = moment().endOf("week");
   // console.log("lub yuh baby",to_date)
    if (moment().isSame(to_date, "day")) {
      let from_date = moment().subtract(3, "weeks").startOf("week");
     // console.log("jik", moment().subtract(3, "weeks"))
      _onDatesChange({
        startDate: from_date,
        endDate: to_date,
        maxDate: to_date,
        isReset: true,
      });
    } else {
      let from_date = moment().subtract(4, "weeks").startOf("week");
      to_date = moment().subtract(1, "weeks").endOf("week");

      _onDatesChange({
        startDate: from_date,
        endDate: to_date,
        maxDate: to_date,
        isReset: true,
      });
    }
  };

  const _onDatesChange = ({
    startDate,
    endDate,
    maxDate = null,
    isReset = false,
  }) => {
    const newDateRange = { ...dateRange };

    if (startDate) startDate = moment(startDate).startOf("week");
    if (endDate) endDate = moment(endDate).endOf("week");

    newDateRange.startDate = startDate;
    newDateRange.endDate = endDate;

    if (maxDate) newDateRange.maxDate = maxDate;

    setDateRange(newDateRange);

    if (startDate && endDate && (focusedInput === "endDate" || isReset)) {
      const newPayload = { ...payload };
      // newPayload.startDate = "01/04/2022";
      // newPayload.endDate = "01/06/2022";
      newPayload.startDate = formatDateForApi(startDate);
      newPayload.endDate = formatDateForApi(endDate);
      setPayload(newPayload);
    }
  };

  useEffect(() => {
    _resetDateRange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content">
      <Container fluid className="mobilePadding-0">
        <div className="page_Inner_header">
          <Row className="align-items-center">
            <Col xl="5" md="4">
              <div className="page_title">
                <h1 className="space_remove pr-2">
                  <LanguageParsedText keyString="pages.KPIs.dashboard" />
                </h1>
              </div>
            </Col>

            {/* date range filter for all graph */}
            <Col
              xl="7"
              md="8"
              className="text-right d-flex justify-content-md-end justify-content-between mt-3 mt-md-0 align-items-center page_header_right"
            >
              <div className="filterDashboard">
                <FormGroup className="mb-0">
                  <Label>
                    <LanguageParsedText keyString="pages.KPIs.selectPeriod" />
                  </Label>
                  <CustomDateRangePicker
                    startDate={dateRange.startDate}
                    endDate={dateRange.endDate}
                    maxDate={dateRange.maxDate}
                    startDateId={"startDate_kpi_dashboard"}
                    endDateId={`endDate_kpi_dashboard`}
                    isDisabledOutsideRange={true}
                    isMaxDateValidation={true}
                    onDatesChange={({ startDate, endDate }) =>
                      _onDatesChange({ startDate, endDate })
                    }
                    focusedInput={focusedInput}
                    setFocusedInput={setFocusedInput}
                  />
                </FormGroup>

                <Button
                  color="primary"
                  className="btn-clear ml-4"
                  onClick={() => _resetDateRange()}
                >
                  <i className="fa fa-refresh fs-15" />
                </Button>
              </div>
            </Col>
          </Row>
        </div>

        <Row className="RowContainer">
          <Col lg="6">
            {/* Guest Graph Card */}
            <GuestGraph payload={payload} />

            {/* feed performance Card */}
            <FeedGraph payload={payload} />
          </Col>

          <Col lg="6">
            {/* Service Graph Card */}
            <ServiceGraph payload={payload} />

            {/* Special offers Graph Card */}
            <SpecialOffersGraph payload={payload} />
          </Col>

          <Col lg="12">
            {/* eButler Graph Card */}
            <EButlerGraph payload={payload} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default KpiDashboard;
