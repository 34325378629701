import { availableLanguages } from "../../config/helper-config";
import { UPDATE_CURRENT_LANGUAGE } from "../actions";

const initialState = {
  currentLang: "en",
};

export const languageDataReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case UPDATE_CURRENT_LANGUAGE: {
      if (
        action?.payload?.currentLang &&
        availableLanguages.find(
          (each) => each.key === action?.payload?.currentLang
        )
      ) {
        newState = {
          currentLang: action.payload.currentLang,
        };
      }
      break;
    }
    default:
  }
  return newState;
};
