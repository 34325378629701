import {
  makeGetRequest,
  makePostRequest,
  makePutRequest,
  // uploadFile,
  makeDeleteRequest,
  // uploadFileMultiPart
} from "./http-service";
import { BASE_URL, IP_FETCH_URL } from "../config/index";

export const login = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/user/login`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const forgotPassword = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/user/forgotpassword`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const requestInvitation = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/request/invitation`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

/**
 * @param {string} platform - google or facebook
 * @param {object} payload - {accessToken: google or facefook response token}
 * @returns
 */
export const socialLogin = (platform, payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/resort/${platform}/signup`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getIpData = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(IP_FETCH_URL, false, {}, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getIpData call error: ", e);
        reject(e);
      });
  });
};

export const checkAvailability = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/user/unique`, true, payload)
      .then((res) => {
        console.log(res,"res")
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllAmenities = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/amenities`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const createAmenity = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/amenity`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateAmenity = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/amenity/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAmenityById = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/amenity/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const deleteAmenityById = (id) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(`${BASE_URL}/amenity/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const createTeamMember = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/teammember`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllTeamMember = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/teammembers`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getTeamMemberById = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/teammember/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateTeamMember = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/teammember/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getSettings = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/resort/get/settings`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllGroups = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/resort/groups`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const createChatGroup = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/resort/create/group`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateChatGroup = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/resort/group/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const removeParticipantFromGroup = (groupId, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      `${BASE_URL}/resort/participant/remove/${groupId}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

// get chat group member
export const getChatGroupMemberDetails = (payload) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/resort/get/vacationer/booking`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

// update chat group member
export const updateChatGroupMemberDetails = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      `${BASE_URL}/resort/update/vacationer/booking`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllGroupMembers = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/resort/group/participants`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllMessagesByGroupId = (groupId, payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${BASE_URL}/resort/group/messages/${groupId}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const deleteMessageById = (messageId) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(`${BASE_URL}/delete/group/message/${messageId}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateMessageById = (messageId, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      `${BASE_URL}/update/group/message/${messageId}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllRequests = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/resort/bookings`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};


export const getInvitedUserByBookingId = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/get/invited/user/by/booking_id`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};


export const getAllOffers = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/resort/offers`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const createOffer = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/resort/offer`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getOfferById = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/resort/offer/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateOffer = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/resort/offer/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

// Resort get amenities List
export const getAmenitiesList = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/resort/list/amenity`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getRequestById = (requestId) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/resort/booking/${requestId}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateRequest = (requestId, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/resort/booking/${requestId}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllMessagesByRequestId = (requestId, payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${BASE_URL}/resort/booking/messages/${requestId}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

// Resort Admin Get All previous Bookings of specific vacationer
export const getAllPreviousRequest = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/resort/bookings/vacationer`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

// Resort get resortadmin list reagrding booking (Assignee List)
export const getAllAssigneeList = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/resort/list/request/assignee`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

// Resort Admin get all Its own services list
export const getAllServicesList = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/resort/list/services`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

// Get a Admin Profile details
export const getLoggedInUserDetail = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/resort/get/profile-details`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

// Admin update Profile details
export const updateLoggedInUserDetail = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/resort/update/profile-details`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateComplimentaryServices = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/resort/update/settings/services`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const addAmenityCategories = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      `${BASE_URL}/resort/update/settings/add/amenity`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateAmenityCategories = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      `${BASE_URL}/resort/update/settings/edit/amenity/${id}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const reorderAmenityCategories = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      `${BASE_URL}/resort/update/resort-details/amenity/order`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const removeAmenityCategories = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      `${BASE_URL}/resort/update/settings/remove/amenity`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getResortDetails = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/resort/get/resort-details`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateResortDetails = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/resort/update/resort-details`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

/**
 * ResortAdmin Requests for new resort
 */
export const requestForAddNewResort = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/resort/request/new-resort`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// ======= start: card details api ========== //

/**
 * gets cards
 */
export const fetchAllCards = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/user/fetch/cards`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/**
 * Adds and Sets Default Card
 */
export const addCardAndMakeDefault = (token) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/user/add/card`, true, {
      token,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/**
 * Sets Default Card
 */
export const makeCardDefault = (cardId) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/resort/card/default/${cardId}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/**
 * user delete a Card
 */
export const deleteCard = (cardId) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/user/delete/card/${cardId}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// ======= end: card details api ========== //

// ResortAdmin Get All billings of resort
export const getAllResortBillings = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/resort/billings`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

// ResortAdmin pay bill for resort
export const payResortBill = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/resort/pay/bill`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

// Resort get all notifications
export const getAllNotifications = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/resort/notifications`, true, payload)
      .then((res) => {
        resolve(res);
        console.log(res,"getAllNotifications")
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

// Resort read all notifications
export const readAllNotifications = (resortId) => {
  console.log(resortId,"resortId")
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `${BASE_URL}/resort/notifications/read`,
      true,
      null,
      resortId
    )
      .then((res) => {
        resolve(res);
        console.log(res,"readAllNotifications")
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};


// export const getRequestById = (requestId) => {

//   return new Promise((resolve, reject) => {
//     makeGetRequest(`${BASE_URL}/resort/booking/${requestId}`, true)
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((e) => {
//         console.log("API call error>>", e);
//         reject(e);
//       });
//   });
// };


// Resort read  notification
// export const readNotification = (requestId) => {
//   const payloadNoti={notification_id:`${requestId}`}
//   console.log(requestId,"requestId")
//   return new Promise((resolve, reject) => {
//     makePostRequest(
//       `${BASE_URL}/resort/notification/single/read`,
//       true,
//       payloadNoti
//     )
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((e) => {
//         console.log("API call error>>", e);
//         reject(e);
//       });
//   });
// };


export const readNotification = (requestId) => {
  const payloadNoti={notification_id:`${requestId}`}
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/resort/notification/single/read`, true, payloadNoti)
      .then((res) => {
        resolve(res);

      //  console.log(res,"readNotification")
        const resortId = localStorage.getItem("resortID");
       // console.log(resortId,"resortIdbbbbbbbbbbbb")
        
        getUnreadNotificationsCount(resortId)
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};
// Resort get unread Notifications count
export const getUnreadNotificationsCount = (resortId) => {
  //localStorage.setItem("resortId", resortId);
  //console.log("binku",resortId)
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `${BASE_URL}/resort/notifications/unread/count`,
      true,
      null,
      resortId
    )
      .then((res) => {
        resolve(res);
        //console.log(res,"getUnreadNotificationsCount")
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};


// e-butler service

export const eButlerService = (payload) => {
 
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/ebutler/service/enable`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const eButlerServiceGetData = (payload) => {
 
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/ebutler/service/enable/disable`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};




/**
 * ResortAdmin getServiceGraphData
 *
 * @param {object} payload
 * @param {date} payload.startDate // startDate "DD/MM/YYYY" - "12/06/2022"
 * @param {date} payload.endDate // endDate "DD/MM/YYYY" - "13/06/2022"
 * @param {string} payload.groupByUnit // enum = ["year", "month", "week", "day"]
 * @returns
 */
export const getServiceGraphData = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/resort/dashboard/service-graph`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

/**
 * ResortAdmin getGuestGraphData
 *
 * @param {object} payload
 * @param {date} payload.startDate // startDate "DD/MM/YYYY" - "12/06/2022"
 * @param {date} payload.endDate // endDate "DD/MM/YYYY" - "13/06/2022"
 * @param {string} payload.groupByUnit // enum = ["year", "month", "week", "day"]
 * @returns
 */
export const getGuestGraphData = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/resort/dashboard/guest-graph`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

/**
 * ResortAdmin getFeedGraphData
 *
 * @param {object} payload
 * @param {date} payload.startDate // startDate "DD/MM/YYYY" - "12/06/2022"
 * @param {date} payload.endDate // endDate "DD/MM/YYYY" - "13/06/2022"
 * @param {string} payload.groupByUnit // enum = ["year", "month", "week", "day"]
 * @returns
 */
export const getFeedGraphData = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/resort/dashboard/feed-graph`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

/**
 * ResortAdmin getOfferGraphData
 *
 * @param {object} payload
 * @param {date} payload.startDate // startDate "DD/MM/YYYY" - "12/06/2022"
 * @param {date} payload.endDate // endDate "DD/MM/YYYY" - "13/06/2022"
 * @param {string} payload.groupByUnit // enum = ["year", "month", "week", "day"]
 * @returns
 */
export const getOfferGraphData = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/resort/dashboard/offer-graph`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

/**
 * ResortAdmin getEButlerGraphData
 *
 * @param {object} payload
 * @param {date} payload.startDate // startDate "DD/MM/YYYY" - "12/06/2022"
 * @param {date} payload.endDate // endDate "DD/MM/YYYY" - "13/06/2022"
 * @param {string} payload.groupByUnit // enum = ["year", "month", "week", "day"]
 * @returns
 */
export const getEButlerGraphData = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/resort/dashboard/ebutler-graph`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};
