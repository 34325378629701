import React from "react";
import { Route, Redirect } from "react-router-dom";
import {
  isAuthenticatedAndHasCard,
  isAuthenticatedHasPermissionAndCard,
} from "../guards/auth-guard";
import { store } from "../redux/store";

const ProtectedPermissionRoute = ({ component: Component, ...rest }) => {
  const state = store.getState();

  return (
    // <Route
    //   {...rest}
    //   render={(props) => {
    //     console.log("restvibha--------------",rest)
    //     console.log("propsPPPPPPPPPPPPPPPPPPPPPPPP",props)
    //     if (
    //       (!rest.isCheckPermission && isAuthenticatedAndHasCard()) ||
    //       (rest.isCheckPermission &&
    //         isAuthenticatedHasPermissionAndCard(rest.permissionName))
    //     ) {
    //       console.log("parrot*****------ has card---------------*******")
    //       return <Component {...props} />;
    //     } else {
    //       console.log("pat2******------------*** no card***-----------***")
    //       return (
    //         // <Redirect
    //         //   to={{
    //         //     pathname: state?.userCredential?.user?.isCardAdded
    //         //       ? rest.redirectRoute
    //         //       : "/subscription",
    //         //     extras: { ...rest.location },
    //         //   }}
    //         // />
    //         <Redirect
    //           to={{
    //             pathname: "/amenities"
    //           }}
    //         />
    //       );
    //     }
    //   }}
    // />

<Route
      {...rest}
      render={(props) => {
       // console.log("restvibha--------------",rest)
       // console.log("propsPPPPPPPPPPPPPPPPPPPPPPPP",props)
        if (
          (!rest.isCheckPermission) ||
          (rest.isCheckPermission )
        ) {
         // console.log("parrot*****------ has card---------------*******")
          return <Component {...props} />;
        } else {
         // console.log("pat2******------------*** no card***-----------***")
          return (
            // <Redirect
            //   to={{
            //     pathname: state?.userCredential?.user?.isCardAdded
            //       ? rest.redirectRoute
            //       : "/subscription",
            //     extras: { ...rest.location },
            //   }}
            // />
            <Redirect
              to={{
                pathname: "/login"
              }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedPermissionRoute;
