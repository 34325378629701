import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Label,
  FormGroup,
  Input,
  Row,
  Card,
  CustomInput,
  Spinner,
} from "reactstrap";
import { InputGroup, FormControl } from "react-bootstrap";
import AddOfferModal from "../../components/Modals/add-offer-modal";
import {
  getAllGroups,
  getAllOffers,
  getAmenitiesList,
  updateOffer,
} from "../../http/http-calls";
import { capitalize, showToast } from "../../helper-methods";
import ReactPaginate from "react-paginate";
import { DEFAULT_PROFILE_PICTURE } from "../../config";
import ReadMoreAndLess from "react-read-more-less";
import { useSelector } from "react-redux";
import LanguageParsedText, {
  getValueFromCurrentLang,
} from "../../multi-lang/lang-parsed-text/LanguageParsedText";

let searchInterval;

const OffersPage = () => {
  const userCredential = useSelector((state) => state["userCredential"]);

  const [addOfferModal, setAddOfferModal] = useState({
    isOpen: false,
    data: null,
  });

  const [amenityArray, setAmenityArray] = useState([]);
  const [groups, setGroups] = useState([]);

  const [filters, setFilters] = useState({
    status: "",
    _amenity: "",
  });
  const [getAllOffersPayload, setGetAllOffersPayload] = useState({
    filters: {},
    search: "",
    skip: 0,
    pageNumber: 0,
    limit: 8,
  });
  const [offers, setOffers] = useState([]);
  const [offersCount, setOffersCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [isActiveLoading, setIsActiveLoading] = useState(false);
  const [filtersLoading, setFiltersLoading] = useState(false);

  const _toggleAddOfferModal = (isOpen = false, data = null) => {
    setAddOfferModal({
      isOpen,
      data,
    });
  };

  const _getAmenitiesList = () => {
    getAmenitiesList()
      .then((res) => {
        setAmenityArray(res.amenity?.length ? res.amenity : []);
      })
      .catch((error) => {
        console.log("error>>", error);
        showToast(
          error?.reason?.length
            ? error.reason
            : getValueFromCurrentLang("pages.General.serverError"),
          "error"
        );
      });
  };

  const _getAllGroups = () => {
    getAllGroups()
      .then((res) => {
        setGroups(res.groups?.length ? res.groups : []);
      })
      .catch((error) => {
        console.log("error>>", error);
        showToast(
          error?.reason?.length
            ? error.reason
            : getValueFromCurrentLang("pages.General.serverError"),
          "error"
        );
      });
  };

  const _getAllOffers = (payload) => {
    setLoading(true);

    getAllOffers(payload)
      .then((res) => {
        setOffers(res.offers);
        setOffersCount(res.offersCount);
        setLoading(false);
        setSearchLoading(false);
        setFiltersLoading(false);
      })
      .catch((error) => {
        console.log("error>>", error);
        setLoading(false);
        setSearchLoading(false);
        setFiltersLoading(false);
        showToast(
          error?.reason?.length
            ? error.reason
            : getValueFromCurrentLang("pages.General.serverError"),
          "error"
        );
      });
  };
  console.log(offers,"offers")
  useEffect(() => {
    if (userCredential?.selectedResort?._id) {
      _getAmenitiesList();
      _getAllGroups();
      _getAllOffers(getAllOffersPayload);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCredential?.selectedResort?._id]);
//console.log(userCredential?.selectedResort?._id,"userCredential?.selectedResort?._id")
  const _toggleOfferStatus = (id, value) => {
    setIsActiveLoading(id);

    const newOffers = [...offers];
    //console.log(newOffers,"newOffer")
    const findOffer = newOffers.find((offer) => offer._id === id);
    //console.log(findOffer,"findOffer")
    if (!findOffer) {
      showToast(
        getValueFromCurrentLang("pages.General.somethingWentWrong"),
        "error"
      );
      return;
    }
    findOffer["isActive"] = value;

    setOffers(newOffers);

    updateOffer(id, { isActive: value })
      .then((res) => {
        setIsActiveLoading(false);
        showToast(
          value ? "Activated successfully" : "Deactivated successfully",
          "success"
        );
      })
      .catch((error) => {
        console.log("error>>", error);
        setIsActiveLoading(false);
        _getAllOffers(getAllOffersPayload);
        showToast(
          error?.reason?.length
            ? error.reason
            : getValueFromCurrentLang("pages.General.serverError"),
          "error"
        );
      });
  };

  const _onPageChange = (pageNumber = 0) => {
    const newGetAllOffersPayload = { ...getAllOffersPayload };
    newGetAllOffersPayload["skip"] =
      pageNumber * newGetAllOffersPayload["limit"];
    newGetAllOffersPayload["pageNumber"] = pageNumber;
    setGetAllOffersPayload(newGetAllOffersPayload);
    _getAllOffers(newGetAllOffersPayload);
  };

  const _onChangeSearch = (searchValue) => {
    clearInterval(searchInterval);
    setSearchLoading(true);
    const newGetAllOffersPayload = { ...getAllOffersPayload };
    newGetAllOffersPayload["search"] = searchValue;
    newGetAllOffersPayload["skip"] = 0;
    newGetAllOffersPayload["pageNumber"] = 0;
    setGetAllOffersPayload(newGetAllOffersPayload);
    searchInterval = setTimeout(() => {
      _getAllOffers(newGetAllOffersPayload);
    }, 1000);
  };

  const _clearFilters = () => {
    setFiltersLoading(true);

    const newFilters = { ...filters };
    Object.keys(newFilters).forEach((key) => {
      newFilters[key] = "";
    });
    setFilters(newFilters);

    const newGetAllOffersPayload = { ...getAllOffersPayload };
    newGetAllOffersPayload["search"] = "";
    newGetAllOffersPayload["skip"] = 0;
    newGetAllOffersPayload["pageNumber"] = 0;
    newGetAllOffersPayload["filters"] = {};
    setGetAllOffersPayload(newGetAllOffersPayload);

    _getAllOffers(newGetAllOffersPayload);
  };

  const _onChangeFilters = (key, value) => {
    setFiltersLoading(true);

    const newFilters = { ...filters };
    newFilters[key] = value;
    setFilters(newFilters);

    const newGetAllOffersPayload = { ...getAllOffersPayload };
    newGetAllOffersPayload["skip"] = 0;
    newGetAllOffersPayload["pageNumber"] = 0;
    newGetAllOffersPayload["filters"] = {};

    Object.keys(newFilters).forEach((eachFilterKey) => {
      if (eachFilterKey === "status" && newFilters[eachFilterKey]) {
        newGetAllOffersPayload.filters["isActive"] =
          newFilters[eachFilterKey] === "active" ? true : false;
      } else {
        if (newFilters[eachFilterKey])
          newGetAllOffersPayload.filters[eachFilterKey] =
            newFilters[eachFilterKey];
      }
    });

    setGetAllOffersPayload(newGetAllOffersPayload);

    _getAllOffers(newGetAllOffersPayload);
  };

  return (
    <div className="content">
      <Container fluid className="mobilePadding-0">
        {/* ===== Page Title ===== */}
        <div className="page_Inner_header">
          <Row className="align-items-center no-margin">
            <Col xs={6} sm={8} className="mobilePadding-0">
              <div className="page_title">
                <h1 className="space_remove">
                  <LanguageParsedText keyString="pages.SpecialOffers.specialOffers" />{" "}
                  {loading || filtersLoading ? <Spinner /> : null}
                </h1>
              </div>
            </Col>
            <Col xs={6} sm={4} className="text-right">
              <Button
                onClick={() => _toggleAddOfferModal(true)}
                className="btn btn-primary">
                <LanguageParsedText keyString="pages.SpecialOffers.createOffer" />
              </Button>
            </Col>
          </Row>
        </div>
        {/* ======== Filter ======= */}
        <Card body className="search-filter pt-2 pb-3 mb-4">
          <Row>
            <Col xs={6} md={3} lg={2}>
              <FormGroup className="mb-0">
                <Label>
                  <LanguageParsedText keyString="pages.General.status" />
                </Label>
                <Input
                  type="select"
                  name="status"
                  value={filters.status}
                  onChange={(e) => _onChangeFilters("status", e.target.value)}
                >
                  <option value="">
                    {getValueFromCurrentLang("pages.General.all")}
                  </option>
                  <option value="active">
                    {getValueFromCurrentLang("pages.General.active")}
                  </option>
                  <option value="inactive">
                    {getValueFromCurrentLang("pages.General.inactive")}
                  </option>
                </Input>
              </FormGroup>
            </Col>
            <Col xs={6} md={3} lg={2}>
              <FormGroup className="mb-0 ">
                <Label for="request">
                  <LanguageParsedText keyString="pages.General.amenity" />
                </Label>
                <Input
                  type="select"
                  name="_amenity"
                  value={filters._amenity}
                  onChange={(e) =>
                    _onChangeFilters("_amenity", e.target.value)
                  }
                >
                  <option value="">
                    {getValueFromCurrentLang("pages.General.all")}
                  </option>
                  {amenityArray.map(
                    (each) =>
                      (each.id || each._id) &&
                      each.name && (
                        <option
                          key={each.id || each._id}
                          value={each.id || each._id}
                        >
                          {capitalize(each.name)}
                        </option>
                      )
                  )}
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <div className="mt-3 mt-md-0 d-flex justify-content-md-end align-items-end">
                <FormGroup className="mb-0">
                  <Label>
                    <LanguageParsedText keyString="pages.General.searchByOfferName" />
                  </Label>
                  <InputGroup>
                    <FormControl
                      type="text"
                      placeholder={getValueFromCurrentLang(
                        "pages.General.enter"
                      )}
                      autoComplete="off"
                      name="firstName"
                      value={getAllOffersPayload.search}
                      onChange={(e) => _onChangeSearch(e.target.value)}
                    />
                    <InputGroup.Text id="basic-addon2">
                      {searchLoading ? (
                        <i className="fa fa-spinner fa-spin" />
                      ) : getAllOffersPayload.search ? (
                        <i
                          className="fas fa-times"
                          onClick={() => _onChangeSearch("")}
                        />
                      ) : (
                        <i className="fas fa-search" />
                      )}
                    </InputGroup.Text>
                  </InputGroup>
                </FormGroup>
                <Button
                  color="primary"
                  className="btn-clear ml-4"
                  onClick={() => _clearFilters()}
                >
                  <i className="fa fa-eraser fs-15" />
                </Button>
              </div>
            </Col>
          </Row>
        </Card>

        <div className="mt-4  main_inner gridBox bg-white">
          <div className="userList">
            <Row className="text-center">
              {offers.length ? (
                offers.map((offer) => (
                  <Col xl="3" lg="4" md="4" sm="6" xs="12" key={offer.id}>
                    <Card>
                      <div className="user_box">
                        <div className="user_image">
                          <img
                            src={
                              offer.offerImage
                                ? offer.offerImage
                                : DEFAULT_PROFILE_PICTURE
                            }
                            alt="offer cover"
                            loading="lazy"
                          />
                          <div className="switch_box">
                            <CustomInput
                              type="switch"
                              id={`offer_page_custom_switch_${offer._id}`}
                              disabled={isActiveLoading === offer._id}
                              checked={offer.isActive}
                              onChange={(e) =>
                                _toggleOfferStatus(offer._id, e.target.checked)
                              }
                            />
                          </div>
                        </div>
                        <div className="user_content">
                          <h1>
                            {offer.name ? capitalize(offer.name) : "N/A"}
                            <span>
                              <i
                                title="Update Offer"
                                className="fas fa-pencil-alt edit"
                                onClick={() =>
                                  _toggleAddOfferModal(true, offer)
                                }
                              />
                            </span>
                          </h1>
                          {offer.description ? (
                            <ReadMoreAndLess
                              className="read-more-content"
                              charLimit={40}
                              readMoreText={` ${getValueFromCurrentLang(
                                "pages.General.readmore"
                              )}`}
                              readLessText={` ${getValueFromCurrentLang(
                                "pages.General.readless"
                              )}`}
                            >
                              {offer.description}
                            </ReadMoreAndLess>
                          ) : null}
                        </div>
                      </div>
                    </Card>
                  </Col>
                ))
              ) : (
                <Col sm="12">
                  {loading ? (
                    <Spinner />
                  ) : (
                    <LanguageParsedText keyString="pages.General.noData" />
                  )}
                </Col>
              )}
            </Row>
          </div>
        </div>

        {offers?.length && offersCount ? (
          <div className="row mt-4">
            <div className="col-md-6">
              <span className="showng_entries">
                <LanguageParsedText
                  keyString="pages.General.showingEntriesOutOf"
                  showCount={offers?.length}
                  totalCount={offersCount}
                />
              </span>
            </div>
            <div className="col-md-6">
              <ReactPaginate
                forcePage={getAllOffersPayload.pageNumber}
                className="pagination justify-content-end mb-2"
                breakLabel="..."
                nextLabel={<i className="fa fa-chevron-right" />}
                previousLabel={<i className="fa fa-chevron-left" />}
                previousClassName="previous"
                nextClassName="next"
                onPageChange={({ selected }) => _onPageChange(selected)}
                pageRangeDisplayed={3}
                pageCount={Math.ceil(offersCount / getAllOffersPayload.limit)}
                pageClassName="page-item"
                renderOnZeroPageCount={null}
                containerClassName={"pagination"}
                previousLinkClassName={"page-link"}
                nextLinkClassName={"page-link"}
                activeClassName={"active"}
                disabledClassName={"page-item"}
              />
            </div>
          </div>
        ) : null}
      </Container>

      <AddOfferModal
        isOpen={addOfferModal.isOpen}
        data={addOfferModal.data}
        toggle={() => _toggleAddOfferModal()}
        amenityArray={amenityArray}
        groups={groups}
        getAllOffers={() => _onPageChange()}
        resetCurrentData={() => _getAllOffers(getAllOffersPayload)}
      />
    </div>
  );
};

export default OffersPage;
