export const en = {
  pages: {
    Login: {
      login: "Login",
      forgotPassword: "Forgot Password?",
      continueWith: "Continue with",
      backToLogin: "Back to Login",
      registerResort: "Register Property",
      registerYourResort: "Register Your Property",
      contactPersonDetails: "Contact Person Details",
    },
    TeamMembers: {
      teamMembers: "Team Members",
      assignment: "Assignment",
      permissions: "Permissions",
      createNew: "Create New",
      home: "Home",
      company: "Company",
      portfolio: "Portfolio",
      blog: "Blog",
    },
    CreateTeamMember: {
      teamMember: "Team Member",
      location: "Location",
      enterTheLocation: "Enter the location",
      permissions: "Permissions",
      assignTo: "Assign to",
      complementaryServices: "Complimentary Services",
      amenities: "Amenities",
    },
    Resorts: {
      resorts: "Properties",
      roomCount: "Room Count",
      plan: "Plan",
      location: "Location",
      verification: "Verification",
      basic: "Basic",
      plus: "Plus",
      premium: "Premium",
      pending: "Pending",
      accepted: "Accepted",
      enter: "Enter",
      addResort: "Add Property",
      resort: "Property",
      feed: "Feed",
      rooms: "Rooms",
      addedOn: "Added On",
      poc: "POC",
      amenities: "Amenities",
      staff: "Staff",
      offers: "Offers",
      request: "Request",
      comments: "Comments",
      subject: "Subject",
      enterTheSubject: "Enter the subject",
      normal: "Normal",
      enterYourMessageHere: "Enter your message here.",
      send: "Send",
      propertyProfile: "Property Profile",
      numberOfRooms: "Number of Rooms",
      checkInTime: "Check In Time",
      checkOutTime: "Check Out Time",
      selectCheckInTime: "Select check in time",
      selectCheckOutTime: "Select check out time",
      enterTheNumberOfRooms: "Enter the number of guest rooms at your property",
      enterYourResortName: "Enter your property name",
      resortAddress: "Property Address",
      city: "City",
      country: "Country",
      state: "State",
      zipcode: "Zipcode",
      enterYourResortAddress: "Enter your property address",
      enterTheCity: "Enter the city",
      selectState: "Select State",
      enterTheState: "Enter the state",
      enterTheZipcode: "Enter the zipcode",
      commentsIfAny: "Comments If Any",
      enterTheComments: "Enter the comments",
      resortName: "Property Name",
      resortDetails: "Property Details",
    },
    Vacationers: {
      vacationers: "Vacationers",
      trips: "Trips",
      outings: "Outings",
      request: "Request",
      lastActive: "Last Active",
      subject: "Subject",
      enterTheSubject: "Enter the subject",
      normal: "Normal",
      enterYourMessageHere: "Enter your message here.",
      send: "Send",
    },
    Outings: {
      outings: "Outings",
      resort: "Resort",
      pax: "PAX",
      dateRange: "Date Range",
      discount: "Discount",
      booked: "Booked",
      completed: "Completed",
      cancelledByUser: "Cancelled by User",
      cancelledByOperator: "Cancelled by Operator",
      enter: "Enter",
      vacationer: "Vacationer",
      location: "Location",
      amount: "Amount",
      date: "Date",
      invoice: "Invoice",
    },
    KPIs: {
      dashboard: "Dashboard",
      activeRegisteredResorts: "Active Registered Resorts",
      activeUsers: "Active Users",
      rooms: "Rooms",
      notGiven: "Not Given",
      selectPeriod: "Select Period",
      outings: "Outings",
      usingChat: "Using Chat",
      pageViews: "Page Views",
      clickThroughRate: "Click Through Rate",
      bookingResortServices: "Booking Resort Services",
      booking: "Booking",
      service: "Service",
      activeUsersBookingEConcierge: "Active Users Booking eConcierge",
      serviceReservations: "Service Reservations",
      reservations: "Reservations",
      guestDemographics: "Guest Demographics",
      overThePastWeek: "Over the past week",
      guestMakingReservation: "Guests Making Reservation",
      restaurants: "Restaurants",
      spa: "Spa",
      kidsClub: "Kids Club",
      services: "Services",
      serviceBreakdown: "Service Breakdown",
      guestAge: "Guest Age",
      specialOffers: "Special Offers",
      totalClicks: "Total Click",
      clickRate: "Click Rate",
      topOffers: "Top Offers",
      datePosted: "Date Posted",
      feedPerformance: "Feed Performance",
      guestViews: "Guest Views",
      guestLikes: "Guest Likes",
      topFeedPosts: "Top Feed Posts",
      totalLikes: "Total Likes",
      views: "Views",
      likes: "Likes",
      feedPosts: "Feed Posts",
      eButler: "eButler",
      guestUsingEConcierge: "Guests Using eConcierge",
      frontDesk: "Front Desk",
      houseKeeping: "House keeping",
      conciergeDesk: "Concierge Desk",
      inRoomDining: "In-Room Dining",
      eConciergeResponseTime: "eConcierge Response Time",
      averageResponseTime: "Average Response Time",
    },
    Profile: {
      profile: "Profile",
      userProfile: "User Profile",
      enterYourPhoneNumber: "Enter your phone number",
      rolesAndPermissions: "Roles and Permissions",
    },
    Settings: {
      settings: "Settings",
      complementaryServices: "Complimentary Services",
      frontDesk: "Front Desk",
      addFrontDesk: "Add front desk",
      houseKeeping: "House keeping",
      addHouseKeeping: "Add House keeping",
      concierge: "Concierge",
      addConcierge: "Add Concierge",
      inRoomDining: "In-Room Dining",
      addInRoomDining: "Add In-Room Dining",
      update: "Update",
      reset: "Reset",
      amenityCategories: "Amenity Categories",
      label: "Label",
      category: "Category",
      add: "Add",
      fitness: "Fitness",
      restaurants: "Restaurants",
      spa: "Spa",
      pools: "Pools",
      kidsClub: "Kids Club",
    },
    Sidebar: {
      dashboard: "Dashboard",
      guestRequests: "Guest Requests",
      guestGroups: "Guest Groups",
      specialOffers: "Special Offers",
      propertyAmenities: "Property Amenities",
      propertyProfile: "Property Profile",
      teamMembers: "Team Members",
      billing: "Billing",
      userProfile: "User Profile",
    },
    General: {
      somethingWentWrong: "Something went wrong, Try again soon.",
      serverError: "Server error. Try again soon.",
      required: "Required",
      invalid: "Invalid",
      logout: "Logout",
      name: "Name",
      phone: "Phone",
      phoneNumber: "Phone Number",
      username: "Username",
      enterTheUsername: "Enter the username",
      email: "Email",
      password: "Password",
      addResort: "Add Resort",
      seeMore: "See More",
      showingEntriesOutOf:
        "Showing <<showCount>> entries out of <<totalCount>>",
        redColorForCancelRequest:"Red Message indicates that the request has been cancelled.",
      noData: "There is no data to display.",
      all: "All",
      enter: "Enter",
      active: "Active",
      inactive: "Inactive",
      create: "Create",
      update: "Update",
      reset: "Reset",
      edit: "Edit",
      delete: "Delete",
      remove: "Remove",
      save: "Save",
      dismiss: "Dismiss",
      cancel: "Cancel",
      add: "Add",
      replace: "Replace",
      upload: "Upload",
      uploadLogo: "Upload Logo",
      uploadPhotos: "Upload Photos",
      enterYourName: "Enter your name",
      enterTheName: "Enter the name",
      enterYourPhoneNumber: "Enter your phone number",
      enterThePhoneNumber: "Enter the phone number",
      enterYourEmail: "Enter your email",
      enterTheEmail: "Enter the email",
      enterYourPassword: "Enter your password",
      enterThePassword: "Enter the password",
      selectAll: "Select All",
      select: "Select",
      searchByName: "Search By Name",
      searchByOfferName:"Search By Offer Name",
      status: "Status",
      settings: "Settings",
      createNew: "Create New",
      action: "Action",
      typeYourMessageHere: "Type your message here...",
      archive: "Archive",
      download: "Download",
      print: "Print",
      startTime: "Start Time",
      endTime: "End Time",
      startDate: "Start Date",
      endDate: "End Date",
      selectTheStartDate: "Select the start date",
      selectTheEndDate: "Select the end date",
      startDateAndTime: "Start Date & Time",
      endDateAndTime: "End Date & Time",
      selectTheStartDateAndTime: "Select the start date & Time",
      selectTheEndDateAndTime: "Select the end date & Time",
      description: "Description",
      enterTheDescription: "Enter the description",
      checkInCheckOut: "Check In - Check out",
      group: "Group",
      coverPhoto: "Cover Photo",
      makeCover: "Make Cover",
      amenity: "Amenity",
      readmore: "Read more",
      readless: "Read less",
      sendEmail: "Send Email",
      notifications: "Notifications",
      language: "Language",
      afterResortRegisterReviewNote:
        "A <<appName>> Account Executive will review your submission and contact you shortly to help you get started and answer any additional questions you may have.",
      areYouSure: "Are you sure?",
      yesDeleteIt: "Yes, delete it!",
      yesRemoveIt: "Yes, remove it!",
      yesArchiveIt: "Yes, archive it!",
      youWillNotRecoverIt: "You will not be able to recover it!",
    },
    Billings: {
      billing: "Billing",
      plan: "Plan",
      billingPeriod: "Billing Period",
      numberOfRooms: "# of Rooms",
      amount: "Amount",
      dueDate: "Due Date",
      date: "Date",
      pay: "Pay",
      processing: "Processing",
    },
    GuestRequests: {
      guestRequests: "Guest Requests",
      vacationer: "Vacationer",
      serviceCategory: "Service Category",
      assignee: "Assignee",
      lastUpdate: "Last Update",
      roomNumber: "Room #",
      groupName: "Group Name",
      requestStatus: "Request Status",
      viewRequest: "View Request",
      checkIn: "Check In",
      checkOut: "Check Out",
      serviceDate: "Service Date",
      serviceTime: "Service Time",
      totalGuest: "Total Guest",
      selectTime: "Select Time",
      enterTheComment: "Enter the comment",
      accept: "Accept",
      decline: "Decline",
      hasCreatedNewRequest: "<<name>> has created a new request for",
      selectAssignee: "Select Assignee",
    },
    GuestGroups: {
      guestGroups: "Guest Groups",
      allMembers: "All Members",
      createGroup: "Create Group",
      noMember: "No member found in this group",
      enterTheGroupName: "Enter the group name",
      uploadGroupIcon: "Upload Group Icon",
      QRCodeWillCreateAutomatically:
        "*QR Code for group will be created automatically",
    },
    SpecialOffers: {
      specialOffers: "Special Offers",
      createOffer: "Create Offer",
      offer: "Offer",
      enterTheOfferName: "Enter the offer name",
      selectAmenity: "Select Amenity",
      showOfferToVacationer: "Show offer to Vacationer?",
      notInAdvance: "Not In Advance",
      groups: "Groups",
      uploadOfferIcon: "Upload Offer Icon",
    },
    CreateAmenity: {
      amenityDetails: "Amenity Details",
      enterTheAmenityName: "Enter the amenity name",
      selectCategory: "Select Category",
      acceptBookingRequests: "Accept Booking Requests",
      serviceMenu: "Service Menu",
      enterTheAmenityAddress: "Enter the amenity address",
      mapOfTheProperty: "Map of the property",
      availability: "Booking Request Time Slots",
      frequentlyAskedQuestions: "Frequently Asked Questions",
      question: "Question",
      answer: "Answer",
      addMore: "Add More",
    },
  },
  components: {},
};

export default en;
