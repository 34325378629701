import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Media,
  Table,
  Spinner,
} from "reactstrap";
import { capitalize, showToast } from "../../helper-methods/index1";
import { getAllPreviousRequest,getInvitedUserByBookingId } from "../../http/http-calls";
import ReactPaginate from "react-paginate";
import { DEFAULT_PROFILE_PICTURE } from "../../config";
import { requestStatusConfig } from "../../config/helper-config";
import LanguageParsedText, {
  getValueFromCurrentLang,
} from "../../multi-lang/lang-parsed-text/LanguageParsedText";

const RequestStatusModal = ({ isOpen, toggle, data ,id }) => {
  const [previousRequestsPayload, setPreviousRequestsPayload] = useState({
    // skip: 0,
    // pageNumber: 0,
    // limit: 5,
    booking_id: "",
  });
  const [previousRequests, setPreviousRequest] = useState([]);
  const [previousRequestsCount, setPreviousRequestsCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const _resetModalState = () => {
    setPreviousRequestsPayload({
      // skip: 0,
      // pageNumber: 0,
      // limit: 5,
      booking_id: "",
    });
    setPreviousRequest([]);
    setPreviousRequestsCount(0);
    setLoading(false);
  };

  const _closeModal = () => {
    _resetModalState();
    toggle();
  };
  
  // const _getInvitedUserByBookingId = (payload) => {
  //   setLoading(true);
  //   getInvitedUserByBookingId(payload)
  //     .then((res) => {
  //       console.log(res,"res")
  //       // setRequests(res.bookings?.length ? res.bookings : []);
  //       // setRequestsCount(res.count || 0);
  //       // setLoading(false);
  //       // setSearchLoading(false);
  //       // setFiltersLoading(false);
  //       // setStatusLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log("error>>", error);
  //       setLoading(false);
  //       setSearchLoading(false);
  //       setFiltersLoading(false);
  //       setStatusLoading(false);
  //       showToast(
  //         error?.reason?.length
  //           ? error.reason
  //           : getValueFromCurrentLang("pages.General.serverError"),
  //         "error"
  //       );
  //     });
   
  // };

  const _getInvitedUserByBookingId = (payload) => {
   // console.log("jacajkkkkkkkk")
    setLoading(true);
    getInvitedUserByBookingId(payload)
      .then((res) => {
        //console.log(res.data,"dataresponse vibha")
        setPreviousRequest(res.data ? res.data : []);
        setPreviousRequestsCount(res.count || 0);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error>>", error);
        _closeModal();
        showToast(
          error?.reason?.length
            ? error.reason
            : getValueFromCurrentLang("pages.General.serverError"),
          "error"
        );
      });
  };

  // const _onPageChange = (pageNumber = 0) => {
  //   const newPreviousRequestsPayload = { ...previousRequestsPayload };
  //   newPreviousRequestsPayload["skip"] =
  //     pageNumber * newPreviousRequestsPayload["limit"];
  //   newPreviousRequestsPayload["pageNumber"] = pageNumber;
  //   setPreviousRequestsPayload(newPreviousRequestsPayload);
  //  // _getAllPreviousRequest(newPreviousRequestsPayload);
  //   _getInvitedUserByBookingId(newPreviousRequestsPayload)

  // };

  useEffect(() => {
    if (isOpen && id) {
     // console.log("yessss",id)
      const newPreviousRequestsPayload = { ...previousRequestsPayload };
       newPreviousRequestsPayload["booking_id"] = id;
       setPreviousRequestsPayload(newPreviousRequestsPayload);
      // //_getAllPreviousRequest(newPreviousRequestsPayload);
       _getInvitedUserByBookingId(newPreviousRequestsPayload);
    } else {
      _closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, data]);
  useEffect(() => {

  }, [isOpen, data]);
  const _getStatusColor = (status) => {
    switch (status) {
      case "unassigned":
        return "accepted";
      case "pending":
        return "accepted";
      case "inprogress":
        return "accepted";
      case "completed":
        return "accepted";
      case "cancelled":
        return "accepted";
      default:
        return "";
    }
  };

  const _getStatusInFormat = (status) => {
    const findStatus = requestStatusConfig.find(
      (each) => each.value === status
    );
    if (findStatus) return findStatus.label;
    else return "N/A";
  };

  return (
    <Modal
      className="request_service"
      isOpen={isOpen}
      toggle={() => _closeModal()}
      centered
    >
      <ModalHeader toggle={() => _closeModal()} className="text-center">
      Requests Status {loading ? <Spinner /> : null}
      </ModalHeader>
      <ModalBody className="p-0">
        {/* ======== All Members ======== */}
        <div className="all-members-chat">
          <div className="all-members-chat-container ">
            <div className="all-members-chat-body">
              <p style={{textAlign:"center"}}>Total members in a group: <span style={{color: "green"}}>{previousRequests?.totalGuests}</span></p>
              <Table className="table-responsive-xl">
                <thead>
                  <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Status</th>
                  </tr>
                </thead>
                {/* <tbody>
                {  console.log(previousRequests,"kkkkkkkkvibhakkkkkkkkkk")}
                  {previousRequests!==[] ? (
                      <tr key={previousRequests._id}>
                        <td>
                          {previousRequests.first}
                        </td>
                       
                        <td>
                        {previousRequests.last}
                        </td>
                        <td>
                          {(previousRequests.isAccepted==true) ? (
                          <div style={{color: "green"}}> Accepted</div>
                          ):!(previousRequests.isAccepted) ?(
                            <div style={{color: "red"}}> Pending</div>
                          ):(previousRequests.isAccepted==false)("decline")}
                        </td>
                      </tr>
                  
                  ) : (
                    <tr className="text-center">
                      <td colSpan="9">
                        {loading ? (
                          <Spinner />
                        ) : (
                          <LanguageParsedText keyString="pages.General.noData" />
                        )}
                      </td>
                    </tr>
                  )}
                </tbody> */}
                <tbody>
                  {
                  console.log(previousRequests?.totalGuests
,"previousRequests.invitedMember")}
                  {previousRequests?.invitedMember?.length ? (
                    previousRequests?.invitedMember.map((request) => (
                      <tr key={request.id}>
                       <td>
                          {request.first}
                        </td>
                       
                        <td>
                        {request.last}
                        </td>
                        <td>
                          {(request.isAccepted===true) ? (
                          <div style={{color: "green"}}> Accepted</div>
                          ):(request.isAccepted===false)?( <div style={{color: "black"}}> decline</div>):!(request.isAccepted) ?(
                            <div style={{color: "red"}}> Pending</div>
                          ):null}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="text-center">
                      <td colSpan="9">
                        {loading ? (
                          <Spinner />
                        ) : (
                          <LanguageParsedText keyString="pages.General.noData" />
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>

              </Table>

              {/* ====== Pagination ====== */}
              {previousRequests?.length && previousRequestsCount ? (
                <div className="row mt-4">
                  <div className="col-md-6">
                    <span className="showng_entries">
                      <LanguageParsedText
                        keyString="pages.General.showingEntriesOutOf"
                        showCount={previousRequests?.length}
                        totalCount={previousRequestsCount}
                      />
                    </span>
                  </div>
                  <div className="col-md-6">
                    {/* <ReactPaginate
                      forcePage={previousRequestsPayload.pageNumber}
                      className="pagination justify-content-end mb-2"
                      breakLabel="..."
                      nextLabel={<i className="fa fa-chevron-right" />}
                      previousLabel={<i className="fa fa-chevron-left" />}
                      previousClassName="previous"
                      nextClassName="next"
                      onPageChange={({ selected }) => _onPageChange(selected)}
                      pageRangeDisplayed={3}
                      pageCount={Math.ceil(
                        previousRequestsCount / previousRequestsPayload.limit
                      )}
                      pageClassName="page-item"
                      renderOnZeroPageCount={null}
                      containerClassName={"pagination"}
                      previousLinkClassName={"page-link"}
                      nextLinkClassName={"page-link"}
                      activeClassName={"active"}
                      disabledClassName={"page-item"}
                    /> */}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center mt-5">
          <Button
            className="btn-primary-outline px-5 mr-3"
            onClick={() => _closeModal()}
          >
            Close
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default RequestStatusModal;
