import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Spinner ,Card,CardHeader,CardTitle,Button,CardBody,ListGroup,ListGroupItem} from "reactstrap";
import Payment from "../../components/payment";
import { APP_NAME } from "../../config";
import { subscriptionPricing } from "../../config/helper-config";
import { formatDate } from "../../helper-methods";
import { hasPermission } from "../../helper-methods/has-permission";
import { getAndUpdateUserData } from "../../redux/actions/user-credential";

const SubscriptionPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const userCredential = useSelector((state) => state["userCredential"]);

  const subscriptionPrice = useMemo(() => {
    const numberOfRooms = userCredential?.selectedResort?.numberOfRooms;

    if (numberOfRooms) {
      const findPriceSlab = subscriptionPricing.find(
        (each) =>
          (!each.rooms.max &&
            each.rooms.min &&
            each.rooms.min < numberOfRooms) ||
          (each.rooms.max &&
            each.rooms.min &&
            each.rooms.max >= numberOfRooms &&
            each.rooms.min <= numberOfRooms)
      );
//console.log(findPriceSlab,"findPriceSlab")
      return findPriceSlab?.monthlyPrice ? findPriceSlab.monthlyPrice : 0;
    }

    return 0;
  }, [userCredential?.selectedResort?.numberOfRooms]);

  const [loading, setLoading] = useState(false);

  const _onBuySubscription = () => {
    dispatch(getAndUpdateUserData());
  };

  useEffect(() => {
    if (userCredential?.selectedResort?.isCardAdded) {
      if (hasPermission("manageresort")) history.replace("/property-profile");
      else history.replace("/user-profile");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCredential?.selectedResort?.isCardAdded]);

  return (
    <>
      <div className="content">
        <Container fluid className="mobilePadding-0">
          <div className="page_Inner_header ">
            <Row className="align-items-center">
              <Col xs={6}>
                <div className="page_title">
                  <h1 className="space_remove">
                    Setup your Subscription {loading ? <Spinner /> : null}
                  </h1>
                </div>
              </Col>
            </Row>
          </div>
          <div className="plus_subscription justify-content-center">
            <div className="innerForm">
              <div className="plan_selected">
                Plan Selected:{" "}
                {subscriptionPrice ? (
                  <span>{subscriptionPrice} USD per month</span>
                ) : (
                  <div className="form-error">Something went wrong</div>
                )}
              </div>

              {userCredential?.user?.isSuper ? (
                <Payment
                  isManageLoading={true}
                  manageLoading={(value) => setLoading(value)}
                  cancelButton={false}
                  submitButtonText="Confirm"
                  onPaymentConfirmation={() => _onBuySubscription()}
                />
              ) : null}

              <div className="billingText">
                <p>
                  Next Billing On:{" "}
                  <span>{formatDate(moment().add(0, "days"))}</span>
                </p>
              </div>
              <div className="billingText">
                <p className="noteText">
                  <span>Note:</span> We want you to have time to get going with
                  the {APP_NAME} platform, so we won't charge your card for the
                  first 3 months.
                </p>
              </div>
            </div>

            {/* <Col md="6" lg="4">
              <Card className="subscriptionPlanWrap">
                <CardHeader>
                  <CardTitle tag="h5">Current Plan</CardTitle>
                  <Button
                    color="primary"
                    // onClick={() =>
                    //   _onToggleBuySubscriptionModal(true, {
                    //     plan: "basic",
                    //     type: "buy",
                    //   })
                    // }
                  >
                    Select
                  </Button>
                </CardHeader>
                <CardBody className="text-center">
                  <CardTitle tag="h3" className="customMarginSubscriptionTitle">
                    // Vayco
                  </CardTitle>
                  <p className="mt-3 mb-0">
                    <span>$50</span>/ month
                  </p>
                  <p className="mt-2 mb-0">
                    <span>$1.50</span> / closing
                  </p>
                  <p>50 Closings Included</p>

                  // don't remove the below text/tag. It's there for UI purpose 
                  <ListGroup className="planBenefits">
                    <ListGroupItem>
                      {" "}
                      <i className="fa fa-check"></i> Cras justo odio
                    </ListGroupItem>
                    <ListGroupItem>
                      <i className="fa fa-check"></i> Dapibus ac facilisis in
                    </ListGroupItem>
                    <ListGroupItem>
                      <i className="fa fa-check"></i> Morbi leo risus
                    </ListGroupItem>
                    <ListGroupItem>
                      {" "}
                      <i className="fa fa-check"></i> Porta ac consectetur ac
                    </ListGroupItem>
                    <ListGroupItem>
                      {" "}
                      <i className="fa fa-check"></i> Vestibulum at eros
                    </ListGroupItem>
                  </ListGroup>
                </CardBody>
              </Card>
            </Col>
            <Col md="6" lg="4">
              <Card className="subscriptionPlanWrap">
                <CardHeader>
                  <Button
                    color="primary"
                    // onClick={() =>
                    //   _onToggleBuySubscriptionModal(true, {
                    //     plan: "plus",
                    //     type: "buy",
                    //   })
                    // }
                  >
                    Select
                  </Button>
                </CardHeader>
                <CardBody className="text-center">
                  <CardTitle tag="h3" className="customMarginSubscriptionTitle">
                    // Vayco Plus
                  </CardTitle>
                  <p className="mt-3 mb-0">
                    <span>$50</span>/ month
                  </p>
                  <p className="mt-2 mb-0">
                    <span>$1.50</span> / closing
                  </p>
                  <p>50 Closings Included</p>
                  
                  // don't remove the below text/tag. It's there for UI purpose  
                  <ListGroup className="planBenefits">
                    <ListGroupItem>
                      {" "}
                      <i className="fa fa-check"></i> Cras justo odio
                    </ListGroupItem>
                    <ListGroupItem>
                      <i className="fa fa-check"></i> Dapibus ac facilisis in
                    </ListGroupItem>
                    <ListGroupItem>
                      <i className="fa fa-check"></i> Morbi leo risus
                    </ListGroupItem>
                    <ListGroupItem>
                      {" "}
                      <i className="fa fa-check"></i> Porta ac consectetur ac
                    </ListGroupItem>
                    <ListGroupItem>
                      {" "}
                      <i className="fa fa-check"></i> Vestibulum at eros
                    </ListGroupItem>
                  </ListGroup>
                </CardBody>
              </Card>
            </Col> */}
          </div>
        </Container>
      </div>
    </>
  );
};

export default SubscriptionPage;
