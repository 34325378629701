import React, { Component } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { Button } from "reactstrap";
import { SOCIAL_CREDENTIALS } from "../config";
import LanguageParsedText from "../multi-lang/lang-parsed-text/LanguageParsedText";

export default class Facebook extends Component {
  _responseFacebook = async (response) => {
    //console.log("pataloon")
    try {
      if (response && response.status !== "unknown") {
        this.props.response(response);
      }
    } catch (error) {
     console.log("error>>", error);
    }
  };

  _componentClicked = () => console.log("clicked");

  render() {
    const { btnText } = this.props;

    return (
      <>
        <FacebookLogin
          appId={SOCIAL_CREDENTIALS.facebookAppId}
          fields="name,email"
          onClick={this._componentClicked}
          callback={this._responseFacebook}
          render={(renderProps) => (
            <Button
              className={this.props.className}
              onClick={renderProps.onClick}
            >
              <img
                src={require("../assets/img/facebook.png").default}
                alt="Facebook"
                loading="lazy"
              />
              {btnText ? (
                btnText
              ) : (
                <>
                  <LanguageParsedText keyString="pages.Login.continueWith" />{" "}
                  Facebook
                </>
              )}
            </Button>
          )}
        />
      </>
    );
  }
}
