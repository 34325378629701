import React from "react";
import MultiLang from "..";
import { connect } from "react-redux";

const LanguageParsedText = (props) => {
  let finalString = "";
  // Get current key string
  const { keyString } = props;
  //console.log(props,"vibha")
  // Prepare dynamic variables
  let dynamicVariablesToBind = { ...props };
 // console.log(dynamicVariablesToBind,"dynamicVariablesToBind")
  // Delete keyString as it was in props
  delete dynamicVariablesToBind.keyString;

  const multiLang = new MultiLang();
  finalString = multiLang.getValueFromCurrentLang(
    keyString,
    dynamicVariablesToBind
  );
  return <>{finalString}</>;
};

const mapStateToProps = (state) => {
  return {
    languageData: state.languageData,
  };
};

export default connect(mapStateToProps, null)(LanguageParsedText);

export const getCurrentLang = () => {
  const multiLang = new MultiLang();
  return multiLang.getCurrentLang();
};

export const getValueFromCurrentLang = (keyString, dynamicVariables = null) => {
  const multiLang = new MultiLang();
  return multiLang.getValueFromCurrentLang(keyString, dynamicVariables);
};
